import { Card, Text, Group, Center, Button } from '@mantine/core';
import { IconArrowRight, IconFileDownload } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { IconAndDetails, IconAndText } from './ProvidersDashboard';
import Styles from './LabReports.module.css'

const data = [
  {
    id: '1',
    name: 'Medical Report 1',
    category: 'lab',
    clinic: 'Southwest Family Medicine',
    date: '2 Aug 2023',
    time: '10:00 am',
  },
  {
    id: '2',
    name: 'Medical Report 2',
    category: 'lab',
    clinic: 'Southwest Family Medicine',
    date: '2 Aug 2023',
    time: '10:00 am',
  },
];

export function LabReports() {

  const organisationDetails = data.map((details) => (
    <Card.Section key={details.id} className={Styles.section}>
      <Group px="xs" display="block">
        <div>
          <Text size="sm" fw={600}>
            {details.name}
          </Text>
          <Center style={{ justifyContent: 'flex-start' }}>
            <IconAndDetails ImageSrc="../../../img/dashboard-assets/calendar-blank.svg" text={details.date} />
            <IconAndDetails ImageSrc="../../../img/dashboard-assets/clock.svg" text={details.time} />
          </Center>
          <Text size="xs" fw={700} fs="12px">
            Category : <span style={{ fontWeight: 400 }}>{details.category}</span>
          </Text>
          <Center style={{ justifyContent: 'space-between' }}>
            <Link to={''} className={Styles.link}>
              More details
              <IconArrowRight size="12px" />
            </Link>
            <Group>
              <Link to={''} className={Styles.link}>
                <IconFileDownload size="16px" />
              </Link>
              <Button variant="light" size="xs" radius="lg" className={Styles.button}>
                Final
              </Button>
            </Group>
          </Center>
        </div>
      </Group>
    </Card.Section>
  ));

  return (
    <Card shadow="sm" radius="md" className={Styles.card}>
      <Card.Section className={Styles.section}>
        <IconAndText ImageSrc="../../../img/dashboard-assets/circle-file.svg" text={'Latest Lab Reports'} />
      </Card.Section>

      <Group display="block" className={Styles.scrollbar} style={{ height: '200px', overflowX: 'hidden' }}>
        {organisationDetails}
      </Group>

      <Card.Section className={Styles.footer}>
        <Group style={{ alignItems: 'flex-end' }} gap={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#222',
              display: 'flex',
            }}
            to={''}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
}
