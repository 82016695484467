/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ActionIcon, Center, Flex, Image, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';

// Removed createStyles, using inline styles instead
/* const useStyles = createStyles(() => ({
  link: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#9552e8',
    lineHeight: '25px',
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'underline',
    },
  },
})); */

const linkStyles = {
  fontSize: '16px',
  fontWeight: 600,
  color: '#9552e8',
  lineHeight: '25px',
  textDecoration: 'none',
};

const Breadcrumb = () => {
  const location = useLocation();

  const generateBreadcrumb = () => {
    const pathnames = location.pathname.split('/').filter((pathname) => pathname !== '');
    const isRoot = pathnames[0] === 'dashboard';

    return (
      <Center>
        <Center style={{ alignItems: 'flex-end' }}>
          <ActionIcon
            onClick={() => {
              window.location.href = '/Dashboard';
            }}
            style={{ background: 'transparent' }}
          >
            <Image src={'/img/icons/breadcrumbHome.svg'} width={18} />
          </ActionIcon>
        </Center>

        {!isRoot ? (
          <ul style={{ display: 'flex', margin: '0', paddingInlineStart: '5px' }}>
            {pathnames.map((pathname, index) => {
              const formatPathname = pathname
                .replace(/-/g, ' ')
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
              const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
              const isLast = index === pathnames.length - 1;
              return (
                <li key={pathname} style={{ listStyleType: 'none' }}>
                  <Flex align={'center'} gap={'8px'} >
                    <IconChevronRight color="#D0D5DD" size="16px" />
                    {isLast ? (
                      <Text fw={600} size={'16px'} lh={'24px'} color="#344054" >
                        {formatPathname}
                      </Text>
                    ) : (
                      <Link 
                        style={{
                          ...linkStyles,
                          cursor: 'pointer',
                          textDecoration: 'none'
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.textDecoration = 'underline';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.textDecoration = 'none';
                        }}
                        to={routeTo}
                      >
                        {formatPathname}
                      </Link>
                    )}
                  </Flex>
                </li>
              );
            })}
          </ul>
        ) : null}
      </Center>
    );
  };

  return <Center>{generateBreadcrumb()}</Center>;
};

export default Breadcrumb;
