/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Title, Text, Tabs, Center, Skeleton } from '@mantine/core';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  YAxis,
  Area,
  Sector,
} from 'recharts';
import TitleComponent from '../TitleComponent';
import { patientCountsByEhr, organizationPatientDemographics, organizationRegisteredPatients } from '../../fhirApi';
import { useMedplum } from '@medplum/react';
import Styles from './EHRDetails.module.css';

const COLORS = ['#89AED3', '#7FD3EC', '#EDB089', '#D9DADB'];
const colors = ['#8DBEF3', '#4D8CD0', '#8DBEF3', '#1B5390', '#3673B6', '#8DBEF3', '#4D8CD0', '#8DBEF3'];

// const useStyles = createStyles((theme) => ({
//   card: {
//     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
//     height: '100%',
//   },
//   section: {
//     padding: theme.spacing.md,
//   },
//   header: {
//     padding: theme.spacing.md,
//     borderBottom: `1px solid #EEF1F6 !important`,
//   },
//   customTabs: {
//     '& button': {
//       fontSize: '14px',
//       color: '#000',
//       background: '#eee',
//       marginRight: '10px',
//     },
//     '& button[data-active]': {
//       color: '#000',
//       background: '#C9E1FF !important',
//       backgroundColor: '#C9E1FF !important',
//       fontWeight: 'bold',
//     },
//   },
//   customTabPanel: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//   },
//   label: {
//     fontWeight: 400,
//     fontSize: theme.fontSizes.sm,
//     marginRight: theme.spacing.sm,
//   },
//   activeLabel: {
//     fontWeight: 700,
//     fontSize: theme.fontSizes.md,
//   },
//   activeBullet: {
//     width: '20px',
//     height: '20px',
//   },
// }));

const cardStyles = {
  backgroundColor: '#fff',
  height: '100%'
};

const sectionStyles = {
  padding: '1rem'
};

const headerStyles = {
  padding: '1rem',
  borderBottom: '1px solid #EEF1F6'
};

const labelStyles = {
  fontWeight: 400,
  fontSize: '14px',
  marginRight: '0.5rem'
};

const activeLabelStyles = {
  fontWeight: 700,
  fontSize: '16px'
};

// const activeBulletStyles = {
//   width: '20px',
//   height: '20px'
// };

const tabs = ['Race', 'Ethnicity', 'Age', 'Gender'];

export function EHRsIntegrated(): JSX.Element {
  const [selected, setSelected] = React.useState({ id: 'all', label: 'All' });
  const [selectedOrganization, setOrganization] = React.useState<any | null>();
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [patientsByEhrType, setPatientsByEhrType] = useState<any[]>([]);
  const [demographicData, setDemographicData] = useState<any | null>([]);
  const [checkOrgSelected, setCheckOrgSelected] = useState<boolean>(false);
  const [yearlyPatientsByEhrType, setYearlyPatientsByEhrType] = useState<any | null>([]);
  const medplum = useMedplum();

  useEffect(() => {
    Promise.all([
      getPatientCountList(),
    ]).catch((error) => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const getPatientCountList = () => {
    return patientCountsByEhr(medplum).then((response) => {
      const updatedResponse = response?.map((item: any, index: number) => ({
        ...item,
        id: index + 1,
      }));
      setPatientsByEhrType(updatedResponse);
    });
  };

  const getDemographicList = (ehr: string, selectedOrg: string) => {
    if(selectedOrg) {
      organizationPatientDemographics(medplum, ehr, selectedOrg).then((response) => {
        setDemographicData(response);
      }).catch((error) => {
        console.error(error);
      });
    }
  };
  
  const getRegisterPatients = (ehr: string, selectedOrg: string) => {
    organizationRegisteredPatients(medplum, ehr, selectedOrg).then((response) => {
      setYearlyPatientsByEhrType(response);
    }).catch((error) => {
      console.error(error);
    });
  }

  const ehrTypesWithAll = [
    { id: 'all', name: 'All', counts: patientsByEhrType?.reduce((acc: number, cur: any) => acc + cur.counts, 0) },
    ...patientsByEhrType || [],
  ];

  const [selectedEhrType, setEhrType] = React.useState(ehrTypesWithAll[0]);

  const ActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    return selectedEhrType?.name !== 'All' ? (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle">
          {selectedEhrType?.name}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fontWeight={700}>
          {selectedEhrType?.counts}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 5}
          outerRadius={outerRadius + 5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    ) : (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle">
          {selectedEhrType?.name}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fontWeight={700}>
          {selectedEhrType?.counts}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const coloredData = yearlyPatientsByEhrType?.map((entry: any, index: number) => ({
    ...entry,
    fill: colors[index % colors.length],
  }));

  const handleEhrTypeSelect = (selectedItem: any) => {
    setSelected(selectedItem);
    setEhrType(selectedItem);
  };

  const handleOrganizationSelect = (selectedItem: any) => {
    setOrganization(selectedItem);
    setCheckOrgSelected(true);
  };

  const handleGetOrganizationSelect = (item: any) => {
    setOrganization(item);
  }

  useEffect(() => {
    const selectedIdString = selected.id.toString();
    const patientIndex = patientsByEhrType?.findIndex((item: any) => item.id.toString() === selectedIdString);
    setActiveIndex(selectedIdString === 'all' ? -1 : patientIndex);

    ehrTypesWithAll.filter((item) => {
      if (item.id.toString() === selected.id) {
        setEhrType(item);
      }
    });

    if (selected.label === 'all') {
      setActiveIndex(-1);
    }

    if (selected.label === 'All') {
      getDemographicList('all', 'all');
      getRegisterPatients('all', 'all');
    } else if (selected.label === 'Epic') {
      getDemographicList(selected.label, selectedOrganization?.id);
      getRegisterPatients(selected.label, selectedOrganization?.id);
    } else if (selected.label === 'Cerner') {
      getDemographicList(selected.label, selectedOrganization?.id);
      getRegisterPatients(selected.label, selectedOrganization?.id);
    } else if (selected.label === 'AthenaHealth') {
      getDemographicList(selected.label, selectedOrganization?.id);
      getRegisterPatients(selected.label, selectedOrganization?.id);
    } else if (selected.label === 'NextGen') {
      getDemographicList(selected.label, selectedOrganization?.id);
      getRegisterPatients(selected.label, selectedOrganization?.id);
    }

  }, [selected]);

  useEffect(() => {
    if(selectedOrganization) {
      setCheckOrgSelected(false);
      if (selected.label === 'All') {
        getDemographicList('all', 'all');
        getRegisterPatients('all', 'all');
      } else {
        getDemographicList(selected.label , selectedOrganization?.id);
        getRegisterPatients(selected.label, selectedOrganization?.id);
      }
    }
  }, [checkOrgSelected]);

  return (
    <>
      <TitleComponent
        title="EHRs Integrated"
        EhrFilter={true}
        organizationFilter={true}
        selectedLabel={selectedEhrType ? selectedEhrType.name : ''}
        selectedEhrType={selectedEhrType}
        onEhrTypeSelect={handleEhrTypeSelect}
        onOrganizationSelect={handleOrganizationSelect}
        getOrganizationSelect={handleGetOrganizationSelect}
      />

      <Box px="lg" py="sm">
        <Grid>
          <Grid.Col span={{ base: 12, sm: 6, lg: 6 }}>
            <Card withBorder radius="md" style={cardStyles}>
              <Card.Section style={headerStyles} p="lg">
                <Text fw={700}>Patients Registered under each EHR system</Text>
              </Card.Section>
              {patientsByEhrType?.length !==0 ? (
                <Card.Section
                  style={{...sectionStyles, minHeight: '200px', borderBottom: '0px', flexDirection: 'row', justifyContent: 'center', marginLeft: '0px'}}
                  p="lg"
                >
                  <Center p="md" style={{ flex: 1 }}>
                    <Legend
                      wrapperStyle={{
                        position: 'relative',
                        border: '1px solid #EEF1F6',
                        padding: '20px',
                        borderRadius: '5px',
                        boxSizing: 'border-box',
                      }}
                      content={
                        <Box style={{ 
                          display: 'flex', 
                          flexDirection: 'row' as const, 
                          width: '100%', 
                          justifyContent: 'center', 
                          flexWrap: 'wrap' 
                        }}>
                          {patientsByEhrType?.map((entry: any, index: number) => (
                            <Center
                              key={`legend-${index}`}
                              style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '100%' }}
                            >
                              <div
                                style={{
                                  backgroundColor: COLORS[index % COLORS.length],
                                  width: selected.id === entry.id ? '20px' : '12px',
                                  height: selected.id === entry.id ? '20px' : '12px',
                                  marginRight: '10px',
                                  borderRadius: '25px',
                                }}
                              ></div>
                              <span style={selected.id === entry.id ? activeLabelStyles : labelStyles}>
                                {selected.id === 'all' ? `${entry.name} : ${entry.counts}` : entry.name}
                              </span>
                            </Center>
                          ))}
                        </Box>
                      }
                    />
                  </Center>
                  <Center style={{ flex: 1 }}>
                    <PieChart width={300} height={300}>
                      <Pie
                        activeIndex={activeIndex}
                        data={patientsByEhrType}
                        cx="50%"
                        cy="50%"
                        innerRadius={70}
                        outerRadius={110}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="counts"
                        activeShape={ActiveShape}
                        label
                      >
                        {patientsByEhrType?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ outline: 'none' }} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </Center>
                </Card.Section>
              ) : (
                <>
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" />
                </>
              )}
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6, lg: 6 }}>
            <Card withBorder radius="md" style={cardStyles}>
              <Card.Section style={headerStyles} p="lg">
                <Text fw={700}>Patients Registered under each Organization</Text>
              </Card.Section>
              {yearlyPatientsByEhrType?.length !== 0 ? (
                <Card.Section
                  style={{...sectionStyles, minHeight: '300px', borderBottom: '0px', flexDirection: 'row'}}
                  p="lg"
                >
                  <Box display="flex" mb="lg" style={{ justifyContent: 'space-between' }}>
                    <div></div>
                    <Center style={{ justifyContent: 'flex-start' }}>
                      <div
                        style={{
                          backgroundColor: '#2859C5',
                          width: '12px',
                          height: '12px',
                          marginRight: '10px',
                          borderRadius: '25px',
                        }}
                      ></div>
                      <Title variant="h6" size={14} mr="xs" fw={500}>
                        Registered Patients
                      </Title>
                    </Center>
                  </Box>
                  <Box
                    style={{ minHeight: '200px', borderBottom: '0px', flexDirection: 'row' }}
                  >
                    <Center style={{ flex: 1 }}>
                      <ResponsiveContainer width="100%" height={300}>
                        <AreaChart
                          width={500}
                          height={300}
                          data={yearlyPatientsByEhrType}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <defs>
                            <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#2859C5" stopOpacity={0.5} />
                              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                            </linearGradient>
                          </defs>

                          <XAxis dataKey="month" tickLine={false} />
                          <YAxis tickLine={false} axisLine={false} />
                          <Tooltip />

                          <Area
                            type="monotone"
                            dataKey="patientCount"
                            name="Patients"
                            stroke="#2859C5"
                            strokeWidth={2}
                            fill="url(#areaGradient)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Center>
                  </Box>
                </Card.Section>
              ): (
                <>
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" />
                </>
              )}
            </Card>
          </Grid.Col>
        </Grid>
      </Box>

      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={{ base: 12, sm: 12, lg: 6 }}>
            <Card withBorder radius="md" style={cardStyles}>
              <Card.Section style={headerStyles} p="lg">
                <Text fw={700}>New Patients Saved</Text>
              </Card.Section>

              <Card.Section style={{...sectionStyles, borderBottom: '0px'}} p="lg">
                <Text variant="xs" mb="md">
                  {'This data is based on patients using our system'}
                </Text>
                <Center>
                  {' '}
                  <BarChart width={500} height={200} data={coloredData} barSize={25}>
                    <XAxis dataKey="month" axisLine={false} tickLine={false} />
                    <Tooltip />
                    <Bar dataKey="patientCount" name="Patients" label={{ position: 'top' }} />
                  </BarChart>
                </Center>
              </Card.Section>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 12, lg: 6 }}>
            <Card withBorder radius="md" style={cardStyles}>
              <Card.Section style={headerStyles} p="lg">
                <Text fw={700}>Patients Demographics </Text>
              </Card.Section>
              {demographicData?.length !== 0 ? (
                <Card.Section style={sectionStyles} p="lg">
                  <Tabs variant="pills" defaultValue="race" className={Styles.customTabsStyles}>
                    <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
                      {tabs.map((t) => (
                        <Tabs.Tab key={t} value={t.toLowerCase()}>
                          {t}
                        </Tabs.Tab>
                      ))}
                    </Tabs.List>
                    <Tabs.Panel value="race" pt="md" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box p="md" style={{ flex: 1, width: '40%' }}>
                        <Legend
                          wrapperStyle={{
                            position: 'relative',
                            border: '1px solid #EEF1F6',
                            padding: '10px',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                          }}
                          content={
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                              {demographicData?.race?.map((entry: any, index: number) => (
                                <Center
                                  key={`legend-${index}`}
                                  style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '40%' }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: COLORS[index % COLORS.length],
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '10px',
                                      borderRadius: '25px',
                                    }}
                                  ></div>
                                  <span>{entry.name}</span>
                                </Center>
                              ))}
                            </Box>
                          }
                        />
                      </Box>
                      <PieChart width={300} height={200} style={{ flex: 1, width: '50%' }}>
                        <Tooltip />
                        <Pie
                          data={demographicData?.race}
                          innerRadius={50}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={0}
                          dataKey="patientCounts"
                          label
                        >
                          {demographicData?.race?.map((entry: any, index: number) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                              style={{ outline: 'none' }}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Tabs.Panel>
                    <Tabs.Panel value="ethnicity" pt="md" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box p="md" style={{ flex: 1, width: '40%' }}>
                        <Legend
                          wrapperStyle={{
                            position: 'relative',
                            border: '1px solid #EEF1F6',
                            padding: '10px',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                          }}
                          content={
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                              {demographicData?.ethnicity?.map((entry: any, index: number) => (
                                <Center
                                  key={`legend-${index}`}
                                  style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '40%' }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: COLORS[index % COLORS.length],
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '10px',
                                      borderRadius: '25px',
                                    }}
                                  ></div>
                                  <span>{entry.name}</span>
                                </Center>
                              ))}
                            </Box>
                          }
                        />
                      </Box>
                      <PieChart width={300} height={220} style={{ flex: 1, width: '50%' }}>
                        <Tooltip />
                        <Pie
                          data={demographicData?.ethnicity}
                          innerRadius={50}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={0}
                          dataKey="patientCounts"
                          label
                        >
                          {demographicData?.ethnicity?.map((entry: any, index: number) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                              style={{ outline: 'none' }}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Tabs.Panel>
                    <Tabs.Panel value="age" pt="md" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box p="md" style={{ flex: 1, width: '40%' }}>
                        <Legend
                          wrapperStyle={{
                            position: 'relative',
                            border: '1px solid #EEF1F6',
                            padding: '10px',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                          }}
                          content={
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                              {demographicData?.age?.map((entry: any, index: number) => (
                                <Center
                                  key={`legend-${index}`}
                                  style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '40%' }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: colors[index % colors.length],
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '10px',
                                      borderRadius: '25px',
                                    }}
                                  ></div>
                                  <span>{entry.age}</span>
                                </Center>
                              ))}
                            </Box>
                          }
                        />
                      </Box>
                      <BarChart width={300} height={200} data={demographicData?.age} barSize={12}>
                        <XAxis dataKey="patientCounts" axisLine={false} tickLine={false} />
                        <Tooltip />
                        <Bar dataKey="patientCounts" fill="#8884d8" label={{ position: 'top' }}>
                          {demographicData?.age?.map((entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} style={{ outline: 'none' }} />
                          ))}
                        </Bar>
                      </BarChart>
                    </Tabs.Panel>
                    <Tabs.Panel value="gender" pt="md" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box p="md" style={{ flex: 1, width: '40%' }}>
                        <Legend
                          wrapperStyle={{
                            position: 'relative',
                            border: '1px solid #EEF1F6',
                            padding: '20px',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                          }}
                          content={
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                              {demographicData?.gender?.map((entry: any, index: number) => (
                                <Center
                                  key={`legend-${index}`}
                                  style={{ marginBottom: '10px', justifyContent: 'flex-start', width: '40%' }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: COLORS[index % COLORS.length],
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '10px',
                                      borderRadius: '25px',
                                    }}
                                  ></div>
                                  <span>{entry.name}</span>
                                </Center>
                              ))}
                            </Box>
                          }
                        />
                      </Box>
                      <PieChart width={300} height={200} style={{ flex: 1, width: '50%' }}>
                        <Tooltip />
                        <Pie
                          data={demographicData?.gender}
                          dataKey="patientCounts"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          label
                        >
                          {demographicData?.gender?.map((entry: any, index: number) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                              style={{ outline: 'none' }}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Tabs.Panel>
                  </Tabs>
                </Card.Section>
              ): (
                <>
                  <Skeleton height={8} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" />
                </>
              )}
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
