import React, { useState } from 'react';
import { Button, Modal, TextInput, Loader, Group } from '@mantine/core';

interface InvitePopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (details: { studyID: string }) => void;
  header: string;
  submitButtonLabel: string;
  cancelButtonLabel: string;
  isLoading?: boolean;
}

const InvitePopup: React.FC<InvitePopupProps> = ({
  isOpen,
  onClose,
  onSubmit,
  header,
  submitButtonLabel,
  cancelButtonLabel,
  isLoading
}) => {
  const [studyID, setStudyID] = useState('');

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const clinicalStudyDetails = { studyID };
    onSubmit(clinicalStudyDetails);
    setStudyID('');
  };

  const handleClose = () => {
    setStudyID('');
    onClose();
  };

  return (
    <Modal opened={isOpen} onClose={handleClose} centered className='invitePopup'>
      <h2 style={{ textAlign: 'center', margin: 0 }}>{header}</h2>
      <form onSubmit={handleFormSubmit}>
        <TextInput
          //          p={10}
          label="Study ID"
          placeholder="Enter Study ID"
          value={studyID}
          onChange={(e) => setStudyID(e.currentTarget.value)}
          required
        />
        <Group 
        //position="right"
        //mt="md"
        >
          <Button onClick={handleClose} className='btn-danger'>
            {cancelButtonLabel}
          </Button>
          <Button type="submit" className='btn-success'>
            {submitButtonLabel} {isLoading && <Loader
              //ml={10} 
              size={20} color='white' />}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default InvitePopup;