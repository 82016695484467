import { useState, useEffect } from 'react';
import { Box, Grid, Table, Text, Button } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import TitleComponent from '../../../components/TitleComponent';
import { getQualifiedPatients, consentStatus, clinicalStudyNotification } from '../../../../src/fhirApi';
import { showNotification } from '@mantine/notifications';

type PatientDetails = {
  patientId: string;
  patientName: string;
  studyId: string;
  studyName: string;
  eligibilityDate: Date;
  condition: string;
  isConsentSent: boolean;
  consentStatus: string;
  action: string;
  researchSubjectId: string;
};

const buttonStyle = {
  textDecoration: 'none',
  color: '#fff',
  backgroundColor: '#3673B6',
  padding: '5px',
  fontSize: '11px',
  fontWeight: 700,
  borderRadius: '2px',
  height: '1.5rem',
};

const TrialQualifiedPatients = () => {
  const medplum = useMedplum();
  const [qualifiedPatientsList, setQualifiedPatientsList] = useState<PatientDetails[]>([]);

  const [extensionDetails, setExtensionDetails] = useState<any[]>([]);
  const [showExtensionTable, setShowExtensionTable] = useState<boolean>(false);

  useEffect(() => {
    const fetchQualifiedPatients = async () => {
      try {
        const response = await getQualifiedPatients(medplum);
        setQualifiedPatientsList(response ?? []);
      } catch (error) {
        console.error('Error fetching clinical study data:', error);
      }
    };

    fetchQualifiedPatients();
  }, [medplum]);

  const handleViewClick = async (researchSubjectId: string) => {
    const result = await consentStatus(medplum, researchSubjectId);
    const consentExtension = result.extension?.find(
      (ext: { url: string; valueString?: string }) => ext.url === 'https://app.pragmaconnect.com/eligibility-assessment'
    );
    if (consentExtension && consentExtension.valueString) {
      const parsedData = JSON.parse(consentExtension.valueString);
      const parsedConsentExtension = parsedData.eligibility_assessment || [];
      setExtensionDetails(parsedConsentExtension);
      setShowExtensionTable(true);
    } else {
      console.error('Eligibility assessment data not found');
      setShowExtensionTable(false);
    }
  };

  const handleNotifyEligibility = async (
    patientID: string,
    patientName: string,
    studyID: string,
    studyName: string,
    researchSubjectId: string
  ) => {
    try {
      await clinicalStudyNotification(
        medplum,
        patientID,
        patientName,
        studyID,
        studyName,
        researchSubjectId,
        ''
      );

      showNotification({
        color: 'green',
        message: `Notification has been sent to ${patientName}.`
      });
    } catch (error) {
      console.error('Error sending clinical study notification:', error);
    }
  };

  return (
    <Box px="lg" py="sm" style={{ paddingBottom: '0px !important' }}>
      <Grid mb="md">
        <Grid.Col span={{base:12, lg:12}}>
          <TitleComponent title={'Study Qualified Patients'} />
        </Grid.Col>

        {/* Patient Table */}
        <Grid.Col span={{base:12, lg:12}}>
          <Box p="md">
            <Box
              style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                overflowY: 'auto',
                maxHeight: '400px',
              }}
            >
              <Table striped highlightOnHover>
                <thead
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                  }}
                >
                  <tr>
                    <th>Patient Name</th>
                    <th>Eligibility Date</th>
                    <th>Clinical Study</th>
                    <th>Condition</th>
                    <th>Consent Sent</th>
                    <th>Consent Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {qualifiedPatientsList.length > 0 ? (
                    qualifiedPatientsList.map((patient, index) => (
                      <tr key={index}>
                        <td>{patient.patientName}</td>
                        <td>{new Date(patient.eligibilityDate).toLocaleDateString()}</td>
                        <td>{patient.studyName}</td>
                        <td>{patient.condition}</td>
                        <td>{patient.isConsentSent ? 'Yes' : 'No'}</td>
                        <td>{patient.consentStatus}</td>
                        <td>
                          <Button
                            style={buttonStyle}
                            onClick={() =>
                              patient.action === 'View'
                                ? handleViewClick(patient.researchSubjectId)
                                : handleNotifyEligibility(
                                  patient.patientId,
                                  patient.patientName,
                                  patient.studyId,
                                  patient.studyName,
                                  patient.researchSubjectId
                                )
                            }
                          >
                            {patient.action === 'View' ? 'View' : patient.action}
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No qualified patients found.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Box>
          </Box>
        </Grid.Col>

        {/* Eligibility Criteria Table */}
        {showExtensionTable && extensionDetails.length > 0 && (
          <Grid.Col span={{base:12, lg:12}}>
            <Box p="md">
              <Text size="lg" style={{ fontSize: '18px', fontWeight: '600', marginBottom: '16px' }}>
                Clinical Trial Criteria Breakdown
              </Text>
              <Box
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  overflowY: 'auto',
                  maxHeight: '400px',
                }}
              >
                <Table striped highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#fff',
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th>Criteria Name</th>
                      <th>Type</th>
                      <th>Patient Value</th>
                      <th>Criteria Met</th>
                      <th>Description</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {extensionDetails.map((detail, index) => (
                      <tr key={index}>
                        <td>{detail.criteria_name}</td>
                        <td>{detail.criteria_type}</td>
                        <td>{detail.patient_value}</td>
                        <td>{detail.met ? 'Yes' : 'No'}</td>
                        <td>{detail.criteria_description}</td>
                        <td>{detail.reason}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </Box>
          </Grid.Col>
        )}
      </Grid>
    </Box>
  );
};

export default TrialQualifiedPatients;
