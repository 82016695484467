import { Center, Grid, Title } from '@mantine/core';
import { SignInForm, useMedplum } from '@medplum/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig } from './config';
import { IntroductionPanel } from './IntroductionPanel';

// const useStyles = createStyles((theme) => ({
//   hiddenMobile: {
//     [theme.fn.smallerThan('md')]: {
//       display: 'none',
//     },
//   },

//   paddingMobile: {
//     [theme.fn.smallerThan('sm')]: {
//       padding: '25px !important',
//     },
//   },

//   secondaryTitle: {
//     color: '#000',
//     float: 'left',
//     fontSize: '28px',
//     lineHeight: '1.1',
//     fontWeight: 700,
//     marginBottom: '8px',
//   },
// }));

export function SignInPage(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const config = getConfig();
  // const { classes } = useStyles();
  const medplum = useMedplum();

  const onSignIn = () => {
    if (medplum.isSuperAdmin()) {
      navigate(searchParams.get('next') || '/');
    } else if (medplum.getUserConfiguration()?.menu?.[0]?.link?.[0].target) {
      navigate(medplum.getUserConfiguration()?.menu?.[0]?.link?.[0].target ?? '/');
    } else {
      navigate('/');
    }
  }

  return (
    <Grid style={{ background: '#E9F3FF', margin: '-60px 0px' }}>
      <Grid.Col
        span={{ base: 12, md: 6, lg: 6 }}
        style={{ 
          alignContent: 'center', 
          justifyContent: 'center', 
          minHeight: '100vh',
          '@media (max-width: 768px)': {
            display: 'none'
          }
        }}
      >
        <IntroductionPanel />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 6 }} 
        style={{
          '@media (max-width: 576px)': {
            padding: '25px !important'
          }
        }}
      >
        <Center
          p={{ xs: 'lg', md: 'xs' }}
          style={{ alignContent: 'center', justifyContent: 'center', minHeight: '100vh', width: '100%' }}
        >
          <SignInForm
            onSuccess={onSignIn}
            onForgotPassword={() => navigate('/resetpassword')}
            onRegister={config.registerEnabled ? () => navigate('/register') : undefined}
            googleClientId={config.googleClientId}
            login={searchParams.get('login') || undefined}
            projectId={searchParams.get('project') || undefined}
          >
            <Center 
              display="flex" 
              m={{ xs: 'lg', md: 'xs' }} 
              style={{ flexDirection: 'column', minWidth: '350px' }}
            >
              <Title 
                variant="h2" 
                fw={700} 
                mb={3}
                style={{
                  color: '#000',
                  float: 'left', 
                  fontSize: '28px',
                  lineHeight: '1.1',
                  fontWeight: 700,
                  marginBottom: '8px'
                }}
              >
                Sign In
              </Title>
              <p>Please log in to your account to proceed.</p>

              {searchParams.get('project') === 'new' && <div>Sign in again to create a new project</div>}
            </Center>
            {/* <Link to="/login" style={{ color: '#2f67ad' }}>
              Login With SSO
            </Link> */}
          </SignInForm>
        </Center>
      </Grid.Col>
    </Grid>
  );
}
