import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import { TextInput, Button, Text, Switch, Select, Group, Drawer, Box } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { createTelehealthSession, fetchPatients } from '../../fhirApi';
import { useMedplumContext } from '@medplum/react';
import { showNotification } from '@mantine/notifications';
import Styles from './TelehealthDrawer.module.css'

interface TelehealthDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onStartCall: (id: string) => void;
}

const defaultQuery = (type: 'PatientList' | 'PractitionerList', searchTerm: string): string => `{
    ${type}(name: "${searchTerm}", _count: 1000) {
      resourceType
      id
      name {
        family
        given
      }
    }
  }`;

export const TelehealthDrawer: React.FC<TelehealthDrawerProps> = ({ isOpen, onClose, onStartCall }) => {
  const { medplum, profile } = useMedplumContext();
  const [title, setTitle] = useState<string>('');
  const [practitioner, setPractitioner] = useState<string | undefined>(undefined);
  const [patient, setPatient] = useState<string | undefined>(undefined);
  const [meetingType, setMeetingType] = useState<'instant' | 'schedule'>('instant');
  const [isStarting, setIsStarting] = useState<boolean>(false);
  const [patientSearchTerm, setPatientSearchTerm] = useState<string>('');
  const [patients, setPatients] = useState<{ value: string; label: string }[]>([]);
  const activeUserType = profile?.resourceType;
  const [sdkType, setSdkType] = useState<string>('zoom');
  const practitionerName = medplum.getActiveLogin()?.profile?.display;
  const patientName = patients?.[0]?.label ?? undefined;

  const sdkOptions = [
    { value: 'zoom', label: 'Zoom', img: '/img/icons/ic-zoom.svg' },
    { value: 'liveKit', label: 'Live Kit', img: '/img/icons/ic-live-kit.svg', disabled: true },
  ];

  // const icon = <img src={sdkOptions.find((sdk) => sdk.value === sdkType)?.img} width="20" />;

  const ItemComponent = forwardRef<HTMLDivElement, { value: string; label: string; img: string }>(
    ({ img, label, ...others }, ref) => {
      return (
        <div ref={ref} {...others}>
          <Group wrap='nowrap'>
            <img src={img} alt={label} style={{ width: 24, height: 24 }} />
            <div>
              <Text>{label}</Text>
            </div>
          </Group>
        </div>
      );
    }
  );

  // Function to handle starting a call
  const handleStartCall = async (): Promise<void> => {
    if (
      !meetingType ||
      (activeUserType === 'Patient' && !practitioner) ||
      (activeUserType === 'Practitioner' && !patient)
    ) {
      showNotification({
        message: 'Please select the required fields',
        color: 'red',
      });
      return;
    }

    setIsStarting(true);

    const body = {
      meetingType,
      title,
      practitionerId: activeUserType === 'Patient' ? practitioner : profile?.id,
      patientId: activeUserType === 'Practitioner' ? patient : profile?.id,
      sdkType,
    };

    try {
      const response = await createTelehealthSession(medplum, body);
      if (response) {
        if (meetingType === 'instant') {
          onStartCall(response?.appointmentId);
        } else {
          closeDialog();
        }
      }
    } catch (error) {
      setIsStarting(false);
      console.error('Error:', error);
    }
  };

  // Function to close the dialog and reset states
  const closeDialog = (): void => {
    setIsStarting(false);
    setTitle('');
    setPractitioner(undefined);
    setPatient(undefined);
    onClose();
  };

  // Function to fetch resources (patients or practitioners) based on search term
  const fetchResources = useCallback(
    (
      type: 'PatientList' | 'PractitionerList',
      searchTerm: string,
      setResource: React.Dispatch<React.SetStateAction<{ value: string; label: string }[]>>
    ) => {
      if (searchTerm.length >= 3) {
        fetchPatients(medplum, defaultQuery(type, searchTerm))
          .then((response: { data: { [key: string]: { id: string; name: { given: string[]; family: string }[] }[] } }) => {
            const resourceList = response.data[type].map((resource) => {
              const givenName = resource?.name?.[0]?.given?.join(' ') || '';
              const familyName = resource?.name?.[0]?.family || '';
              return { value: resource.id, label: `${givenName} ${familyName}` };
            });
            setResource(resourceList);
          })
          .catch((error: Error) => console.error(error));
      } else {
        setResource([]);
      }
    },
    [medplum]
  );

  // Effect to fetch patients when search term changes
  useEffect(() => {
    if (patientSearchTerm.length >= 3) {
      fetchResources('PatientList', patientSearchTerm, setPatients);
    }
  }, [patientSearchTerm, fetchResources]);

  return (
    <Drawer
      className={Styles.drawer}
      position="right"
      opened={isOpen}
      onClose={closeDialog}
      title={
        <Box className="header">
          <Text className="header-txt">Create a Telehealth Meeting</Text>

          <Text className="sub-header-txt">
            Enter details to start a meeting with <span className="patient-name">{patientName ?? 'Patient'}</span>
          </Text>
        </Box>
      }
      size="35%"
    >
      <Box className="meeting-type-switch">
        <Text
          style={{
            fontWeight: meetingType === 'schedule' ? '500' : '400',
            fontSize: '14px',
            color: meetingType === 'schedule' ? '#0D121C' : '#0D121C',
          }}
        >
          Schedule
        </Text>
        <Switch
          defaultChecked
          className={Styles.checkbox}
          size="sm"
          onChange={() => setMeetingType(meetingType === 'instant' ? 'schedule' : 'instant')}
          my="xl"
        />
        <Text style={{ fontWeight: meetingType === 'instant' ? '500' : '400', fontSize: '14px' }}>Instant</Text>
      </Box>
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Provider{' '}
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <TextInput
          placeholder="Enter a title"
          className="meeting-title-input-box"
          w="70%"
          disabled
          value={practitionerName}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs },
          })}
          mb={12}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Title (Optional)
        </Text>
        <TextInput
          placeholder="Enter a title"
          className="meeting-title-input-box"
          w="70%"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs },
          })}
          mb={12}
        />
      </Box>

      {activeUserType === 'Practitioner' && (
        <Box className="flex-container">
          <Text span w={'30%'} className="title-txt">
            Patient
            <Text span c={'red'}>
              {' '}
              *
            </Text>
          </Text>
          <Select
            placeholder="Select"
            w="70%"
            className="meeting-title-input-box"
            data={patients}
            value={patient}
            onChange={(value: string | null) => {
              if (value !== null) {
                setPatient(value);
              }
            }}
            searchValue={patientSearchTerm}
            onSearchChange={setPatientSearchTerm}
            rightSection={<IconChevronDown size={14} />}
            styles={(theme) => ({
              label: { marginBottom: theme.spacing.xs},
            })}
            mb="12"
            searchable
            required
          />
        </Box>
      )}
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt" mt={12}>
          Add video call
        </Text>
        <Select
          value={sdkType}
          w="70%"
          className="meeting-title-input-box"
          placeholder="Select"
          data={sdkOptions}
          onChange={(value: string | null) => {
            if (value !== null) {
              setSdkType(value);
            }
          }}
          component={ItemComponent}
          rightSection={<IconChevronDown size={14} />}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs},
          })}
          mb="12"
          mt={12}
        />
      </Box>
      <Group ml={'auto'} mt={'25px'} className="telehealth-action-group">
        <Box className="telehealth-create-meeting-btn-container">
          <Button
            size="md"
            onClick={closeDialog}
            bg="white"
            radius={4}
            variant="default"
            fullWidth
            className="telehealth-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            className="telehealth-start-vedio-btn"
            onClick={handleStartCall}
            radius={4}
            disabled={isStarting || !meetingType}
            fullWidth
          >
            {isStarting ? <Text>Starting call...</Text> : 'Start video call'}
          </Button>
        </Box>
      </Group>
    </Drawer>
  );
};
