import { Card, Center, Text, Container, Table, Input, Button, Tooltip, Group, Pagination, Modal } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useMedplum } from "@medplum/react";
import { IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import Styles from './CernerEhrPatientList.module.css'
import { useDisclosure } from "@mantine/hooks";
import { importPatientDetails, loadCernerPatientsList, saveEhrPatientById } from "../fhirApi";
import { useNavigate } from "react-router-dom";

type Patient = {
    id: string;
    name: string;
    birthdate: string;
    gender: string;
    mrnNo: string;
    contact: string;
    address: string;
}
const columns: string[] =
    ['MRN', 'Patient Name', 'Birth Date', 'Gender', 'Phone Number', 'Address'];

const ITEMS_PER_PAGE = 20;

export function CernerEhrPatientList(): JSX.Element {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const medplum = useMedplum();
    const [searchPatientInput, setSearchPatientInput] = useState<string>('');
    const [patientList, setPatientList] = useState<Patient[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [opened, { open, close }] = useDisclosure(false);
    const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(undefined);
    const ehr_token = JSON.parse(localStorage.getItem('ehr_token') ?? 'null')?.data?.access_token ?? null;
    const api_url = localStorage.getItem('api_url');
    const navigate = useNavigate();

    const fetchCernerPatientList = async () => {
        setIsLoading(true);
        try {
            if (ehr_token && api_url) {
                const response = await loadCernerPatientsList(medplum, searchPatientInput, ehr_token, api_url);
                if (Array.isArray(response)) {
                    setPatientList(response);
                } else {
                    showNotification({ color: 'red', message: response });
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching Cerner patient list:', error);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchCernerPatientList();
        setSearchPatientInput('');
        setCurrentPage(1);
    };

    const totalPages = Math.ceil((patientList?.length ?? 0) / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentPatients = patientList?.slice(startIndex, endIndex) ?? [];

    const openModal = (patient: Patient) => {
        setSelectedPatient(patient);
        open();
    };

    const importCernerPatient = async () => {
        showNotification({ color: 'green', message: `Loading patient data of ${selectedPatient?.name} from Cerner. This may take a few seconds.` });
        if (selectedPatient?.id && ehr_token && api_url) {
            let url = api_url.endsWith('/') ? api_url.slice(0, -1) : api_url;
            const response = await saveEhrPatientById(medplum, selectedPatient.id, 'Cerner', ehr_token, url);
            navigate(`/Patient/patient-dashboard/${response.patient.id}`);
            await importPatientDetails(medplum, selectedPatient.id, ehr_token, url, 'Cerner');
            setSelectedPatient(undefined);
        } else {
            console.error('Missing patient ID, EHR token, or API URL');
        }
    }

    return (
        <>
            <CernerPatientImportModal opened={opened} close={close} patient={selectedPatient} importCernerPatient={importCernerPatient} />
            <Card mx="lg" my="sm" className={Styles.headerCard}>
                <Center>
                    <Text variant="sm" size="16px" fw="bold">
                        {'Load Patients from Cerner'}
                    </Text>
                </Center>
            </Card>

            <Card withBorder mx="lg">
                <form onSubmit={handleSubmit}>
                    <div className={Styles.searchContainer}>
                        <Input
                            variant="filled"
                            radius="md"
                            placeholder="Search Patient"
                            value={searchPatientInput}
                            className={Styles.searchInput}
                            onChange={(event) => setSearchPatientInput(event.target.value)}
                        />
                        <Tooltip label="Search" position="bottom" className={Styles.tooltip}>
                            <Button
                                type="submit"
                                variant="outline"
                                disabled={isLoading || (searchPatientInput?.length ?? 0) < 3}
                                className={Styles.searchButton}
                            >
                                <IconSearch size={16} />
                            </Button>
                        </Tooltip>
                    </div>
                    <Text size="xs" c="dimmed" mt="xs" pl="sm">
                        Please enter a name with at least three characters.
                    </Text>
                </form>
            </Card>

            <Card withBorder mx="lg" mt="xl" mb="xl">
                <Container fluid mb="xl" style={{ width: '100%' }}>
                    <Table className={Styles.table}>
                        <thead>
                            <tr>
                                {columns.map((column) => (
                                    <th key={column} style={{ textAlign: 'left' }}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading ? (
                                currentPatients?.map((patient) => (
                                    <tr
                                        key={patient?.id}
                                        className={Styles.tableRow}
                                        onClick={() => openModal(patient)}
                                    >
                                        <td>{patient?.mrnNo}</td>
                                        <td>{patient?.name}</td>
                                        <td>{patient?.birthdate}</td>
                                        <td>{patient?.gender}</td>
                                        <td>{patient?.contact}</td>
                                        <td>{patient?.address}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length}>
                                        <Container>
                                            <Center className={Styles.loadingContainer}>
                                                <Text size="xl" c="dimmed">
                                                    Loading...
                                                </Text>
                                            </Center>
                                        </Container>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {patientList?.length === 0 && !isLoading && (
                        <Container>
                            <Center className={Styles.noResultsContainer}>
                                <Text size="xl" c="dimmed">
                                    No results
                                </Text>
                            </Center>
                        </Container>
                    )}
                    {patientList?.length > 0 && (
                        <Group className={Styles.paginationGroup}>
                            <Pagination
                                total={totalPages}
                                value={currentPage}
                                onChange={setCurrentPage}
                                siblings={1}
                                boundaries={1}
                                className={Styles.pagination}
                            />
                        </Group>
                    )}
                </Container>
            </Card>
        </>
    );
}

const CernerPatientImportModal = ({ opened, close, patient, importCernerPatient }: {
    opened: boolean;
    close: () => void;
    patient?: Patient;
    importCernerPatient: () => void;
}) => {

    const submit = () => {
        importCernerPatient();
        close();
    };
    return (
        <Modal opened={opened} size="auto" padding="lg" onClose={close} title="Confirm Patient Record Import" centered>
            <div className={Styles.modalContent}>
                <Text className={Styles.modalText}>
                    You are about to import the patient record of <b>{patient?.name}</b>.
                </Text>
                <Text className={Styles.modalDescription}>
                    This action will retrieve the latest patient demographics,<br/> conditions, observations etc. from cerner.
                </Text>
                <div className={Styles.modalActions}>
                    <Button variant="outline" color="green" onClick={submit}>
                        Yes, Import
                    </Button>
                    <Button variant="outline" color="red" onClick={close}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>

    );
};

