import { Box, Center, Grid, Title, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

// const useStyles = createStyles((theme) => ({
//   hiddenMobile: {
//     [theme.fn.smallerThan('md')]: {
//       display: 'none',
//     },
//   },

//   paddingMobile: {
//     [theme.fn.smallerThan('sm')]: {
//       padding: '25px !important',
//     },
//   },

//   primaryTitle: {
//     textAlign: 'left',
//     color: '#000',
//     float: 'left',
//     fontSize: '34px',
//     lineHeight: '1.1',
//     fontWeight: 300,
//     marginBottom: '8px',
//   },

//   secondaryTitle: {
//     color: '#000',
//     float: 'left',
//     fontSize: '28px',
//     lineHeight: '1.1',
//     fontWeight: 700,
//     marginBottom: '8px',
//   },

//   subTitle: {
//     color: '#94a3b8',
//     marginTop: '10px',
//     letterSpacing: '-0.025em',
//     fontSize: '14px',
//     fontWeight: 300,
//   },
// }));

export function IntroductionPanel() : JSX.Element {
  // const { classes } = useStyles();
  return (
    <>
      <Box style={{ padding: '25px' }}>
        <Center
          p={{ xs: 'lg', md: 'xs' }}
          style={{
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            minHeight: '100vh',
          }}
        >
          <Grid className="custom-grid" style={{ paddingLeft: '3px', paddingRight: '3px', justifyContent: 'center' }}>
            <Grid.Col span={{ xs: 12, md: 9, lg: 9 }} style={{ marginBottom: '16px' }}>
              <Link to="https://pragmaconnect.com/" target="_blank">
                <img src="../img/pragmaconnect-logo.svg" height={35} />
              </Link>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, md: 8, lg: 8 }}>
              <Title
                variant="h1"
                style={{
                  textAlign: 'left',
                  color: '#000',
                  float: 'left',
                  fontSize: '34px',
                  lineHeight: '1.1',
                  fontWeight: 300,
                  marginBottom: '8px',
                  width: '100%'
                }}
              >
                Welcome to <span style={{ fontWeight: 600 }}>{'PragmaConnect'}</span>
              </Title>
              <Text
                variant="h5"
                style={{
                  color: '#94a3b8',
                  marginTop: '10px',
                  letterSpacing: '-0.025em',
                  fontSize: '14px',
                  fontWeight: 300,
                  marginBottom: '8px',
                  width: '100%'
                }}
              >
                One Platform for All HealthCare Integrations
              </Text>
              <Box>
                <img src="../img/signin-bg.svg" style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
              </Box>
            </Grid.Col>
          </Grid>
        </Center>
      </Box>
    </>
  );
}
