import { Box, Button, Divider, Group, Paper, Text } from '@mantine/core';
import { IconCornerUpLeft, IconMessage2 } from '@tabler/icons-react';
import './Components.css';
import { useEffect, useState } from 'react';
import { useMedplumContext } from '@medplum/react';
import { Appointment } from '@medplum/fhirtypes';
import FeedBackRatingModal from '../../../components/FeedBackRatingModal';
import { showNotification } from '@mantine/notifications';

interface CallSessionEndedProps {
  appointmentId: string;
  documentId?: string;
}

const CallSessionEnded = ({ appointmentId, documentId }: CallSessionEndedProps): JSX.Element => {
  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const { medplum } = useMedplumContext();
  const [value] = useState(0);
  const [popupOpened, setPopupOpened] = useState(false);

  useEffect(() => {
    // Fetch appointment details when component mounts
    const fetchAppointment = async (): Promise<void> => {
      const appointmentData = await medplum.readResource('Appointment', appointmentId);
      setAppointment(appointmentData);
    };

    fetchAppointment().catch((error) => {
      console.error('Error fetching appointment:', error);
    });
  }, [appointmentId]);

  useEffect(() => {
    if (value > 0) {
      setPopupOpened(true);
    }
  }, [value]);

  // Component to display a detail item with a label and value
  const DetailItem = ({ label, value }: { label: string; value: string }): JSX.Element => {
    return (
      <Box w={'100%'} mb={30}>
        <Text size="sm" color="#475467" mb={4} fw={400}>
          {label}
        </Text>
        <Text
          onClick={
            label === 'Patient Name'
              ? () => window.open(`/Patient/patient-dashboard/${patientId}`, '_blank')
              : undefined
          }
          size="sm"
          fw={600}
          color={label === 'Patient Name' ? '#9552E8' : '#344054'}
          style={{ cursor: label === 'Patient Name' ? 'pointer' : 'default' }}
        >
          {value}
        </Text>
      </Box>
    );
  };

  // Extract patient and provider names, date and time of session from appointment
  const patientName =
    appointment?.participant?.find((p) => p.actor?.reference?.startsWith('Patient'))?.actor?.display || 'N/A';
  const providerName =
    appointment?.participant?.find((p) => p.actor?.reference?.startsWith('Practitioner'))?.actor?.display || 'N/A';
  const dateOfSession = appointment?.start ? new Date(appointment?.start).toLocaleDateString() : 'N/A';
  const timeOfSession =
    appointment?.start && appointment?.end
      ? `${new Date(appointment?.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${new Date(
          appointment?.end
        ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - (${appointment?.minutesDuration} minutes)`
      : 'N/A';
  const patientId = appointment?.participant
    ?.find((p) => p.actor?.reference?.startsWith('Patient'))
    ?.actor?.reference?.split('/')[1];
  // Handle popup submit
  const handlePopupClose = (): void => {
    setPopupOpened(false);
  };

  const handleFeedbackClick = (): void => {
    if (!documentId) {
      showNotification({ color: 'red', message: 'Please generate notes before giving feedback' });
      return;
    }
    setPopupOpened(true);
  };

  return (
    <>
      <Paper shadow="xs" p="xl" bg={'#F9F5FF'} h={'80vh'} radius="md">
        <Text
          mb="lg"
          size="18px"
          style={{
            textAlign: 'left',
            justifyContent: 'center',
            fontWeight: 600,
            lineHeight: '45px',
            color: '#101828 !important',
          }}
        >
          Session Overview
        </Text>
        <Divider mb={'xl'} />
        <Box>
          <Box className="session-detail-box">
            <DetailItem label="Patient Name" value={patientName} />
            <DetailItem label="Provider Name" value={providerName} />
          </Box>
          <Box className="session-detail-box">
            <DetailItem label="Date of Session" value={dateOfSession} />
            <DetailItem label="Time of Session" value={timeOfSession} />
          </Box>
        </Box>

        <Group justify="center" mt={'xl'} mr={'35px'} style={{ gap: '32px' }}>
          <Button
            leftSection={<IconCornerUpLeft size={20} color="#667085" />}
            size="md"
            h={'40px'}
            w={'fit-content'}
            radius="md"
            className="return-home-btn"
            onClick={() => window.location.replace(`/Telehealth`)}
          >
            Go to Appointment
          </Button>
          <Button
            leftSection={<IconMessage2 size={20} />}
            size="md"
            h={'40px'}
            w={'fit-content'}
            radius="md"
            className="Feedback-btn"
            title={!documentId ? 'Submit Feedback after notes are generated' : 'Give feedback'}
            onClick={() => handleFeedbackClick()}
          >
            Feedaback
          </Button>
        </Group>
      </Paper>
      {popupOpened && (
        <FeedBackRatingModal
          opened={popupOpened}
          onClose={handlePopupClose}
          documentId={documentId || ''}
          value={value}
        />
      )}
    </>
  );
};

export default CallSessionEnded;
