/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import { Grid, TextInput, Text, Box, Card, Button, Divider, Select, Radio } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useMedplum } from '@medplum/react';
import { createPractitioner, getAllOrganization, getPracctitionerRolesList, getReadResource } from '../../fhirApi';
import { showNotification } from '@mantine/notifications';

interface Organization {
  id: string;
  name: string;
}

export const PractitionersForm: React.FC = () => {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const practitionerID = useParams().id || '';
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accountStatus, setaccountStatus] = useState<string>('true');
  const [selectedOrganizationName, setSelectedOrganizationName] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await getAllOrganization(medplum);
      if (response !== null) {
        const orgs = response.entry.map((entry: any) => {
          const resource = entry.resource;
          return {
            id: resource.id,
            name: resource.name,
          };
        });
        setOrganizations(orgs);
      }
    };

    fetchOrganizations();
  }, []);

  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    organizationName: '',
    gender: '',
    address: {
      line: ['', ''],
      city: '',
      state: '',
      postalCode: '',
    }
  });

  const [errors, setErrors] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
    organizationName?: string;
    gender?: string;
  }>({});

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name.startsWith('address.line.')) {
      const index = parseInt(name.split('.')[2], 10);
      setFormState((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          line: prevState.address.line.map((line, i) => (i === index ? value : line)),
        },
      }));
    } else if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormState((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (practitionerID) {
      getReadResource(medplum, 'Practitioner', practitionerID).then((response) => {
        if (response) {
          const practitioner = response;
          const telecomMap = practitioner?.telecom?.reduce((acc: any, curr: any) => ({ ...acc, [curr.system]: curr.value }), {});
          setFormState({
            ...formState,
            firstName: practitioner?.name?.[0]?.given?.[0] || '',
            lastName: practitioner?.name?.[0]?.family || '',
            email: telecomMap['email'] || '',
            phoneNo: telecomMap['phone'] || '',
            gender: practitioner?.gender || '',
            address: { ...formState.address, ...practitioner?.address?.[0] },
          });
          setaccountStatus(practitioner?.active ? 'true' : 'false');
        }
        getPracctitionerRoles();
      }).catch((error) => {
        console.log(error);
      });
    }
  }, []);
  
  const getPracctitionerRoles = () => {
    getPracctitionerRolesList(medplum, practitionerID).then((response) => {
      if (response) {
        const PractitionerRole = response?.entry?.[0]?.resource;
        setFormState((prevState) => ({ ...prevState, organizationName: PractitionerRole?.organization?.reference?.split('/')[1] ?? '' }));
        setSelectedOrganizationId(PractitionerRole?.organization?.reference?.split('/')[1] ?? '');
        setSelectedOrganizationName(PractitionerRole?.organization?.display ?? '');
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const handleSelectChange = (name: string) => (value: string | null) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'organizationName') {
      setSelectedOrganizationId(value);
      clearError('organizationName');
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors: { [key: string]: string } = {};

    if (!formState.firstName) {
      formIsValid = false;
      errors['firstName'] = 'First Name is required';
    }

    if (!formState.lastName) {
      formIsValid = false;
      errors['lastName'] = 'Last Name is required';
    }

    if (!formState.email) {
      formIsValid = false;
      errors['email'] = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      formIsValid = false;
      errors['email'] = 'Email is not valid';
    }

    if (!formState.organizationName) {
      formIsValid = false;
      errors['organizationName'] = 'Organization Name is required';
    }

    if (!formState.gender) {
      formIsValid = false;
      errors['gender'] = 'Gender is required';
    }
    setErrors(errors);
    return formIsValid;
  };

  function clearError(field: string) {
    setErrors((prevErrors) => {
      return { ...prevErrors, [field]: null };
    });
  }

  const createNewPractitioner = () => {
    if (validateForm()) {
      setIsSubmitting(true);
      const { email, firstName: first_name, lastName: last_name, gender, address, phoneNo } = formState;
      const payload = {
        email,
        first_name,
        last_name,
        phoneNo,
        gender,
        address: [
          {
            line: address.line,
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
          },
        ],
        organization_id: selectedOrganizationId,
        account_status: accountStatus === 'true'
      };
      createPractitioner(medplum, payload)
        .then((response: any) => {
          setIsSubmitting(false);
          
          if (response) {
            if (practitionerID) {
              showNotification({ color: 'green', message: 'Practitioner updated successfully.' });
            } else {
              showNotification({ color: 'green', message: 'Practitioner created successfully.' });
            }
            navigate('/Practitioner');
          } else {
            showNotification({ color: 'red', message: 'An error occurred while creating the practitioner.' });
          }
        })
        .catch((error: any) => {
  
          setIsSubmitting(false);
          if (error.response.status === 500) {
            showNotification({
              color: 'red', message: 'An error occurred while creating the practitioner. '
            });
          }
        });
    }
  };
  console.log(organizations)
  return (
    <Box px="lg" py="sm" style={{ paddingBottom: '0px !important' }}>
      <Grid mb="md">
        <Grid.Col span={{ base: 12, lg: 9 }}>
          <Card withBorder radius="md" style={{ padding: '0px !important', boxShadow: '0px 1px 2px 0px #0000001A' }}>
            <Card.Section p="lg" style={{ padding: '2rem 2rem !important' }}>
              <form>
                <div>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}>
                      <Text style={{ fontSize: '14px', color: '#272D41' }}>
                        Name<span style={{ color: 'red' }}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4.5 }}>
                      <TextInput
                        placeholder="First Name"
                        name="firstName"
                        onChange={handleInputChange}
                        onInput={() => clearError('firstName')}
                        required
                        value={formState.firstName}
                      />
                      {errors.firstName && <span style={{ color: 'red' }}>{errors.firstName}</span>}
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4.5 }}>
                      <TextInput
                        placeholder="Last Name"
                        name="lastName"
                        onChange={handleInputChange}
                        onInput={() => clearError('lastName')}
                        required
                        value={formState.lastName}
                      />
                      {errors.lastName && <span style={{ color: 'red' }}>{errors.lastName}</span>}
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}>
                      <Text style={{ fontSize: '14px', color: '#272D41' }}>
                        Email<span style={{ color: 'red' }}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 9 }}>
                      <TextInput
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={handleInputChange}
                        onInput={() => clearError('email')}
                        required
                        value={formState.email}
                      />
                      {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}>
                      <Text style={{ fontSize: '14px', color: '#272D41' }}>
                        Phone No
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 9 }}>
                      <TextInput
                        placeholder="Phone No"
                        name="phoneNo"
                        onChange={handleInputChange}
                        maxLength={10}
                        value={formState.phoneNo}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}>
                      <Text style={{ fontSize: '14px', color: '#272D41' }}>
                        Gender<span style={{ color: 'red' }}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 9 }}>
                      <Select
                        placeholder="Gender"
                        name="gender"
                        value={formState.gender}
                        onChange={handleSelectChange('gender')}
                        onInput={() => clearError('gender')}
                        required
                        data={[
                          { value: 'male', label: 'Male' },
                          { value: 'female', label: 'Female' },
                          { value: 'other', label: 'Other' },
                          { value: 'unknown', label: 'Unknown' }
                        ]}
                      />
                      {errors.gender && <span style={{ color: 'red' }}>{errors.gender}</span>}
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}>
                      <Text style={{ fontSize: '14px', color: '#272D41' }}>
                        Address
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 9 }}>
                      <TextInput
                        placeholder="Address Line 1"
                        name="address.line.0"
                        onChange={handleInputChange}
                        value={formState.address.line[0]}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}></Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 9 }}>
                      <TextInput
                        placeholder="Address Line 2"
                        name="address.line.1"
                        onChange={handleInputChange}
                        value={formState.address.line[1]}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}></Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4.5 }}>
                      <TextInput
                        placeholder="City"
                        name="address.city"
                        onChange={handleInputChange}
                        value={formState.address.city}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4.5 }}>
                      <TextInput
                        placeholder="State"
                        name="address.state"
                        onChange={handleInputChange}
                        value={formState.address.state}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}></Grid.Col>

                    <Grid.Col span={{ base: 12, lg: 4.5 }}>
                      <TextInput
                        placeholder="Postal Code"
                        name="address.postalCode"
                        onChange={handleInputChange}
                        value={formState.address.postalCode}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'start' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}>
                      <Text style={{ fontSize: '14px', color: '#272D41' }}>
                        Organization<span style={{ color: 'red' }}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 9 }}>
                      {medplum.isSuperAdmin() ? (
                        <>
                          <Select
                            placeholder="Organization"
                            name="organizationName"
                            value={formState.organizationName}
                            onChange={handleSelectChange('organizationName')}
                            onInput={() => clearError('organizationName')}
                            required
                            data={organizations.map((org) => ({ value: org.id, label: org.name }))}
                          />
                          {errors.organizationName && <span style={{ color: 'red' }}>{errors.organizationName}</span>}
                        </>
                      ) : (
                        <Text style={{ fontSize: '14px', color: '#272D41' }}>
                          {selectedOrganizationName}
                        </Text>
                      )}
                    </Grid.Col>
                  </Grid>
                  <Grid style={{ marginBottom: '15px', alignItems: 'end' }}>
                    <Grid.Col span={{ base: 12, lg: 3 }}>
                      <Text style={{ fontSize: '14px', color: '#272D41' }}>
                        Account Status
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 9 }}>
                    {medplum.isSuperAdmin() ? (
                      <Radio.Group
                        value={accountStatus}
                        onChange={setaccountStatus}
                        name="active"
                      >
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                          <Radio value="true" label="Active" />
                          <Radio value="false" label="Inactive" ml={10} />
                        </div>
                      </Radio.Group>
                    ) : (
                      <Text style={{ fontSize: '14px', color: '#272D41' }}>
                        {accountStatus === 'true' ? 'Active' : 'Inactive'}
                      </Text>
                    )}
                    </Grid.Col>
                  </Grid>
                </div>
              </form>
              <Divider my="sm" />
              <Grid style={{ justifyContent: 'end', margin: '20px 0' }}>
                <Button
                  style={{
                    border: '1.5px solid #3673B6',
                    background: 'transparent',
                    padding: '8px 60px',
                    color: '#3673B6',
                    fontWeight: 600,
                    marginRight: '10px',
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                  onClick={() => navigate(`/Practitioner`)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  style={{
                    background: '#3673B6',
                    padding: '8px 60px',
                    fontWeight: 600,
                    '&:hover': {
                      background: '#3673B6',
                    },
                  }}
                  onClick={createNewPractitioner}
                >
                  Save
                </Button>
              </Grid>
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>
    </Box>
  );
};


