import { useEffect, useState } from 'react';
import { Drawer, Divider, Skeleton } from '@mantine/core';
import { QuestionnaireResponse } from '@medplum/fhirtypes';
import AssessmentPreview from './AssessmentPreview';
import { getAssessmentList } from '../../fhirApi';
import { useMedplum } from '@medplum/react';
import Assessment from './Assessment';

interface AssessmentDetailsProps {
  opened: boolean;
  close: () => void;
  assessmentDetails: any;
  assessmentName?: string;
}

const AssessmentDetailsModel = (props: AssessmentDetailsProps) => {
  const stepLabels = ['Assessment Response Overview', 'AI Insights'];
  const [activeStep, setActiveStep] = useState(0);
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse>();
  const [assessmentInsightsSummaryData, setAssessmentInsightsSummaryData] = useState<string[] | undefined>([]);
  const [assessmentCompletionDate, setAssessmentCompletionDate] = useState('');
  const [assessmentName, setAssessmentName] = useState('');
  const [hasData, setHasData] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const medplum = useMedplum();

  useEffect(() => {
    if (props.opened) {
      setLoading(true);
      getAssessmentList(medplum, props.assessmentDetails.id)
        .then((response) => {
          setAssessmentName(props.assessmentDetails.assessmentName);
          setQuestionnaireResponse(response.entry[0].resource);
          const aiInsightsExtension = response?.entry?.[0]?.resource?.extension?.find(
            (entry: any) => entry.url === 'http://ai-insights'
          );

          if (aiInsightsExtension) {
            getAIInsights(aiInsightsExtension, response?.entry?.[0]?.resource?.authored);
          } else {
            console.warn('AI Insights extension not found');
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.opened]);

  const getAIInsights = (data: any, date: string) => {
    setAssessmentCompletionDate(date || '');
    if (data) {
      const lines = data?.valueString?.split('\n');
      setAssessmentInsightsSummaryData(lines);
      setHasData(true);
    } else {
      setHasData(false);
    }
  };

  return (
    <Drawer.Root position="right" size="55%" opened={props.opened} onClose={props.close} padding="md">
      <Drawer.Overlay />
      <Drawer.Content
        style={{
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <Drawer.Header>
          <Drawer.Title>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginBottom: '10px' }}>
              {stepLabels.map((label, index) => (
                <button
                  key={label}
                  style={{
                    padding: '10px',
                    backgroundColor: '#fff',
                    color: '#000',
                    fontSize: '14px',
                    fontWeight: '500',
                    textAlign: 'left',
                    border: 'none',
                    fontFamily: 'inherit',
                    borderBottom: index === activeStep ? '2px solid #1b5390' : '2px solid #a4a7ab',
                    cursor: 'pointer',
                  }}
                  onClick={() => setActiveStep(index)}
                >
                  {label}
                </button>
              ))}
            </div>
          </Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Divider />
        <Drawer.Body
          style={{
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 100px)',
            padding: '16px',
          }}
        >
          {loading ? (
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Skeleton height={30} width="100%" />
              <Skeleton height={20} width="100%" />
              <Skeleton height={20} width="100%" />
              <Skeleton height={20} width="100%" />
            </div>
          ) : activeStep === 0 ? (
            <AssessmentPreview questionnaireResponse={questionnaireResponse} assessmentName={assessmentName} />
          ) : activeStep === 1 ? (
            <div>
              <Assessment
                assessmentCompletionDate={assessmentCompletionDate}
                assessmentInsightsSummaryData={assessmentInsightsSummaryData}
                hasData={hasData}
              />
            </div>
          ) : null}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export default AssessmentDetailsModel;
