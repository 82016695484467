/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MedplumClient } from '@medplum/core';
import axios from './utils/axios';
import { AuditEvent, BundleEntry, Organization } from '@medplum/fhirtypes';
import {
  ConditionPayload,
  CreateClinicalNotePayload,
  GetFeedbackListPayload,
  getZoomSignaturePayloadProps,
  SubmitNoteFeedbackPlayload,
  submitTranscriptionPayloadProps,
  updateClinicalNotePayloadProps,
} from './utils/constant';

export const fetchPatients = async (medplum: any, query: string, filters?: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(
      `/fhir/R4/$graphql`,
      {
        query: query,
        filters: filters,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const fetchPatientDetails = async (medplum: any, query: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(
      `/fhir/R4/$graphql`,
      {
        query: query,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const exportMedicalRecord = async (
  medplum: MedplumClient,
  patientId: string,
  resources: string[],
  existingFile: boolean,
  exportType: string,
  isPII: boolean,
  startDate?: string,
  endDate?: string
) => {
  try {
    const token = await medplum.getAccessToken();
    const resourcesParam = resources.join(',');
    const existingFileParam = existingFile ? true : false;
    const isPIIParam = isPII ? true : false;

    let url = `/api/pragmaconnect/export-patient-data?patientId=${patientId}&resources=${resourcesParam}&existingFile=${existingFileParam}&exportType=${exportType}&isPII=${isPIIParam}`;

    if (startDate) {
      url += `&fromDate=${startDate}`;
    }

    if (endDate) {
      url += `&toDate=${endDate}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const getPatientClinicalData = async (medplum: MedplumClient, patientId: string) => {
  try {
    const response = await medplum.get(`/api/pragmaconnect/get-patient-clinical-data-document-date?patientId=${patientId}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAiAssistantPatientData = async (
  medplum: MedplumClient,
  patientId: string,
  question: string,
  conversationSessionId: string
) => {
  const token = await medplum.getAccessToken();
  if (patientId) {
    try {
      let url = `/api/pragmaconnect/ai-asssistant-chat?patientId=${patientId}&question=${question}`;
      if (conversationSessionId) {
        url = url + `&sessionId=${conversationSessionId}`;
      }
      const response = await axios.get(url, {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
};

export const updateAIAssistantChat = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.put('/api/pragmaconnect/update-ai-assistant-chat', payload, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAiAssistantChatHistory = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  try {
    let url = `/fhir/R4/Communication?_count=500&_offset=0&_sort=-_lastUpdated&_total=accurate&category=chatbot`;
    const response = await axios.get(url, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateChatHistoryTitle = async (medplum: any) => {
  const token = await medplum.getAccessToken();
  try {
    let url = `/api/pragmaconnect/update-ai-assistant-chat-title`;
    const response = await axios.put(
      url,
      {},
      {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getOrganization = async (medplum: any, orgId: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`/fhir/R4/Organization/${orgId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateOrganization = async (medplum: any, orgData: any, orgId: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.put(`fhir/R4/Organization/${orgId}`, orgData, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createOrganizations = async (medplum: MedplumClient, orgData: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`fhir/R4/Organization`, orgData, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createOrganizationPolicyAndAdmin = async (medplum: MedplumClient, orgData: Organization) => {
  const payload = {
    organizationName: orgData.name,
    organizationId: orgData.id,
    user: {
      firstName: orgData.contact?.[0]?.name?.given?.[0],
      lastName: orgData.contact?.[0]?.name?.family,
      email: orgData.contact?.[0]?.telecom?.find((contact) => contact.system === 'email')?.value,
    },
  };
  try {
    return await medplum.post('/api/pragmaconnect/create-organization-project', payload);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createPractitioner = async (medplum: MedplumClient, practitioner: any) => {
  const response = await medplum.post(`/api/pragmaconnect/create-organization-practitioner`, practitioner);
  return response;
};

export const createNewSession = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/api/pragmaconnect/save-patient-session`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createTelehealthSession = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/api/pragmaconnect/create-telehealth-session`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const exportClinicalNote = async (medplum: any, patientId: any) => {
  try {
    const token = await medplum.getAccessToken();

    const response = await axios.get(`/api/pragmaconnect/download-clinical-note?id=${patientId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const getClinicalNotes = async (medplum: any, patientId: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`/api/analytics/get-clinical-note-by-patientId?patientId=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const loadClinicalStudy = async (medplum: MedplumClient, studyId: string) => {
  try {
    const response = await medplum.post(`/fhir/R4/Bot/3313d556-40da-449c-a9bf-549ec99541c9/$execute`, { studyId });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const clinicalStudyData = async (medplum: MedplumClient, studyId: string, patientId: string) => {
  try {
    const response = await medplum.post(`/api/pragmaconnect/patient-eligibility-clinical-study`, {
      patientId,
      studyId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const clinicalStudyNotification = async (
  medplum: MedplumClient,
  patientId: string,
  patientName: string,
  researchStudyId: string,
  researchStudyName: string,
  researchSubjectId: string,
  message: string
) => {
  try {
    const response = await medplum.post(`/api/pragmaconnect/generate-clinical-study-notification`, {
      patientId,
      patientName,
      researchStudyId,
      researchStudyName,
      researchSubjectId,
      message,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQualifiedPatients = async (medplum: MedplumClient) => {
  try {
    const response = await medplum.get('api/pragmaconnect/get-qualified-patients');
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllClinicalStudyConsents = async (medplum: MedplumClient) => {
  try {
    const response = await medplum.get('api/pragmaconnect/track-all-clinical-study-consents');
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getClinicalTrialNotification = async (medplum: MedplumClient) => {
  try {
    const response = await medplum.get(
      `fhir/R4/CommunicationRequest?_count=20&_offset=0&_sort=-_lastUpdated&_total=accurate&group-identifier=Clinical+Trial+Notification`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const consentStatus = async (medplum: MedplumClient, researchSubjectId: string) => {
  try {
    const response = await medplum.get(`fhir/R4/ResearchSubject/${researchSubjectId}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCoverageInfo = async (medplum: MedplumClient, patientId: any) => {
  try {
    const response = await medplum.get(`/api/analytics/get-coverage-info-by-patientId?patientId=${patientId}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getClaimInfo = async (medplum: MedplumClient, patientId: any) => {
  try {
    const response = await medplum.get(`/api/analytics/get-claim-info-by-patientId?patientId=${patientId}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllOrganization = async (medplum: MedplumClient) => {
  try {
    const response = await medplum.get(`fhir/R4/Organization`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching all organizations:', error);
    return null;
  }
};

export const statisticCounts = async (medplum: MedplumClient) => {
  try {
    const response = await medplum.get(`api/analytics/statistic-counts`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching statistic counts:', error);
    return null;
  }
};

export const integratedOrganizations = async (medplum: MedplumClient) => {
  try {
    const response = await medplum.get(`api/analytics/integrated-organizations`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching integrated organizations:', error);
    return null;
  }
};

export const patientCountsByOrganization = async (medplum: MedplumClient, ehr: string) => {
  try {
    const response = await medplum.get(`api/analytics/patient-counts-by-organization?ehr=${ehr}`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching patient counts by organization:', error);
    return null;
  }
};

export const patientCountsByEhr = async (medplum: MedplumClient) => {
  try {
    const response = await medplum.get(`api/analytics/get-patient-counts-by-ehr`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching patient counts by EHR:', error);
    return null;
  }
};

export const ehrList = async (medplum: MedplumClient) => {
  try {
    const response = await medplum.get(`api/analytics/get-ehr-list`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching the EHR list:', error);
    return null;
  }
};

export const getDemographic = async (medplum: MedplumClient, ehr: string) => {
  try {
    const response = await medplum.get(`api/analytics/get-demographic-data?ehr=${ehr}`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching demographic data:', error);
    return null;
  }
};

export const registeredPatients = async (medplum: MedplumClient, ehr: string) => {
  try {
    const response = await medplum.get(`api/analytics/get-newly-registered-patients`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getOrganizationsByEhr = async (medplum: MedplumClient, ehr: string) => {
  try {
    const response = await medplum.get(`api/analytics/get-organizations-by-ehr?ehr=${ehr}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const organizationPatientDemographics = async (medplum: MedplumClient, ehr: string, org: string) => {
  try {
    const response = await medplum.get(
      `api/analytics/organization-patient-demographics?ehr=${ehr}&organizationId=${org}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const organizationRegisteredPatients = async (medplum: MedplumClient, ehr: string, org: string) => {
  try {
    const response = await medplum.get(
      `api/analytics/organization-registered-patients?ehr=${ehr}&organizationId=${org}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAssessmentList = async (medplum: MedplumClient, assessmentId: string) => {
  try {
    const response = await medplum.get(`fhir/R4/QuestionnaireResponse?_id=${assessmentId}`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching the assessment list:', error);
    return null;
  }
};

export const analyticsSummary = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/get-step-count-analytics?patientId=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const heartRateAnalytics = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/get-heart-rate-analytics?patientId=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const weightAnalytics = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/get-weight-analytics?patientId=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const bloodPressureAnalytic = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/get-blood-pressure-analytics?patientId=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const assessmentInsightsSummary = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/pragmaconnect/get-patient-assessment-insights?patient_id=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error: any) {
    console.error(error);
    return null;
  }
};

export const progressReportSummary = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/pragmaconnect/get-patient-clinical-notes-insights?patient_id=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error: any) {
    console.error(error);
    return null;
  }
};

export const getNotifications = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(
      `fhir/R4/AuditEvent?_count=200&_offset=0&_sort=-_lastUpdated&_total=accurate&outcome:not=4`,
      {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    const filteredEntries = {
      data: {
        entry: response.data.entry.filter((entry: BundleEntry) => (entry.resource as AuditEvent)?.outcomeDesc),
      },
    };
    return filteredEntries;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const organizationStatisticCounts = async (medplum: MedplumClient, orgId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/organization-statistic-counts?id=${orgId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const appointmentsByOrganization = async (medplum: MedplumClient, orgId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/get-appointments-by-organization?organizationId=${orgId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const practitionersByOrganization = async (medplum: MedplumClient, orgId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/get-practitioners-by-organization?organizationId=${orgId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const patientsByOrganization = async (medplum: MedplumClient, orgId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/organization-patient-list?organizationId=${orgId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const serviceRequest = async (medplum: MedplumClient, orgId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/analytics/get-service-requests-by-organization?organizationId=${orgId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getZoomSignature = async (medplum: MedplumClient, payload: getZoomSignaturePayloadProps) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/api/pragmaconnect/join-telehealth-session`, payload, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const submitTranscription = async (medplum: MedplumClient, payload: submitTranscriptionPayloadProps) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/api/pragmaconnect/end-telehealth-session`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const fetchAppointmentStatusList = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();
  const body = {
    url: 'http://hl7.org/fhir/ValueSet/appointmentstatus|4.0.1',
    filter: '',
  };

  try {
    const response = await axios.get(`/fhir/R4/ValueSet/$expand?url=${body.url}&filter=${body.filter}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const getPrimaryConditions = async (searchText: string) => {
  try {
    const response = await axios.get(`/api/medical-conditions/search?searchText=${searchText}`);
    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const registerPractitioner = async (medplum: MedplumClient, practitioner: any) => {
  const response = await medplum.post(`/api/pragmaconnect/self-register-practitioner`, practitioner);
  return response;
};

export const verifyOrganizationConnection = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/api/pragmaconnect/verify-organization-connection`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const healthSummary = async (medplum: MedplumClient, patientId: string, refreshSummary: boolean) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(
    `/api/pragmaconnect/generate-patient-health-summary`,
    {
      patientId: patientId,
      refreshSummary: refreshSummary,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response.data;
};

export const downloadHealthSummary = async (medplum: MedplumClient, documentRefId: string) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(
    `/api/pragmaconnect/download-ai-health-summary?documentRefId=${documentRefId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      responseType: 'blob'
    }
  );
  return response;
};

export const getTelehealthAlerts = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/api/pragmaconnect/get-telehealth-alerts`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return response.data;
};

export const updateClinicalNote = async (medplum: MedplumClient, payload: updateClinicalNotePayloadProps) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/api/pragmaconnect/update-clinical-note`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};
export const getQualification = async (search: string) => {
  const response = await axios.get(`/api/practitioner-qualification/search?searchText=${search}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response?.data;
};

export const createPatientCondition = async (payload: {
  patientId: string;
  primaryCondition: { code: string; display: string };
  stage: { code: string; display: string };
  conditionSeverity: { code: string; display: string };
  projectName: string;
  isPrimaryCondition: boolean;
}) => {
  try {
    const response = await axios.post(`/api/create-patient-condition`, payload);
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const updatePatientCondition = async (medplum: MedplumClient, payload: ConditionPayload) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/api/pragmaconnect/update-patient-condition`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const submitNoteFeedback = async (medplum: MedplumClient, payload: SubmitNoteFeedbackPlayload) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/api/pragmaconnect/submit-feedback`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};
export const getFeedbackList = async (medplum: MedplumClient, payload: GetFeedbackListPayload) => {
  try {
    const { id, searchType, itemsPerPage, offset, category, startDate, endDate } = payload;
    const token = await medplum.getAccessToken();
    let actor = '';
    if (id) {
      if (searchType === 'practitioner') {
        actor = `&subject=Practitioner/${id}`;
      } else if (searchType === 'patient') {
        actor = `&identifier=Patient/${id}`;
      }
    }

    let url = `/api/pragmaconnect/get-feedback-list?_count=${itemsPerPage}${actor}&_offset=${offset}&_sort=-_lastUpdated`;
    if (category !== 'Rating') {
      url += `&category=${category}`;
    } else {
      url += `&category=RATING`;
    }

    if (startDate && endDate) {
      const formattedStartDate = encodeURIComponent(`sa${startDate}`);
      const formattedEndDate = encodeURIComponent(`eb${endDate}`);

      url += `&authored=${formattedStartDate}&authored=${formattedEndDate}`;
    }

    const response = await axios.get(url, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred:', error);
    return error;
  }
};

export const getSavedSession = async (medplum: MedplumClient, appointmentId: string) => {
  try {
    const token = await medplum.getAccessToken();

    const response = await axios.get(`/api/pragmaconnect/get-session/${appointmentId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response?.data;
  } catch (error) {
    console.error('An error occurred:', error);
    return error;
  }
};

export const createClinicalNote = async (medplum: MedplumClient, payload: CreateClinicalNotePayload) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/api/pragmaconnect/create-clinical-note`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const getPracctitionerRolesList = async (medplum: MedplumClient, practitionerId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`/fhir/R4/PractitionerRole?practitioner=Practitioner/${practitionerId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getReadResource = async (medplum: MedplumClient, resourceType: string, resourceId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`/fhir/R4/${resourceType}/${resourceId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getConditionList = async (medplum: MedplumClient, patientId: string) => {
  try {
    const response = await medplum.get(`/fhir/R4/Condition?_total=accurate&patient=Patient/${patientId}`);
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const markPatientLatestCondtion = async (medplum: MedplumClient, patientId: string) => {
  try {
    const response = await medplum.get(`/api/pragmaconnect/manage-patient-condition-assessment?patientId=${patientId}`);
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const importPatientDetails = async (medplum: MedplumClient, patientId: string, ehr_token: string, api_url: string, ehr_type: string) => {
  try {
    let postData = {
      patient_id: patientId,
      ehr_type: ehr_type,
    }
    const response = await medplum.post('/api/pragmaconnect/import-patient-details', postData, 'application/json', {
      headers: {
        ehr_token: ehr_token,
        api_url: api_url
      }
    })
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
}

export const saveEhrPatientById = async (medplum: MedplumClient, patientId: string, ehr: string, ehr_token: string, api_url: string) => {
  try {
    let postData = {
      patient_id: patientId,
    }
    const response = await medplum.post(`/ehr/${ehr.toLowerCase()}/save-patient`, postData, 'application/json', {
      headers: {
        ehr_token: ehr_token,
        api_url: api_url
      }
    })
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
}

export const loadCernerPatientsList = async (medplum: MedplumClient, patientName: string, ehr_token: string, api_url: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/sso/cerner-patient-list?name=${patientName}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
        'ehr_token': ehr_token,
        'api_url': api_url,
      },
    });
    return response?.data;
  } catch (error) {
    return (error as any )?.response?.data?.message ?? (error as Error )?.message;
  }
}

export const importLaunchPatient = async (medplum: MedplumClient, iss: string, launch: string, ehr_type: string) => {
  try {
    const postData = {
      ehrType: ehr_type,
      iss,
      launch
    };

    const headers = {
      'app_token': await medplum.getAccessToken() ?? '',
      'Content-Type': 'application/json'
    };

    const response = await axios.post('/api/sso/load-ehr-launch-data', postData, { headers });
    return response.data;
  } catch (error) {
    return null;
  }
}