import {
  Table,
  Text,
  Group,
  NativeSelect,
  Rating,
  Paper,
  Box,
  Badge,
  Divider,
  Flex,
  Button,
  Tooltip,
} from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AsyncAutocomplete, useMedplum } from '@medplum/react';
import { IconChevronDown, IconChevronUp, IconEye, IconSearch } from '@tabler/icons-react';
import { getFeedbackList } from '../../fhirApi';
import './Feedback.css';
import TitleComponent from '../TitleComponent';
import CustomPagination from '../../components/CustomPagination';
import { DateInput } from '@mantine/dates';
import {
  buildGraphQLQuery,
  getResourcesFromResponse,
  HeaderSearchTypes,
  SearchGraphQLResponse,
  toOption,
} from '../../../../react/src/AppShell/HeaderSearchInput';
import { CommunicationRequest } from '@medplum/fhirtypes';

// const useStyles = createStyles(() => ({
//   tr: {
//     cursor: 'pointer',
//   },
//   rightCorner: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     marginRight: '20px',
//   },
// }));

interface FeedbackData {
  fullUrl: string;
  resource: CommunicationRequest;
}

interface FeedbackResponse {
  resourceType: string;
  type: string;
  entry: FeedbackData[];
  total: number;
  link: {
    relation: string;
    url: string;
  }[];
}

function Feedback(): JSX.Element {
  // const { classes } = useStyles();
  const [activity, setActivity] = useState<string>('Rating');
  const medplum = useMedplum();
  const [feedbackData, setFeedbackData] = useState<FeedbackData[]>([]);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [resourceData, setResourceData] = useState<FeedbackResponse | null>(null);

  const [offset, setOffset] = useState<number>(0);
  const skipEffect = useRef(false);
  const itemsPerPage = 10;

  type SearchType = 'patient' | 'practitioner' | 'date';
  const [searchType, setSearchType] = useState<SearchType>('patient');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const fetchFeedback = useCallback(
    async (id?: string | null, startDate?: string, endDate?: string) => {
      try {
        const payload = {
          category: activity,
          offset: offset,
          id: id ?? '',
          searchType: searchType,
          startDate: startDate,
          endDate: endDate,
          itemsPerPage: itemsPerPage,
        };
        const data: FeedbackResponse = await getFeedbackList(medplum, payload);
        setResourceData(data);
        setFeedbackData(data.entry || []);
      } catch (error) {
        console.error('Error fetching feedback:', error);
        setFeedbackData([]);
      }
    },
    [activity, medplum, offset, searchType, selectedId]
  );

  useEffect(() => {
    if (skipEffect.current) {
      skipEffect.current = false;
      return;
    }
    fetchFeedback(selectedId).catch((err) => console.error(err));
  }, [activity, medplum, offset, fetchFeedback, selectedId]);

  const renderValue = (data: FeedbackData, field: string): string | JSX.Element => {
    const resource = data.resource;
    switch (field) {
      case 'practitionerName':
        return resource?.subject?.display || '-';
      case 'patientName': {
        const patientNameExtension = resource?.extension?.find(
          (ext) => ext.url === '/intg/structure/extensions/patientName'
        );
        return patientNameExtension?.valueString || '-';
      }
      case 'session': {
        const session = resource?.extension?.find((ext) => ext.url === '/intg/structure/extensions/sessionName');
        return session?.valueString || '-';
      }
      case 'noteType': {
        const noteTypeExtension = resource?.extension?.find((ext) => ext.url === 'http://note-type');
        return noteTypeExtension?.valueString || '-';
      }
      case 'dateTime':
        return formatDateTime(resource?.authoredOn as string) || '-';
      case 'rating':
        return <Rating value={Number(resource?.category?.[0].coding?.[0].display)} size="xs" readOnly />;
      case 'viewSession': {
        const redirectLinkId = resource?.extension?.find(
          (ext) => ext.url === '/intg/structure/extensions/sessionId'
        );
        const sessionId = redirectLinkId?.valueString;
        return sessionId ? (
          <Tooltip label="View Session" withArrow>
            <Button
              style={{
                textDecoration: 'none',
                color: '#3673b6',
                backgroundColor: 'transparent',
                padding: '5px',
                fontSize: '11px',
                fontWeight: 700,
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '29px',
                marginLeft: '5px',
              }}
              onClick={() => window.open(`Telehealth/${sessionId}/meeting`, '_blank')}
            >
              <IconEye size={20} />
            </Button>
          </Tooltip>
        ) : (
          '-'
        );
      }
      default:
        return '-';
    }
  };

  const handleFeedbackSearch = useCallback(
    async (item: HeaderSearchTypes[]): Promise<void> => {
      const id = item.length > 0 ? item[0].id ?? null : null;
      setSelectedId(id);
      skipEffect.current = true;
      await fetchFeedback(id);
    },
    [fetchFeedback]
  );

  const formatDateTime = (dateTimeString: string): string => {
    if (!dateTimeString) {
      return '-';
    }

    const date = new Date(dateTimeString);

    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = `0${date.getMinutes()}`.slice(-2);

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12;
    const formattedHours = `0${hours}`.slice(-2);

    return `${month}-${day}-${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  const fields = ['practitionerName', 'patientName', 'session', 'noteType', 'rating', 'dateTime', 'viewSession'];

  const handlePageChange = (page: number): void => {
    setOffset((page - 1) * itemsPerPage);
  };

  const placeholderText = (): string => {
    switch (searchType) {
      case 'patient':
        return 'Search Patient';
      case 'practitioner':
        return 'Search Practitioner';
      case 'date':
        return 'Search Date';
      default:
        return '';
    }
  };

  const handleDateChange = async (date: Date | null): Promise<void> => {
    setSelectedDate(date);

    if (date) {
      const startTime = '00:01:00.000Z';
      const endTime = '23:59:00.000Z';

      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);

      const startDateString = `${year}-${month}-${day}T${startTime}`;
      const endDateString = `${year}-${month}-${day}T${endTime}`;

      await fetchFeedback(null, startDateString, endDateString);
    }
  };

  // Add this function for loading search data
  const loadData = useCallback(
    async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
      if (searchType === 'date') {
        return [];
      }
      const query = buildGraphQLQuery(input, searchType, 20);
      const options = { signal };
      const response = (await medplum.graphql(query, undefined, undefined, options)) as SearchGraphQLResponse;
      return getResourcesFromResponse(response, input);
    },
    [medplum, searchType]
  );
  return (
    <>
      <TitleComponent />
      <Paper className="feedback-table-container">
        <Box className="feedback-header" m="md" mb={0}>
          <Box className={`telehealth-title`}>
            <Text display={'flex'} fw={600} py={'10px'} fz={'18px'} color="#101828" lh={'28px'}>
              Feedback
            </Text>
            <Badge className="badge-style">{resourceData?.total} records</Badge>
          </Box>
        </Box>
        <Divider my="sm" color="#EAECF0" />
        <Group gap="sm" style={{ justifyContent: 'space-between' }}>
          <Flex align={'center'}>
            <Box>
              <Text className="search-by">Search by</Text>
              <NativeSelect
                data={[
                  { value: 'patient', label: 'Patient' },
                  { value: 'practitioner', label: 'Practitioner' },
                ]}
                value={searchType}
                onChange={(event) => setSearchType(event.currentTarget.value as SearchType)}
                ml={20}
                radius={'md'}
                mr={20}
                mb={24}
                styles={{
                  label: { marginBottom: '8px', marginLeft: '5px' },
                }}
              />
            </Box>

            {searchType === 'date' ? (
              <DateInput
                placeholder="Select date"
                value={selectedDate}
                onChange={handleDateChange}
                valueFormat="MM-DD-YYYY"
              />
            ) : (
              <Box pt={6} mt={-3}>
                <AsyncAutocomplete
                  key={`${activity}?${offset}`}
                  size="sm"
                  radius="md"
                  width={200}
                  leftSection={<IconSearch size={16} color="#3176C9" />}
                  placeholder={placeholderText()}
                  onChange={(items) => handleFeedbackSearch(items as HeaderSearchTypes[])}
                  loadOptions={loadData}
                  maxValues={0}
                  clearable={true}
                  toOption={toOption}
                />
              </Box>
            )}
          </Flex>
          <NativeSelect
            data={[
              { value: 'Rating', label: 'All Rating' },
              { value: '1', label: '1 Star' },
              { value: '2', label: '2 Stars' },
              { value: '3', label: '3 Stars' },
              { value: '4', label: '4 Stars' },
              { value: '5', label: '5 Stars' },
            ]}
            value={activity}
            onChange={(event) => setActivity(event.currentTarget.value)}
            mb="md"
            radius={'md'}
            m={20}
          />
        </Group>
        <Group>
          <Table className="feedback-table">
            <thead>
              <tr className="feedback-table-header">
                {fields.map((field) => (
                  <th key={field} className="feedback-table-head">
                    <Group gap="sm" style={{ justifyContent: 'space-between' }}>
                      <Text fw={500} size="sm" c={'#344054'} fz={'14px'} lh={'18px'}>
                        {(() => {
                          switch (field) {
                            case 'practitionerName':
                              return 'Practitioner Name';
                            case 'patientName':
                              return 'Patient Name';
                            case 'session':
                              return 'Session';
                            case 'noteType':
                              return 'Note Type';
                            case 'dateTime':
                              return 'Date & Time';
                            case 'viewSession':
                              return 'View Session';
                            case 'rating':
                              return 'Rating';
                            default:
                              return '';
                          }
                        })()}
                      </Text>
                    </Group>
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {feedbackData && feedbackData.length > 0 ? (
                feedbackData.map((resource: any) => (
                  <React.Fragment key={resource.resource.id}>
                    <tr
                      key={`row-${resource.resource.id}`}
                      style={{ cursor: 'pointer' }}
                      className={expandedRow === resource.resource.id ? 'bg-color-class' : ''}
                      onClick={() => setExpandedRow(expandedRow === resource.resource.id ? null : resource.resource.id)}
                    >
                      {fields.map((field, i) => (
                        <td key={`cell-${resource.resource.id}-${i}`} style={{ padding: '1rem' }}>
                          {renderValue(resource, field)}
                        </td>
                      ))}
                      <td key={`expand-icon-${resource.resource.id}`}>
                        {(() => {
                          if (!resource?.resource?.note?.[0]?.text) {
                            return <div className="h-5 w-5" />;
                          }
                          return expandedRow === resource.resource.id ? (
                            <IconChevronUp className="h-5 w-5" />
                          ) : (
                            <IconChevronDown className="h-5 w-5" />
                          );
                        })()}
                      </td>
                    </tr>
                    {expandedRow === resource.resource.id && resource?.resource?.note?.[0]?.text && (
                      <tr key={`expanded-${resource.resource.id}`} style={{ border: '1px solid #E5E5E5' }}>
                        <td colSpan={fields.length} style={{ padding: '1rem' }}>
                          <div style={{ marginBottom: '5px' }}>
                            <span style={{ color: '#000', fontWeight: 500 }}>Feedback:</span>
                            <span style={{ paddingLeft: '5px' }}>
                              {resource?.resource?.note?.[0]?.text?.split(':')?.[0] || '-'}
                            </span>
                          </div>
                          <div>
                            <span style={{ color: '#000', fontWeight: 500 }}>Comment:</span>
                            <span style={{ paddingLeft: '5px' }}>
                              {resource?.resource?.note?.[0]?.text?.split(':')?.[1] || '-'}
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td>No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            total={Math.ceil((resourceData?.total ?? 0) / itemsPerPage)}
            onChange={handlePageChange}
            value={Math.floor(offset / itemsPerPage) + 1}
          />
        </Group>
      </Paper>
    </>
  );
}

export default Feedback;
