/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Text, Card } from '@mantine/core';

interface AudioRecorderProps {
  audioTranscription: any;
}
const AudioTranscription = (props: AudioRecorderProps) => {
  return (
    <>
      <Text style={{ fontSize: '16px', fontWeight: 500, display: 'inline-block' }}>Audio Transcription</Text>
      <Card display="block" mt={10} radius="sm" p="xs" style={{ border: '1px solid #BDBDBD' }}>
        <Text className="prg-scrollable-section">
          {props?.audioTranscription?.length > 0 && props?.audioTranscription?.map((paragraph: any, index: any, array: any) => (
            <React.Fragment key={index}>
              {paragraph}
              {index !== array.length - 1 && paragraph.trim() && <br />}
            </React.Fragment>
          ))}
        </Text>
      </Card>
    </>
  );
};

export default AudioTranscription;
