/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Card, Text, Group, Badge } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { integratedOrganizations } from '../fhirApi';
import { useMedplum } from '@medplum/react';
import "./OrganizationCard.css"

export function OrganisationCard() {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const [organizationResponse, setOrganizationResponse] = useState<any | null>();
  useEffect(() => {
    integratedOrganizations(medplum)
    .then((response) =>{
      setOrganizationResponse(response);
    })
    .catch((error: any) => {
      console.error('Error fetching data:', error);
    });
  }, [])

  const organisationDetails = organizationResponse?.map((details: any) => (
    <Card.Section
      key={details.id}
      display="flex"
      className="section"
      onClick={() => navigate('/organizations', { state: details } )}
      style={{ cursor: 'pointer' }}
    >
      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
        <Text className="f-main" fw={500}>
          {details.organizationName}
        </Text>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
        <Text className="lightText" fw={500}>
          {details.state}
        </Text>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
        <Text className="lightText" fw={500}>
          {details.ehr}
        </Text>
      </Group>
      <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
        {details?.status === 'Active' ? (
          <Badge className="badge" variant="filled" style={{ background: '#DEF5EA' }}>
            {details.status}
          </Badge>
        ) : details?.status === 'Inactive' ? (
          <Badge className="badge" variant="filled" style={{ background: '#FAEBEB' }}>
            {details.status}
          </Badge>
        ) : (
          <Badge className="badge" variant="filled" style={{ background: '#FCF2DF' }}>
            {details.status}
          </Badge>
        )}
      </Group>
    </Card.Section>
  ));

  return (
    <Card withBorder radius="md" className="card">
      <Card.Section className="section" p="lg">
        <Text className="darkText" fw={700}>
          Organizations and EHRs Integrated
        </Text>
      </Card.Section>

      <Card.Section px="lg" display="flex" className="section">
        <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
          <Text className="darkText" fw={700}>
            Name
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Location
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            EHR
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Status
          </Text>
        </Group>
      </Card.Section>

      <Group px="xs" className="scrollbar" display="block" style={{ height: '360px', overflowX: 'hidden', padding: '16px' }}>
        {organisationDetails}
      </Group>

      <Card.Section className="footer">
        <Group style={{ alignItems: 'flex-end' }} gap={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#222',
              display: 'flex',
            }}
            to={'/all-organizations-and-ehrs-integrated'}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
}
