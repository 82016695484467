/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Grid } from '@mantine/core';
import { IntroductionPanel } from '../IntroductionPanel';
import RegisterForm from './RegisterForm';

// const useStyles = createStyles((theme) => ({
//     hiddenMobile: {
//       [theme.fn.smallerThan('md')]: {
//         display: 'none',
//       },
//     },
  
//     paddingMobile: {
//       [theme.fn.smallerThan('sm')]: {
//         padding: '25px !important',
//       },
//     },
// }));

const PractitionerRegister = () => {
  return (
    <>
      <Grid style={{ background: '#E9F3FF' }}>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            '@media (max-width: 768px)': {
              display: 'none'
            }
          }}
        >
          <IntroductionPanel />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5, lg: 5 }}
          style={{
            '@media (max-width: 576px)': {
              padding: '25px !important'
            }
          }}
        >
          <RegisterForm />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default PractitionerRegister;
