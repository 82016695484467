/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
import { Box, Card, Text, Tabs, Anchor, Table, Badge, Center, Pagination, Button, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { exportClinicalNote, fetchPatientDetails, getClinicalNotes } from '../../fhirApi';
import { useMedplum } from '@medplum/react';
import { formatDate, capitalize } from '@medplum/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AudioRecorder from './AudioRecorder';
import { IconFileDownload, IconEye, IconEdit, IconBrandGithubCopilot } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import AssessmentDetailsModel from './AssessmentDetailsModel';
import AddCondition from './AddCondition';
import Styles from './MedicalHistory.module.css';

export function MedicalHistory() {
  const patientID = useParams().id;
  const defaultQuery = `${
    '{ Patient(id: "' +
    patientID +
    '") { resourceType id name {text} telecom {system value} birthDate maritalStatus {text} patients: QuestionnaireResponseList(_reference: patient, _count:20, _offset:0, _sort:"-_lastUpdated") { resourceType id status authored questionnaire } } }'
  }`;

  const [query, setQuery] = useState(defaultQuery);
  const medplum = useMedplum();
  const [data, setData] = useState([]);
  const [noDataRow, setNoDataRow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const navigate = useNavigate();
  const detailsTab = [
    { value: 'clinicalNotes', name: 'Clinical Notes' },
    { value: 'assessment', name: 'Assessments' },
    { value: 'conditions', name: 'Conditions' },
    { value: 'appointments', name: 'Appointments' },
    { value: 'procedures', name: 'Procedures' },
    { value: 'allergies', name: 'Allergies' },
    { value: 'medications', name: 'Medications' },
  ];

  const [activeTab, setActiveTab] = useState('clinicalNotes');
  const [isLoading, setIsLoading] = useState(true);
  const [isAudioRecorderModalOpen, setIsAudioRecorderModalOpen] = useState(false);
  const [selectedAppointmentId] = useState<string | null>(null);
  const [selectedAppointmentData] = useState<any>();
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [isopenCondition, setIsopenCondition] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [isQuestionnaire, setIsQuestionnaire] = useState<boolean>(false);
  // Unused code leaving for future reference
  // const [selectedAppointmentId, setSelectedAppointmentId] = useState<string | null>(null);
  // const [selectedAppointmentData, setSelectedAppointmentData] = useState<any>();
  const appointmentFields = [
    { fieldName: 'appointmentName', displayName: 'Appointment Type' },
    { fieldName: 'appointmentType', displayName: 'Visit Type' },
    { fieldName: 'appointmentTime', displayName: 'Date & Time' },
    { fieldName: 'organization', displayName: 'Hospital' },
    { fieldName: 'practitioner', displayName: 'Practitioner' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const clinicalNotesFields = [
    { fieldName: 'type', displayName: 'Type' },
    { fieldName: 'category', displayName: 'Category' },
    { fieldName: 'date', displayName: 'Date & Time' },
    { fieldName: 'docStatus', displayName: 'Status' },
    { fieldName: 'practitioner', displayName: 'Practitioner' },
    { fieldName: 'actions', displayName: 'Action' },
  ];

  const procedureFields = [
    { fieldName: 'procedureName', displayName: 'Procedure Name' },
    { fieldName: 'visit', displayName: 'Visit Type' },
    { fieldName: 'startDate', displayName: 'Start Date' },
    { fieldName: 'endDate', displayName: 'End Date' },
    { fieldName: 'dignosis', displayName: 'Diagnosis' },
    { fieldName: 'status', displayName: 'Clinical Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const conditionFields = [
    { fieldName: 'conditionName', displayName: 'Condition Name' },
    { fieldName: 'recordedDate', displayName: 'Recorded Date' },
    { fieldName: 'startDate', displayName: 'Onset Start Date' },
    { fieldName: 'endDate', displayName: 'Onset End Date' },
    { fieldName: 'severity', displayName: 'Severity' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const allergiesFields = [
    { fieldName: 'allergyName', displayName: 'Allergy Name' },
    { fieldName: 'category', displayName: 'Type' },
    { fieldName: 'onsetDate', displayName: 'Onset Date' },
    { fieldName: 'reaction', displayName: 'Reaction' },
    { fieldName: 'status', displayName: 'Clinical Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const medicationFields = [
    { fieldName: 'medicationName', displayName: 'Medications Name' },
    { fieldName: 'category', displayName: 'Type' },
    { fieldName: 'startDate', displayName: 'Start Date' },
    { fieldName: 'reapeats', displayName: 'No. of Repeats' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const assessmentFields = [
    { fieldName: 'assessmentName', displayName: 'Assessment Name' },
    { fieldName: 'completionDate', displayName: 'Completion Date' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const successStatus = ['active', 'completed', 'fulfilled', 'final'];
  const failedStatus = ['inactive', 'incomplete', 'cancelled'];
  const warningStatus = ['booked'];
  const infoStatus = ['amended'];
  const blueColorStatus = ['arrived'];

  const statusButton = (status: any) => {
    const formattedStatus = status?.toLowerCase();
    return successStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#DEF5EA' }}>
        {status}
      </Badge>
    ) : failedStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#FAEBEB' }}>
        {status}
      </Badge>
    ) : warningStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#FCF2DF' }}>
        {status}
      </Badge>
    ) : infoStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#F2F2F2' }}>
        {status}
      </Badge>
    ) : blueColorStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#DBF0FD' }}>
        {status}
      </Badge>
    ) : (
      '-'
    );
  };

  // Unused code leaving for future reference
  // const handleOpenAudioRecorderModal = (data: any) => {
  //   setSelectedAppointmentId(data.id);
  //   setIsAudioRecorderModalOpen(true);
  //   setSelectedAppointmentData(data);
  // };

  useEffect(() => {
    if (activeTab === 'clinicalNotes') {
      clinicalNoteData();
    } else {
      fetchPatientDetails(medplum, query)
        .then(async (response) => {
          setIsLoading(false);
          if (response?.data.Patient.patients?.length) {
            setNoDataRow(false);
            const fetchedAppointments = response?.data.Patient.patients;
            let tempList: any = [];
            let email: any;
            if (response?.data?.Patient?.telecom?.length) {
              response?.data.Patient?.telecom?.map((e: any) => {
                if (e.system === 'email') {
                  email = e.value;
                }
              });
            }

            if (activeTab === 'appointments') {
              tempList = fetchedAppointments?.map((item: any) => ({
                id: item?.id,
                appointmentName: item?.appointmentType?.coding?.[0]?.display ?? '-',
                display: '-',
                appointmentTime: dateFormate(item?.start),
                status: item?.status,
                appointmentType: item?.serviceType !== null ? item?.serviceType[0]?.coding[0]?.display : '-',
                practitioner: item?.participant.length >= 1 ? item?.participant[1]?.actor?.display : '-',
                organization: item?.participant.length >= 2 ? item?.participant[2]?.actor?.display : '-',
                startDate: item?.start,
                name: response?.data.Patient.name[0].text,
                email: email,
                patientID: response?.data.Patient.id,
              }));
            } else if (activeTab === 'procedures') {
              tempList = fetchedAppointments?.map((item: any) => ({
                id: item?.id,
                procedureName: item?.code?.coding ? item?.code?.coding[0].display : '-',
                recordedDate: item?.recordedDate || '-',
                startDate: formatDate(item?.performedPeriod?.start) || formatDate(item?.performedDateTime) || '-',
                endDate: formatDate(item?.performedPeriod?.end) || '-',
                visit: item?.location?.display || '-',
                status: item?.status,
                dignosis: item?.reasonCode ? item?.reasonCode[0].text : '-',
              }));
            } else if (activeTab === 'conditions') {
              tempList = fetchedAppointments?.map((item: any) => ({
                id: item?.id,
                conditionName: item?.code?.text || '-',
                severity: item?.severity?.coding?.[0]?.display || '-',
                recordedDate: formatDate(item?.recordedDate) || '-',
                startDate: formatDate(item?.onsetPeriod?.start) || formatDate(item?.onsetDateTime) || '-',
                endDate: formatDate(item?.onsetPeriod?.end) || '-',
                status: item?.status,
                condition: item?.category?.[0]?.coding?.[0]?.code,
                stage: item?.stage?.[0]?.summary?.coding?.[0]?.code,
                severityCode: item?.severity?.coding?.[0]?.code,
                isPrimaryCondition: item?.identifier?.find(
                  (data: { value: string }) => data.value === 'primary-condition'
                )
                  ? true
                  : false,
                conditionLabel: item?.category?.[0]?.coding?.[0]?.display,
                stageLable: item?.stage?.[0]?.summary?.coding?.[0]?.display,
                recordedDateTime: item?.recordedDate,
              }));
            } else if (activeTab === 'allergies') {
              tempList = fetchedAppointments?.map((item: any) => ({
                id: item?.id,
                allergyName: item?.code?.text || '-',
                category: item?.category ? item?.category[0] : '-',
                severity: item?.severity || '-',
                reaction: item?.reaction ? item?.reaction[0].description : '-',
                onsetDate: formatDate(item?.onsetPeriod?.start) || formatDate(item?.onsetDateTime) || '-',
                visit: item?.location?.display || '-',
                status: item?.clinicalStatus?.coding[0]?.display,
              }));
              tempList = tempList?.filter((item: any) => item.allergyName !== 'Not on File');
            } else if (activeTab === 'medications') {
              tempList = fetchedAppointments?.map((item: any) => ({
                id: item?.id,
                medicationName: item?.medicationReference
                  ? item?.medicationReference?.display
                  : item?.medicationCodeableConcept
                    ? item?.medicationCodeableConcept?.coding[0]?.display
                    : '-',
                category: item?.category ? item?.category[0]?.coding[0]?.display : '-',
                reapeats: item?.dispenseRequest?.numberOfRepeatsAllowed || '0',
                startDate: formatDate(item?.dispenseRequest?.validityPeriod?.start) || '-',
                status: item?.status,
              }));

              tempList = tempList?.filter((item: any) => item.status !== undefined);
            } else if (activeTab === 'assessment') {
              tempList = await Promise.all(
                fetchedAppointments?.map(async (item: any) => {
                  const questionnaireNameQuery = `${'{ Questionnaire(id: "' + item.questionnaire.split('/')[1] + '") { resourceType id name } }'
                    }`;
                  try {
                    const res = await fetchPatientDetails(medplum, questionnaireNameQuery);
                    return {
                      id: item?.id,
                      assessmentName: res?.data?.Questionnaire?.name,
                      completionDate: formatDate(item.authored),
                      status: item.status ? capitalize(item.status) : '-',
                    };
                  } catch (error) {
                    console.error('Error fetching data:', error);
                    return null;
                  }
                })
              );
            }
            if (tempList.length) {
              setData(tempList);
            } else {
              setNoDataRow(true);
              setData([]);
            }
          } else {
            setNoDataRow(true);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [query, activeTab]);

  const displayData = data?.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const setDetailsLink = (id: string) => {
    let link = '#';
    if (activeTab === 'appointments') {
      link = `${'/Appointment/' + id + '/details'}`;
    } else if (activeTab === 'procedures') {
      link = `${'/Procedure/' + id + '/details'}`;
    } else if (activeTab === 'conditions') {
      link = `${'/Condition/' + id + '/details'}`;
    } else if (activeTab === 'allergies') {
      link = `${'/AllergyIntolerance/' + id + '/details'}`;
    } else if (activeTab === 'medications') {
      link = `${'/MedicationRequest/' + id + '/details'}`;
    } else if (activeTab === 'assessment') {
      link = `${'/Questionnaire/' + id}`;
    }
    return link;
  };

  const downloadPdf = (id: any, patientName: any) => {
    exportClinicalNote(medplum, id)
      .then((response: any) => {
        if (response && response.data) {
          // Determine file type and extension based on Content-Type header
          const contentType = response.headers['content-type'] || '';
          let fileType = 'text/html';
          let fileExtension = 'html';

          if (contentType.includes('pdf')) {
            fileType = 'application/pdf';
            fileExtension = 'pdf';
          } else if (contentType.includes('xml')) {
            fileType = 'text/xml';
            fileExtension = 'xml';
          } else if (contentType.includes('plain')) {
            fileType = 'text/plain';
            fileExtension = 'txt';
          }

          // Create Blob from response data
          const blob = new Blob([response.data], { type: fileType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}.${fileExtension}`;
          a.click();
          window.URL.revokeObjectURL(url);

          showNotification({ color: 'green', message: `Exported successfully as ${fileExtension.toUpperCase()}` });
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      });
  };
  const handleClose = () => {
    setIsAudioRecorderModalOpen(false);
  };

  const dateFormate = (inputDate: string): string => {
    const date = new Date(inputDate);

    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate.replace(',', ','); // Remove the comma after the date
  };

  const anchorFieldNames = ['appointmentName', 'procedureName', 'conditionName', 'allergyName', 'assessmentName'];

  const generateTabContent = (tab: any, data: any[], fields: any[]) => (
    <Tabs.Panel value={tab.value} pt="md" className={Styles.tabPanel}>
      <Card withBorder radius="md" className={Styles.card}>
        <Card.Section className={Styles.header} p="lg">
          <Text className="darkText" fw={700}>
            {tab.name}
          </Text>
        </Card.Section>
        <Card.Section className={Styles.section} p="lg">
          <Table highlightOnHover style={{ overflowX: 'hidden' }} className={Styles.table} verticalSpacing="sm">
            <thead style={{ textAlign: 'left' }}>
              <tr className={Styles.tableRow}>
                {fields.map((field) => (
                  <th className={Styles.tableRow} key={field.fieldName}>
                    {field.displayName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr className={Styles.tableRow}>
                  <td className={Styles.tableRow} colSpan={appointmentFields.length}>
                    <Center>{'Loading...'}</Center>
                  </td>
                </tr>
              ) : noDataRow ? (
                <tr className={Styles.tableRow}>
                  <td className={Styles.tableRow} colSpan={appointmentFields.length}>
                    <Center>{'No Data Found'}</Center>
                  </td>
                </tr>
              ) : (
                data.map((item: any) => (
                  <tr className={Styles.tableRow} key={item.id}>
                    {fields.map((field) => (
                      <td
                        className={Styles.tableRow}
                        key={field.fieldName}
                        style={{ width: field.fieldName === 'conditionType' ? '17%' : '' }}
                      >
                        {anchorFieldNames.includes(field.fieldName) ? (
                          <Anchor
                            component="button"
                            fz="sm"
                            // justify="left"
                            className={Styles.highlitedRow}
                            onClick={function () {
                              if (activeTab === 'assessment') {
                                navigate(`/QuestionnaireResponse/${item.id}`);
                              } else {
                                window.open(setDetailsLink(item.id), '_blank');
                              }
                            }}
                          >
                            {item[field.fieldName]}
                          </Anchor>
                        ) : field.fieldName === 'actions' && activeTab === 'conditions' ? (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip label="View" withArrow>
                              <Link
                                style={{
                                  textDecoration: 'none',
                                  color: '#3673b6',
                                  padding: '5px',
                                  fontSize: '11px',
                                  fontWeight: 700,
                                  borderRadius: '2px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                target="_blank"
                                to={setDetailsLink(item.id)}
                              >
                                <IconEye size={20} />
                              </Link>
                            </Tooltip>
                            <Tooltip label="Edit" withArrow>
                              <Button
                                style={{
                                  textDecoration: 'none',
                                  color: '#3673b6',
                                  backgroundColor: 'transparent',
                                  padding: '5px',
                                  fontSize: '11px',
                                  fontWeight: 700,
                                  borderRadius: '2px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '29px',
                                  marginLeft: '5px',
                                }}
                                onClick={() => {
                                  setIsopenCondition(true);
                                  setSelectedData(item);
                                }}
                              >
                                <IconEdit size={20} />
                              </Button>
                            </Tooltip>
                          </div>
                        ) : field.fieldName === 'actions' && activeTab === 'assessment' ? (
                          <Tooltip label="View Details" withArrow>
                            <Button
                              style={{
                                textDecoration: 'none',
                                color: '#3673b6',
                                backgroundColor: 'transparent',
                                padding: '5px',
                                fontSize: '11px',
                                fontWeight: 700,
                                borderRadius: '2px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '29px',
                                marginLeft: '5px',
                              }}
                              onClick={() => {
                                setAssessmentDetails(item);
                                setIsQuestionnaire(true);
                              }}
                            >
                              <IconEye size={20} />
                            </Button>
                          </Tooltip>
                        ) : field.fieldName === 'actions' && activeTab === 'clinicalNotes' ? (
                          <Tooltip label="Download" withArrow>
                            <IconFileDownload
                              className="file-donwload"
                              onClick={() => downloadPdf(item.id, item.patient)}
                            />
                          </Tooltip>
                        ) : field.fieldName === 'actions' && activeTab === 'appointments' ? (
                          <Tooltip label="AI Consultation" withArrow>
                            <Button
                              style={{
                                textDecoration: 'none',
                                color: '#3673b6',
                                backgroundColor: 'transparent',
                                padding: '5px',
                                fontSize: '11px',
                                fontWeight: 700,
                                borderRadius: '2px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '29px',
                                marginLeft: '5px',
                              }}
                              onClick={() =>
                                navigate(`/Patient/clinical-note/${item.id}`, { state: { appointmentData: item } })
                              }
                            >
                              <IconBrandGithubCopilot size={20} />
                            </Button>
                          </Tooltip>
                        ) : field.fieldName === 'actions' ? (
                          <Tooltip label="View" withArrow>
                            <Link
                              style={{
                                textDecoration: 'none',
                                color: '#3673b6',
                                padding: '5px',
                                fontSize: '11px',
                                fontWeight: 700,
                                borderRadius: '2px',
                                display: 'flex',
                                alignItems: 'left',
                                justifyContent: 'left',
                              }}
                              target="_blank"
                              to={setDetailsLink(item.id)}
                            >
                              <IconEye size={20} />
                            </Link>
                          </Tooltip>
                        ) : field.fieldName === 'status' ? (
                          <>{statusButton(item[field.fieldName])}</>
                        ) : (
                          <>{item[field.fieldName] || '-'}</>
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Section>
      </Card>
    </Tabs.Panel>
  );

  const handleTabChange = (tab: any) => {
    setData([]);
    setActiveTab(tab);
    setIsLoading(true);
    setCurrentPage(1);
    if (tab === 'appointments') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id name {text}  birthDate maritalStatus {text} patients: AppointmentList(_reference: patient, _sort:"-_lastUpdated") { resourceType id start status appointmentType { coding {display}} serviceType { coding {display}} participant {actor {reference display}} } } }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'procedures') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id name {text}  birthDate maritalStatus {text} patients: ProcedureList(_reference: patient) { resourceType id performedPeriod {start end}  status location { display } reasonCode { text } code {coding {display}} } } }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'conditions') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id patients: ConditionList(_reference: patient, _sort:"-_lastUpdated") { resourceType id onsetPeriod{start end} recordedDate code { text } encounter { display } category {coding {code display}} severity {coding {code display}} code {coding {code display}} identifier {system value} stage {summary {coding {code display}}} } } }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'allergies') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: AllergyIntoleranceList(_reference: patient) { resourceType id code{text} category onsetPeriod{start} reaction{description} clinicalStatus{coding{display}} } } }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'medications') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: MedicationRequestList(_reference: patient) { resourceType id medicationReference{display} medicationCodeableConcept{coding{display}} category{coding{display}} status dispenseRequest{ validityPeriod{start} numberOfRepeatsAllowed} }} }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'clinicalNotes') {
      clinicalNoteData();
    } else if (tab === 'assessment') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: QuestionnaireResponseList(_reference: patient, _count:20, _offset:0, _sort:"-_lastUpdated") { resourceType id status authored questionnaire}} }'
      }`;
      setQuery(newQuery);
    }
  };

  const clinicalNoteData = () => {
    setIsLoading(true);
    getClinicalNotes(medplum, patientID)
      .then((response: any) => {
        setIsLoading(false);
        if (response.data) {
          let tempList: any = [];
          tempList = response.data?.map((item: any) => ({
            id: item.id,
            type: item.type,
            patient: item.patient.name,
            date: item?.date ? dateFormate(item.date) : '-',
            docStatus: item?.status ? capitalize(item.status) : '-',
            practitioner: item.practitioner,
            category: item?.category,
          }));
          if (tempList.length) {
            setData(tempList);
            setNoDataRow(false);
          } else {
            setNoDataRow(true);
            setData([]);
          }
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      });
  };

  return (
    <Box py="sm" display="block">
      <Tabs
        variant="pills"
        defaultValue={activeTab}
        className={Styles.tabs}
        onChange={(newTab) => handleTabChange(newTab)}
      >
        <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
          {detailsTab.map((tab) => (
            <Tabs.Tab key={tab.value} value={tab.value}>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {generateTabContent(detailsTab[0], displayData, clinicalNotesFields)}
        {generateTabContent(detailsTab[1], displayData, assessmentFields)}
        {generateTabContent(detailsTab[2], displayData, conditionFields)}
        {generateTabContent(detailsTab[3], displayData, appointmentFields)}
        {generateTabContent(detailsTab[4], displayData, procedureFields)}
        {generateTabContent(detailsTab[5], displayData, allergiesFields)}
        {generateTabContent(detailsTab[6], displayData, medicationFields)}
      </Tabs>
      <Center mt="lg" style={{ justifyContent: 'flex-end' }}>
        <Pagination
          value={currentPage}
          total={Math.ceil(data.length / itemsPerPage)}
          onChange={handlePageChange}
          defaultValue={itemsPerPage}
          className={Styles.pagination}
        />
      </Center>
      {isAudioRecorderModalOpen && selectedAppointmentId && (
        <AudioRecorder
          isOpen={isAudioRecorderModalOpen}
          handleClose={handleClose}
          appointmentId={selectedAppointmentId}
          appointmentData={selectedAppointmentData}
        />
      )}
      {isQuestionnaire && (
        <AssessmentDetailsModel
          opened={isQuestionnaire}
          close={() => setIsQuestionnaire(false)}
          assessmentDetails={assessmentDetails}
        />
      )}
      {isopenCondition && (
        <AddCondition opened={isopenCondition} close={() => setIsopenCondition(false)} selectedData={selectedData} />
      )}
    </Box>
  );
}

export default MedicalHistory;
