import { Card, Text, Group } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import "./NotificationCard.css"

export interface notificationCardProps {
  notificationList: any;
}

const NotificationsCard: React.FC<notificationCardProps> = ({notificationList}) =>{
  let displayedNotificationsCount = 0;
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return format(date, 'MM/DD/YYYY hh:mm A');
  };
  const organisationDetails = notificationList?.map((details: any) => {
    try {
      const outcomeDesc = details.resource?.outcomeDesc;
  
      if (!outcomeDesc || outcomeDesc.toLowerCase() === "{}" || outcomeDesc.toLowerCase().includes('error')) {
        return null;
      }

      if (displayedNotificationsCount >= 5) {
        return null;
      }

      displayedNotificationsCount++;
  
      return (
        <Card.Section key={details.resource.id} display="flex" className="section">
          <Group style={{ justifyContent: 'flex-start', flex: 1 }}>
            <div className="icon">
              <img src="../../img/dashboard-assets/notifications.svg" height="15px" alt="Notification Icon" />
            </div>
          </Group>
          <Group style={{ display: 'block', flex: 6, padding: '0 6px', gap: '0.5rem' }}>
            <Text className="lightText" fw={400} style={{ lineHeight: '1.4', fontSize: '13px' }}>
              {outcomeDesc}
            </Text>
            <Text className="lightText" fw={500} style={{ lineHeight: '1.4', fontSize: '12px', width: '100%', textAlign: 'end', marginTop: '5px'}}>
              {formatDate(details.resource.recorded)}
            </Text>
          </Group>
        </Card.Section>
      );
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  });
  
  

  return (
    <Card withBorder radius="md" className="card">
      <Card.Section className="section" p="lg">
        <Text className="darkText" fw={700}>
          Notifications & Alerts
        </Text>
      </Card.Section>

      <Group px="xs" display="block" className="scrollbar" style={{ height: '407px', overflowX: 'hidden', padding: '16px' }}>
        {organisationDetails}
      </Group>

      <Card.Section className="footer">
        <Group style={{ alignItems: 'flex-end' }} gap={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#222',
              display: 'flex',
            }}
            to={'/all-notifications'}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
};
 
export default NotificationsCard;
