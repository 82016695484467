import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Center, Grid } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import axios from '../utils/axios';
import { getEHRTokenFromLocalStorage } from '../utils/util';
import { SSOButtons } from './CustomSSOButtons';
import Styles from './SSO.module.css'

const SSO = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const medplum = useMedplum();
  const [startLogin, setStartLogin] = useState(false);
  const [organizationsComplete, setOrganizationsComplete] = useState();
  const navigate = useNavigate();

  const getAccessToken = async () => {
    try {
      let formbody: any = {};
      const uri = window.location.href.split('?')[0];
      if (localStorage.getItem('EMR_CODE')?.toUpperCase() === 'EPIC' || localStorage.getItem('EMR_CODE')?.toUpperCase() === 'CERNER') {
        const organization: any = localStorage.getItem('Organization');
        const orgObject = JSON.parse(organization);
        const orgConfig = orgObject.resource.extension.find((item: any) => item.type === 'provider');
        formbody = {
          code,
          redirectUrls: uri,
          tokenEndpoint: orgConfig.tokenUrl,
          scope: orgConfig.scope,
          clientId: orgConfig.clientId,
          grant_type: 'authorization_code',
          client: localStorage.getItem('EMR_CODE'),
          apiUrl: orgConfig.apiUrl
        };
        if (formbody.tokenEndpoint) {
          const res = await axios.post(`/api/sso/get-ehr-token-practioner`, formbody);
          localStorage.setItem('ehr_token', JSON.stringify(res));
          localStorage.setItem('patient_id', res.data.patient);
          localStorage.setItem('api_url', orgConfig.apiUrl);
          if (localStorage.getItem('EMR_CODE')?.toUpperCase() === 'CERNER') {
            navigate('/cerner-ehr-patient-list');
            return;
          }
          createPatientAndNavigate();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrgs = async () => {
    try {
      const response = await medplum.get('/fhir/R4/Organization?_count=100');
      setOrganizationsComplete(response.entry);
    } catch (error) {
      console.error(error);
    }
  };

  async function createPatientAndNavigate(): Promise<void> {
    const organization: any = localStorage.getItem('Organization');
    const orgObject = JSON.parse(organization);
    const orgConfig = orgObject.resource.extension.find((item: any) => item.type === 'provider');
    let postData: any = {};
    postData.patient_id = localStorage.getItem('patient_id');

    let token = await medplum.getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ehr_token: getEHRTokenFromLocalStorage(),
        api_url: orgConfig.apiUrl
      },
    };
    axios
      .post('/ehr/epic/save-patient', postData, config)
      .then((response: any) => {
        localStorage.removeItem('patient_id');
        const patientId = response.data.patient.id;
        postData.ehr_type = localStorage.getItem('EMR_CODE');
        axios.post('/api/pragmaconnect/import-patient-details', postData, config).then((response: any) => {
          console.log('Started import');
        });
        const path = `/Patient/patient-dashboard/${patientId}`;
        const uri = window.location.href.split('/')[0];
        window.open(`${uri}${path}`, '_self');
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      fetchOrgs();
      try {
        if (code && !startLogin) {
          if (isMounted) {
            setStartLogin(true);
          }

          await getAccessToken();
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!startLogin) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [code, state, startLogin]);

  return (
    <Grid className={Styles.mainDarkBg}>
      <Grid.Col className={Styles.paddingMobile}>
        <Center p={{ xs: 'lg', md: 'lg' }} style={{ alignContent: 'center', justifyContent: 'center' }}>
          <Grid className="sso-card">
            <Grid.Col span={{base:12, md:12}}>
              <SSOButtons organizationList={organizationsComplete} />
            </Grid.Col>
          </Grid>
        </Center>
      </Grid.Col>
    </Grid>
  );
};

export default SSO;
