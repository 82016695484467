/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Button, Divider, Grid, Text, Box, Tooltip } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useMedplum } from '@medplum/react';
import { healthSummary, downloadHealthSummary } from '../../fhirApi';
import { showNotification } from '@mantine/notifications';

const AIHealthSummary = () => {
  const patientID = useParams().id || '';
  const medplum = useMedplum();
  const [lastGenerated, setLastGenerated] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientHealthSummary, setPatientHealthSummary] = useState<any>({});
  const [documentRefId, setDocumentRefId] = useState<string | null>(null);

  useEffect(() => {
    getPatientHealthSummary();
  }, []);

  const getPatientHealthSummary = async (refreshSummary: boolean = false) => {
    setIsLoading(true);
    try {
      const response = await healthSummary(medplum, patientID, refreshSummary);
      const attachmentData = JSON.parse(response?.content?.[0]?.attachment?.data || '{}');
      setPatientHealthSummary(attachmentData);
      setLastGenerated(response?.date || '');
      setDocumentRefId(response?.id || null);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    if (documentRefId) {
      try {
        const response = await downloadHealthSummary(medplum, documentRefId);
        if (response && response.data) {
          const contentDisposition = response?.headers['content-disposition'];
          const match = contentDisposition?.match(/filename="(.+)"/);
          const fileName = match?.[1] || 'AIHealthSummary.pdf';
          const fileType = 'application/pdf';
          const fileExtension = 'pdf';
          const blob = new Blob([response.data], { type: fileType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          showNotification({ color: 'green', message: `Exported successfully as ${fileExtension.toUpperCase()}` });
        } else {
          throw new Error('Response is null or does not contain data');
        }
      } catch (error) {
        console.error('Error downloading health summary:', error);
      }
    }
  };
  
  const renderContent = (content: any, level = 1) => {
    if (content == null) {
      return null;
    }
    if (typeof content === 'string') {
      // Remove leading hyphen and space if present
      const formattedContent = content.replace(/^- /, '');
      return (
        <div style={{ margin: '10px 0', marginLeft: `${level * 3}px` }}>
          <span
            style={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#475467',
              lineHeight: '26px',
            }}
          >
            {'• '}
            {formattedContent}
          </span>
        </div>
      );
    }

    if (Array.isArray(content)) {
      return (
        <div style={{ margin: '10px 0', marginLeft: `${level * 3}px` }}>
          {content.map((item, idx) => (
            <div key={idx} style={{ marginLeft: `${level * 3}px` }}>
              {typeof item === 'object' ? (
                renderContent(item, level + 1)
              ) : (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 450,
                    color: '#475467',
                    lineHeight: '26px',
                  }}
                >
                  {'• '}
                  {item.toString().replace(/^- /, '')}
                </span>
              )}
            </div>
          ))}
        </div>
      );
    }
  
    return Object.keys(content).map((key, index) => {
      const value = content[key];
      const isObject = typeof value === 'object' && value !== null;
      const isArray = Array.isArray(value);
      const marginLeft = level * 3;
  
      // Handle the "-" key to directly display its value
      if (key === '-') {
        return (
          <div key={index} style={{ marginBottom: '10px', marginLeft: `${marginLeft}px` }}>
            <span
              style={{
                fontSize: '14px',
                marginLeft: `${(level + 1) * 3}px`,
                fontWeight: 500,
                color: '#475467',
                lineHeight: '26px',
              }}
            >
              {value}
            </span>
          </div>
        );
      }
  
      return (
        <div key={key} style={{ marginLeft: `${marginLeft}px` }}>
          <span style={{ fontWeight: 700, fontSize: '14px', color: '#333' }}>
            {/* Numbered list for top-level items */}
            {level === 1 ? `${index + 1}. ` : '• '}
            {key.replace(/([A-Z])/g, ' $1').trim().replace(/\s+/g, ' ')}
            {!isArray && ':'}
          </span>
          {isObject ? (
            <div style={{ margin: '5px 0' }}>
              {isArray ? (
                value.map((item: any, idx: number) => (
                  <div key={idx} style={{ marginLeft: `${marginLeft + 3}px` }}>
                    {typeof item === 'object' ? (
                      renderContent(item, level + 1)
                    ) : (
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 450,
                          color: '#475467',
                          lineHeight: '26px',
                        }}
                      >
                        {'• '}
                        {item.toString().replace(/^- /, '')}
                      </span>
                    )}
                  </div>
                ))
              ) : (
                renderContent(value, level + 1)
              )}
            </div>
          ) : (
            <span
              style={{
                fontSize: '14px',
                margin: '10px 5px',
                fontWeight: 450,
                color: '#475467',
                lineHeight: '26px',
              }}
            >
              {value}
            </span>
          )}
        </div>
      );
    });
  };

  const renderSection = (title: string, content: any, index: number) => (
    <>
      <h5 style={{ fontWeight: 700, fontSize: '16px', color: '#333', marginBottom: '10px', marginTop: '15px' }}>
        {`${index + 1}. ${title.replace(/([A-Z])/g, ' $1').trim()}`}
      </h5>
      {renderContent(content, 2)}
    </>
  );

  return (
    <Box px="lg" py="lg">
      {lastGenerated && (
        <Grid mb={10}>
              <Grid.Col span={{base: 12, lg:1.5}}>
              <Text c="#475467" fz={13} pb={5}>
                  Last Generated
              </Text>
              <Text fw={600} c="#344054">
                  {lastGenerated ? new Date(lastGenerated).toLocaleDateString() : ''}
              </Text>
              </Grid.Col>
          <Grid.Col span={{base: 12, lg:3}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip label="Refresh Health Summary"
                styles={{
                  tooltip: {
                    border: '1px solid grey',
                  },
                }}
              >
                <Button
                  style={{
                    textDecoration: 'none',
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    fontSize: '11px',
                    fontWeight: 700,
                    borderRadius: '8px',
                    marginTop: '5px',
                    marginRight: '10px',
                    width: '36px',
                    height: '36px',
                    border: '1px solid #D0D5DD',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#f6f6f6';
                    e.currentTarget.style.borderColor = 'grey';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = '#fff';
                    e.currentTarget.style.borderColor = '#D0D5DD';
                  }}
                  size="sm"
                  onClick={() => getPatientHealthSummary(true)}
                >
                  <img
                    src="../../img/icons/refresh-Page.svg"
                    alt="AI Assistant"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </Button>
              </Tooltip>
              <Tooltip label="Download Health Report"
                styles={{
                  tooltip: {
                    border: '1px solid grey',
                  },
                }}
              >
                <Button
                  style={{
                    textDecoration: 'none',
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    fontSize: '11px',
                    fontWeight: 700,
                    borderRadius: '8px',
                    marginTop: '5px',
                    marginRight: '10px',
                    width: '36px',
                    height: '36px',
                    border: '1px solid #D0D5DD',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#f6f6f6';
                    e.currentTarget.style.borderColor = 'grey';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = '#fff';
                    e.currentTarget.style.borderColor = '#D0D5DD';
                  }}
                  size="sm"
                  onClick={handleDownload}
                >
                  <img
                    src="../../img/icons/Download-AI-Health-Summary.svg"
                    alt="Download AI Health Summary"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </Button>
              </Tooltip>
            </div>
          </Grid.Col>
        </Grid>
      )}
      <Divider color="#EAECF0" />
      <Box className='custom-scrollbar'>
        {isLoading ? (
          <>
            <span className="loading" style={{ display: 'flex', alignItems: 'end'}}>
              <Text fw={600}>Loading</Text>
              <span className="loading__dot"></span>
              <span className="loading__dot"></span>
              <span className="loading__dot"></span>
            </span>
          </>
        ) : patientHealthSummary && typeof patientHealthSummary === 'object' && Object.keys(patientHealthSummary).length > 0 ? (
          <div
            className="ai_health_summary">
            {Object.keys(patientHealthSummary).map((key, index) => (
              <div key={key}>
                {renderSection(key.replace(/([A-Z])/g, ' $1').trim(), patientHealthSummary[key], index)}
              </div>
            ))}
          </div>
        ) : (
          <p style={{ fontWeight: '500', fontSize: '16px'}}>No AI Health Summary Found.</p>
        )}
      </Box>
    </Box>
  );
};

export default AIHealthSummary;
