import React, { useEffect, useState } from 'react';
import { Paper, Button, Group, Tabs, Box, Text, Badge, Image } from '@mantine/core';
import { formatSearchQuery, Operator, SearchRequest } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { Appointment, Bundle } from '@medplum/fhirtypes';
import TitleComponent from '../TitleComponent';
import TelehealthFilters from './TelehealthFilters';
import TelehealthTable from './TelehealthTable';
import { TelehealthDrawer } from './TelehealthDrawer';
import { useNavigate } from 'react-router-dom';
import './Telehealth.css';
import TelehealthAlert from './TelehealthAlert';
import CustomPagination from '../../components/CustomPagination';


const Telehealth: React.FC = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Bundle<Appointment> | undefined>(undefined);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 6;
  const [activeTab, setActiveTab] = useState('all');
  const [advancedFilters, setAdvancedFilters] = useState<any>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const filterTabs = [
    { label: 'All', value: 'all' },
    { label: 'Pre-Booked', value: 'pending' },
    { label: 'Completed', value: 'fulfilled' },
    { label: 'Cancelled', value: 'cancelled' },
  ];

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    let filters = [
      { code: 'service-type', operator: Operator.EQUALS, value: 'Telehealth' },
      { code: '_sort', operator: Operator.EQUALS, value: '-date' },
    ];

    filters = advancedFilters.length ? [...filters, ...advancedFilters] : filters;

    const query: SearchRequest = {
      resourceType: 'Appointment',
      filters: filters,
      offset: offset,
      count: itemsPerPage,
      total: 'accurate',
    };

    if (activeTab !== 'all') {
      query.filters?.push({ code: 'status', operator: Operator.EQUALS, value: activeTab });
    }

    try {
      const response: any = await medplum.readResource('Appointment', formatSearchQuery(query));
      setData(response.entry);
      setTotalItems(response.total ?? 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAppointmentStatuses = async (): Promise<void> => {
    try {
      const body = {
        url: 'http://hl7.org/fhir/ValueSet/appointmentstatus|4.0.1',
        filter: '',
      };

      const response: any = await medplum.get(`/fhir/R4/ValueSet/$expand?url=${body.url}&filter=${body.filter}`);
      if (response?.expansion?.contains) {
        const statuses = response.expansion.contains;
        setStatuses(statuses);
      }
    } catch (error) {
      console.error('Error fetching statuses:', error);
    }
  };

  useEffect(() => {
    fetchData().catch((error) => console.error('Error fetching data:', error));
    getAppointmentStatuses().catch((error) => console.error('Error fetching statuses:', error));
  }, [offset, medplum, activeTab, advancedFilters]);

  const handlePageChange = (page: number): void => {
    setOffset((page - 1) * itemsPerPage);
  };

  const handleRefresh = (): void => {
    setOffset(0);
    setActiveTab('all');
    fetchData().catch((error) => console.error('Error fetching data:', error));
  };

  const handleTabChange = (newTab: string): void => {
    setActiveTab(newTab);
  };

  const handleFilters = (filters: any): void => {
    const { date, location, practitioner, patient } = filters;
    const practitionerReference = practitioner ? `Practitioner/${practitioner}` : '';
    const locationReference = location ? `Location/${location}` : '';
    const patientReference = patient ? `Patient/${patient}` : '';
    const newDate = date ? new Date(date) : null;
    const nextDate = newDate ? new Date(newDate.setDate(newDate.getDate() + 1)).toISOString() : null;

    const updatedFilters = [
      { code: 'date', operator: Operator.GREATER_THAN_OR_EQUALS, value: date },
      { code: 'date', operator: Operator.LESS_THAN, value: nextDate },
      { code: 'location', operator: Operator.EQUALS, value: locationReference },
      { code: 'practitioner', operator: Operator.EQUALS, value: practitionerReference },
      { code: 'patient', operator: Operator.EQUALS, value: patientReference },
    ]?.filter((filter) => filter.value);

    setAdvancedFilters(updatedFilters);
  };

  const handleStartCall = (appointmentId: string): void => {
    if (!appointmentId) {
      console.error('Appointment ID not found');
      return;
    }
    handleRefresh();
    setIsModalOpen(false);
    navigate(`/Telehealth/${appointmentId}/meeting`);
  };

  const handleAddNew = (): void => {
    setIsModalOpen(true);
  };

  const handleFiltersClick = (): void => {
    setIsFilterOpen(true);
  };

  return (
    <>
      <TitleComponent />
      <TelehealthAlert handleStartCall={handleStartCall} />
      <Paper radius={'12px'} className="telehealthContainerBorder">
        <Box className="telehealth-header" m="md" mb={0}>
          <Box className={`telehealth-title`}>
            <Text>Telehealth</Text>
            <Badge className="badge-style">{totalItems} records</Badge>
          </Box>

          <Group>
            <Button className={`button-default`} onClick={handleFiltersClick}>
              Filters
            </Button>

            <Button className={`addnew-button-default button-default`} onClick={handleAddNew}>
              <Image src="/img/icons/plus.svg" alt="Add Icon" width={20} mr={4} />
              Add New
            </Button>
          </Group>
        </Box>
        <div className="telehealth-table-container"></div>
        {isFilterOpen && (
          <TelehealthFilters
            onApplyFilters={handleFilters}
            onCloseFilters={(isFilterOpen) => {
              setIsFilterOpen(isFilterOpen);
            }}
          />
        )}
        <Box m={0} p={0} className="telehealth-list">
          <Tabs mt="md" variant="pills" value={activeTab} className="tabs" onChange={(newTab: string | null) => handleTabChange(newTab || '')}>
            <Tabs.List w="min-content" mt={'0px'} ml={'10px'} className="tabs-list">
              {filterTabs.map((tab) => (
                <Tabs.Tab key={tab.value} value={tab.value} className="tabs-inline-style">
                  {tab.label}
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {filterTabs.map((tab) => (
              <Tabs.Panel key={tab.value} value={tab.value} pt="md" className="tabPanel">
                <TelehealthTable
                  data={data}
                  isLoading={isLoading}
                  statuses={statuses}
                  handleStartCall={(value) => handleStartCall(value)}
                />
              </Tabs.Panel>
            ))}
          </Tabs>

          <CustomPagination
            total={Math.ceil(totalItems / itemsPerPage)}
            onChange={handlePageChange}
            value={Math.floor(offset / itemsPerPage) + 1}
          />
        </Box>

        <TelehealthDrawer
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onStartCall={(value) => handleStartCall(value)}
        />
      </Paper>
    </>
  );
};

export default Telehealth;
