import { Box, Card, Grid, Title } from '@mantine/core';
import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import styled from '@emotion/styled';
import { AppointmentsCard } from './AppointmentsCard';
import { MedicationsCard } from './MedicationsCard';
import { LabReports } from './LabReports';
import { MedicationReminders } from './MedicationReminders';
import { Procedures } from './Procedures';

export function ProvidersDashboard(): JSX.Element {
  return (
    <>
      <Box px="lg" py="sm">
        <Card
          display="flex"
          shadow="sm"
          radius="sm"
          px="md"
          py="10px !important"
          style={{ justifyContent: 'space-between', alignItems: 'baseline', flexDirection: 'row' }}
        >
          <Card.Section px="md">
            <Title variant="sm" size="16px" fw="bold">
              Providers
            </Title>
          </Card.Section>
          <Card.Section px="md">{<Breadcrumb />}</Card.Section>
        </Card>
      </Box>

      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={{ base: 12, md: 12, lg: 4}}>
            <AppointmentsCard />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 4}}>
            <MedicationsCard />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 4}}>
            <MedicationReminders />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 4}}>
            <LabReports />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 4}}>
            <Procedures />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}

export const StyledTypography = styled(Title)({
  fontSize: '14px',
  fw: '500',
});

export const IconAndText: React.FC<{
  ImageSrc: any;
  text?: React.ReactNode;
}> = ({ ImageSrc, text }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <img src={ImageSrc} />
    <StyledTypography
      style={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
      ml="xs"
      fw="600"
      variant="lg"
    >
      {text}
    </StyledTypography>
  </div>
);

export const StyledTypographyDetail = styled(Title)({
  fontSize: '10px',
  fw: '400',
  color: '#888B92',
});

export const IconAndDetails: React.FC<{
  ImageSrc?: any;
  text?: React.ReactNode;
}> = ({ ImageSrc, text }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {ImageSrc ? <img src={ImageSrc} width="12px" /> : ''}
    <StyledTypographyDetail
      style={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
      ml={ImageSrc ? 'xs' : '0px'}
      mr="md"
    >
      {text}
    </StyledTypographyDetail>
  </div>
);
