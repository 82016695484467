import { Card, Text, Group,Skeleton, Box, Grid, Center, Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react';
import Each from '../Each';
import TitleComponent from '../TitleComponent';
import axios from '../../utils/axios';
import styles from './AllPatientsRegisteredByOrganizations.module.css';


export function AllPatientsRegistiredByOrganizations(props: any): JSX.Element {
  const medplum = useMedplum();
  const [patientResponse, setPatientResponse] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataFound, setIsDataFound] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const perPage = 20;

  const fetchData = async () => {
    const token = await medplum.getAccessToken();
    const offset = (currentPage - 1) * perPage;
    try {
      setIsLoading(true);
      setPatientResponse([]);
      setIsDataFound(false);
      const response = await axios.get(
        `api/analytics/patient-counts-by-organization?_count=${perPage}&_offset=${offset}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data?.length !== 0) {
        setTotalPageCount(response?.data.data.length);
        setPatientResponse(response.data.data || []);
      } else {
        setIsDataFound(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <>
      <TitleComponent title="Patients Registered by Organizations" />
      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={12}>
            <Card withBorder radius="md" className={styles.card}>
              <Card.Section px="lg" display="flex" className={styles.section}>
                <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                  <Text className="darkText" fw={700}>Organization Name</Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                  <Text className="darkText" fw={700}>EHR</Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
                  <Text className="darkText" fw={700}>Count</Text>
                </Group>
              </Card.Section>

              <Group
                px="xs"
                display="block"
                className={styles.scrollbar}
                style={{ height: '450px', overflowX: 'hidden', padding: '16px' }}
              >
                <Each
                  of={patientResponse}
                  render={(item: any) => (
                    <Card.Section display="flex" className={styles.section}>
                      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                        <Text className="lightText" fw={500}>{item?.name || '-'}</Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                        <Text className="lightText" fw={500}>{item?.ehrname || '-'}</Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
                        <Text className="lightText" fw={500}>{item?.patientCount || '-'}</Text>
                      </Group>
                    </Card.Section>
                  )}
                />
                {isDataFound && (
                  <Group style={{ justifyContent: 'center', flex: 1, position: 'relative', top: '50%' }}>
                    <Text className="darkText" fw={700}>No Data Found</Text>
                  </Group>
                )}
                {isLoading && (
                  <div style={{ marginTop: '10px' }}>
                    <Skeleton height={8} radius="xl" />
                    <Skeleton height={8} mt={6} radius="xl" />
                    <Skeleton height={8} mt={6} width="70%" radius="xl" />
                  </div>
                )}
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
          {totalPageCount && (
            <Pagination
              className="pagination"
              value={currentPage}
              total={Math.ceil(totalPageCount / perPage)}
              onChange={setCurrentPage}
            />
          )}
        </Center>
      </Box>
    </>
  );
}
