import { Button, Center, Modal, Stack, Text } from '@mantine/core';

interface ConfirmationModalProps {
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: (value: boolean) => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({ isConfirmationModalOpen, setIsConfirmationModalOpen, onConfirm }: ConfirmationModalProps): JSX.Element => {
  return (
    <Center>
      <Modal
        opened={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        size={'sm'}
        padding="24px"
        radius={12}
        centered
        title={
          <div
            className='icon-button'
          >
            <img src="../../img/icons/checked.svg" alt="Checked" />
          </div>
        }
        styles={{
          header: {
            border: 'none !important',
          },
        }}
      >
        <Stack align="start" gap={0}>
          <Text size="sm" c="#475467" ta="start" fz={14} fw={400}>
            You will end this for everyone because you are the initiator of the call.
          </Text>

          <div
           className='confirmation-modal-container'
          >
            <Button variant="default" fullWidth radius={8} onClick={() => setIsConfirmationModalOpen(false)} h={'45px'}>
              Cancel
            </Button>
            <Button
              color="violet"
              radius={8}
              bg={'#9552E8'}
              c={'#FFFFFF'}
              h={'45px'}
              fullWidth
              onClick={() => {
                onConfirm();
              }}
            >
              Confirm
            </Button>
          </div>
        </Stack>
      </Modal>
    </Center>
  );
};

export default ConfirmationModal;
