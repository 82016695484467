import { Box, UnstyledButton } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import './Pagination.css';

interface CustomPaginationProps {
  total: number;
  onChange: (page: number) => void;
  value?: number;
}

const CustomPagination = ({ total, onChange, value = 1 }: CustomPaginationProps): JSX.Element => {
  const renderPageNumbers = () => {
    const pages = [];
    const boundary = 3;
    const ellipsis = '...';

    for (let i = 1; i <= total; i++) {
      if (
        i <= boundary ||
        i > total - boundary ||
        (i >= value - 1 && i <= value + 1)
      ) {
        pages.push(
          <UnstyledButton
            key={i}
            onClick={() => onChange(i)}
            className={`pagination-number ${i === value ? 'active' : ''}`}
          >
            {i}
          </UnstyledButton>
        );
      } else if (
        (i === boundary + 1 && value > boundary + 2) ||
        (i === total - boundary && value < total - boundary - 1)
      ) {
        pages.push(
          <span key={i} className="pagination-ellipsis">
            {ellipsis}
          </span>
        );
      }
    }

    return pages;
  };

  return (
    <Box className="pagination-container">
      <UnstyledButton
        onClick={() => onChange(value - 1)}
        disabled={value <= 1}
        className={`pagination-button previous ${value <= 1 ? 'disabled' : ''}`}
      >
        <IconChevronLeft size={18} />
        Previous
      </UnstyledButton>

      <Box className="pagination-numbers">
        {renderPageNumbers()}
      </Box>

      <UnstyledButton
        onClick={() => onChange(value + 1)}
        disabled={value >= total}
        className={`pagination-button next ${value >= total ? 'disabled' : ''}`}
      >
        Next
        <IconChevronRight size={18} />
      </UnstyledButton>
    </Box>
  );
};

export default CustomPagination;
