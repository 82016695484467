import { Box, Button, Flex, Group, Modal, Text, Textarea } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { IconStar, IconStarFilled } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import './FeedBackRatingModal.css';
import { submitNoteFeedback } from '../fhirApi';
import { showNotification } from '@mantine/notifications';

interface FeedbackRatingProps {
  opened: boolean;
  onClose: () => void;
  documentId: string;
  value ?: number;
}

const FeedBackRatingModal: React.FC<FeedbackRatingProps> = ({
  opened,
  onClose,
  documentId,
  value,
}: FeedbackRatingProps) => {
  const medplum = useMedplum();
  const [selectedFeedback, setSelectedFeedback] = useState<string[]>([]);
  const [comment, setComment] = useState<string>('');
  const [rating, setRating] = useState<number>(value || 0);

  const feedbackOptions = [
    'Too Many Details',
    'Not Enough Details',
    'Recording Trouble',
    'Inaccurate',
    'Other',
  ];

  const handleFeedbackToggle = (feedback: string): void => {
    setSelectedFeedback((prev) =>
      prev.includes(feedback) ? prev.filter((item: string) => item !== feedback) : [...prev, feedback]
    );
  };
  const handlePopupSubmit = async (feedback: string[], comment: string, rating: string): Promise<void> => {
    const payload = {
      noteId: documentId,
      rating: rating,
      comment: feedback + (comment ? `: ${comment}` : ''),
    };

    try {
      await submitNoteFeedback(medplum, payload)
        .then(() => {
          showNotification({ color: 'green', message: 'Feedback submitted successfully' });
        })
        .catch((error) => {
          console.error('Error submitting feedback:', error);
          showNotification({ color: 'red', message: 'Error submitting feedback' });
        });
    } catch (error) {
      console.error('Error submitting feedback:', error);
      showNotification({ color: 'red', message: 'Error submitting feedback' });
    }
  };
  const handleSubmit = async(): Promise<void> => {
    if (!rating) {
      console.error('Please select a rating before submitting');
      return;
    }
   await handlePopupSubmit(selectedFeedback, comment, rating.toString());
    onClose();
  };
  useEffect(() => {
    medplum
      .search('CommunicationRequest' as const, {
        _sort: '-_lastUpdated',
        _total: 'accurate',
        identifier: `DocumentReference/${documentId}`,
      })
      .then((response) => {
        response.entry?.forEach((entry) => {
          setRating(Number(entry?.resource?.category?.[0].coding?.[0].display) || 0);
          const note = entry?.resource?.note?.[0]?.text?.split(':');
          setSelectedFeedback(note?.[0]?.split(',') || []);
          setComment(note?.[1] || '');
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [opened]);

  return (
    <Modal
      className="feedBackPopup"
      opened={opened}
      onClose={onClose}
      title={
        <Box>
          <Text>Rating and Feedback</Text>
          <Text span className="sub-txt">
            We value your input! Please take a moment to rate the quality of our clinical notes.
          </Text>
        </Box>
      }
      size={'md'}
      centered
    >
      <Group gap="lg" pl={6}>
        <Flex justify={'center'} align={'center'} gap={'xs'} w={'100%'} h={'100%'} mt={'3rem'}>
          {Array.from({ length: 5 }).map((_, index) => {
            const starValue = index + 1;
            return starValue <= rating ? (
              <IconStarFilled key={index} onClick={() => setRating(starValue)} className="star-icon-filled" size={29} />
            ) : (
              <IconStar key={index} onClick={() => setRating(starValue)} className="star-icon-unfilled" size={29} />
            );
          })}
        </Flex>
        <Flex gap={'xs'} px={6} className="feedbackBtnGroup" mt="1rem">
          {feedbackOptions.map((option) => (
            <Button
              key={option}
              className={selectedFeedback.includes(option) ? 'filled' : 'outline'}
              onClick={() => handleFeedbackToggle(option)}
            >
              {option}
            </Button>
          ))}
        </Flex>
        <Group className="group">
          <Text
          className="commentText"
          mt={'md'}
          >
            Additional Feedback (Optional){' '}
          </Text>
          <Textarea
            placeholder="(Optional) Feel free to add specific details"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            mt={10}
            minRows={5}
          />
        </Group>
        <Flex justify={'space-between'} mt={10} className="btn-group">
          <Button w={'48%'} className="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button w={'48%'} className="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Flex>
      </Group>
    </Modal>
  );
};

export default FeedBackRatingModal;
