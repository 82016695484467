import { Avatar, Paper } from '@mantine/core'
import React from 'react'

interface FileUploadProps {
    imageUrl: any;
    handleFileChange: (event: any) => void;
}

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
  return (
    <>
        <Paper>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              {props.imageUrl ? (
                <div style={{ width: '30%'}}>
                  <img src={props.imageUrl} alt='logo' style={{ width: '100px', height: '100px' }} />
                </div>
              ) : (
                <div>
                  <Avatar
                    src="../../img/dashboard-assets/male-profile-icon.svg"
                    size={120}
                    style={{ background: '#EFF7FF !important', minWidth: '5.5rem', width: '5.5rem', height: '5.5rem' }}
                  />
                </div>
              )}
              <div style={{ width: '50%'}}>
                <label className="file-input-label">
                  <input
                    type="file"
                    onChange={(event) => props.handleFileChange(event)}
                    accept=".jpg, .jpeg, .png, .svg"
                    className="file-input"
                  />
                  Upload logo
                </label>
              </div>
            </div>
        </Paper>
    </>
  )
}

export default FileUpload
