import { Button, Popover } from '@mantine/core';
import TelehealthEndCallModal from '../../../TelehealthComponents/TelehealthEndCallModal';
import { useState } from 'react';
import ConfirmationModal from '../../../TelehealthComponents/ConfirmationModal';

interface LeaveButtonProps {
  onLeaveClick: () => void;
  onEndClick: () => void;
  isHost: boolean;
  isEndingSession: boolean;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onLeaveClick, onEndClick, isEndingSession } = props;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const onConfirm = (): void => {
    onEndClick();
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <Popover
        width={200}
        position="bottom"
        shadow="md"
        styles={{
          dropdown: {
            top: '-103px !important',
          },
        }}
      >
        <Popover.Target>
          <Button
            variant="filled"
            radius="md"
            title="Leave session"
            w="auto"
            ml="md"
            fs="14"
            fw={600}
            h={45}
            bg={'#D3302F'}
            style={{
              '&:hover': {
                backgroundColor: '#E34234',
              },
            }}
            disabled={isEndingSession}
          >
           {isEndingSession ? 'Generating Notes...' : 'End Call'} 
          </Button>
        </Popover.Target>
        <Popover.Dropdown display={isConfirmationModalOpen || isEndingSession ? 'none' : 'block'}>
          <TelehealthEndCallModal onLeaveClick={onLeaveClick} setisConfirmationModalOpen={setIsConfirmationModalOpen} />
        </Popover.Dropdown>
      </Popover>
      <ConfirmationModal
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        onConfirm={onConfirm}
        isConfirmationModalOpen={isConfirmationModalOpen}
      />
    </>
  );
};

export { LeaveButton };
