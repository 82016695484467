import { Tabs } from '@mantine/core';

interface CustomTabsProps {
  value: string;
  onClick: () => void;
  title: string;
  disabled: boolean;
}
const CustomTabs = ({ value, onClick, title, disabled }: CustomTabsProps): JSX.Element => {
  return (
    <Tabs.Tab value={value} onClick={onClick} title={title} disabled={disabled}>
      {value}
    </Tabs.Tab>
  );
};

export default CustomTabs;
