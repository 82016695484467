/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Modal, Select, Grid, Text, Button, Divider, Checkbox, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getPrimaryConditions, createPatientCondition, updatePatientCondition } from '../../fhirApi';
import { useForm } from '@mantine/form';
import { useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { ConditionPayload } from '../../utils/constant';

interface AddConditionProps {
  opened: boolean;
  close: () => void;
  selectedData?: any;
}

interface DataValue {
  value: string;
  label: string;
}

const AddCondition = (props: AddConditionProps) => {
  const StageValue = [
    { value: '13104003', label: 'Clinical stage I' },
    { value: '60333009', label: 'Clinical stage II' },
    { value: '50283003', label: 'Clinical stage III' },
    { value: '2640006', label: 'Clinical stage IV' },
  ];
  const severityData = [
    { value: '255604002', label: 'Mild' },
    { value: '24484000', label: 'Severe' },
    { value: '6736007', label: 'Moderate' },
  ];

  const form = useForm();
  const medplum = useMedplum();
  const patientID = useParams().id || '';
  const [severity, setSeverity] = React.useState<DataValue[]>([{ value: '255604002', label: 'Mild' }]);
  const [primaryConditions, setPrimaryConditions] = useState<DataValue[]>([]);
  const [isPrimaryCondition, setIsPrimaryCondition] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState<{ id: string, name: any }>();
  const [isCheckNoCondition, setIsCheckNoCondition] = useState(false);

  // Debounced function to call API after user input
  const fetchConditions = async (searchText: string) => {
    if (searchText.length >= 3) {
      const response = await getPrimaryConditions(searchText);
      if (response) {
        setPrimaryConditions(
          response.primaryConditionsDropdown.map((item: { code: string; display: string }) => ({
            value: item.code,
            label: item.display,
          }))
        );
        if (response.primaryConditionsDropdown.length === 0) {
          setIsCheckNoCondition(true);
        }
      }
    } else {
      setPrimaryConditions([]);
      setIsCheckNoCondition(false);
    }
  };

  useEffect(() => {
    if (props.selectedData) {
      const graphqlQuery = `${'{ Patient(id: "' +
        patientID +
        '") { resourceType id name { given family } } }'
        }`;
      medplum.graphql(graphqlQuery).then((response) => {
        setPatientData(response.data.Patient);
      }).catch(() => {
        console.log('Error while fetching patient data');
      });
      const response = fetchConditions(props.selectedData.conditionLabel);
      response?.finally(() => {
        form.setFieldValue('condition', props.selectedData.condition);
        form.setFieldValue('conditionLabel', props.selectedData.conditionLabel);
        form.setFieldValue('stage', props.selectedData.stage);
        form.setFieldValue('stageLable', props.selectedData.stageLable);
        setSeverity([{ value: props.selectedData.severityCode, label: props.selectedData.severity }]);
        setIsPrimaryCondition(props.selectedData.isPrimaryCondition);
      });
    }
  }, [props.selectedData]);

  useEffect(() => {
    if (patientData) {
      form.setValues({ patientId: patientData?.id, patientName: patientData?.name[0].given + ' ' + patientData?.name[0].family, patientPrimaryCondition: isPrimaryCondition, recordedDate: props.selectedData?.recordedDateTime });
    }
  }, [patientData]);

  // Function to handle form submit
  const handleSubmit = (values: Record<string, unknown>) => {
    setLoading(true);
    const conditionPayload = {
      patientId: patientID,
      primaryCondition: { code: values.condition as string, display: values.conditionLabel as string },
      stage: { code: values.stage as string, display: values.stageLable as string },
      conditionSeverity: { code: severity[0].value, display: severity[0].label },
      projectName: medplum.getProject()?.name ?? '',
      isPrimaryCondition: isPrimaryCondition,
    };
    if (props?.selectedData?.id) {
      updateCondition(conditionPayload);
    } else {
      createPatientCondition(conditionPayload)
        .then(() => {
          setLoading(false);
          showNotification({ message: 'Condition added successfully', color: 'blue' });
          props.close();
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error while adding condition', error);
          showNotification({ message: 'Error while adding condition', color: 'red' });
        });
    }
  };

  const updateCondition = (conditionPayload: ConditionPayload) => {
    conditionPayload.conditionId = props?.selectedData?.id || '';
    updatePatientCondition(medplum, conditionPayload).then(() => {
      showNotification({ message: 'Condition updated successfully', color: 'blue' });
      props.close();
    }).catch(() => {
      showNotification({ message: 'Error while updating condition', color: 'red' });
    });

  }

  return (
    <Modal size="46%" opened={props.opened} onClose={props.close} title={props?.selectedData?.id ? 'Edit Condition' : 'Add New Condition'}
      className="add_condition">
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid align="center" style={{ margin: '10px 0 0' }}>
          <Grid.Col span={{ base: 12, sm: 6, lg: 3.5 }}>
            <Text fw={500} style={{ fontSize: '14px', color: '#000' }}>
              Condition<span style={{ color: 'red' }}>*</span>
            </Text>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6, lg: 8.5 }}>
            <Select
              placeholder="Start typing a condition"
              searchable
              nothingFoundMessage={isCheckNoCondition ? "No conditions found" : ''}
              required
              name="condition"
              {...form.getInputProps('condition')}
              onChange={(value) => {
                const selectedCondition = primaryConditions?.find((condition) => condition.value === value);
                form.setFieldValue('condition', value);
                form.setFieldValue('conditionLabel', selectedCondition?.label);
              }}
              onSearchChange={(value) => {
                if (value === '') {
                  form.setFieldValue('condition', '');
                  form.setFieldValue('conditionLabel', '');
                }
                fetchConditions(value);
              }}
              data={primaryConditions}
              styles={(theme) => ({
                item: {
                  '&[data-selected] svg': {
                    display: 'none',
                  },
                },
                dropdown: {
                  // You can also add styles for other parts like dropdown
                },
                dropdownWrapper: {
                  // and for the dropdown wrapper
                },
              })} />
          </Grid.Col>
        </Grid>
        <Grid align="center" style={{ margin: '10px 0 0' }}>
          <Grid.Col span={{ base: 12, sm: 6, lg: 3.5 }}>
            <Text fw={500} style={{ fontSize: '14px', color: '#000' }}>
              Stage<span style={{ color: 'red' }}>*</span>
            </Text>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6, lg: 8.5 }}>
            <Select
              placeholder="Select a stage"
              name="stage"
              required
              {...form.getInputProps('stage')}
              onChange={(value) => {
                const selectedStage = StageValue?.find((stage) => stage.value === value);
                form.setFieldValue('stage', value);
                form.setFieldValue('stageLable', selectedStage?.label);
              }}
              data={StageValue}
            />
          </Grid.Col>
        </Grid>
        <Grid align="center" style={{ margin: '10px 0 10px' }}>
          <Grid.Col span={{ base: 12, sm: 6, lg: 3.5 }}>
            <Text fw={500} style={{ fontSize: '14px', color: '#000' }}>
              Severity
            </Text>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6, lg: 8.5 }}>
            <Grid style={{ gap: '10px' }} ml={0}>
              {severityData?.map((item, index) => (
                <Button
                  variant="light"
                  color="gray"
                  radius={8}
                  onClick={() => setSeverity([{ value: item.value, label: item.label }])}
                  key={index}
                  style={{
                    width: 'auto',
                    backgroundColor: severity?.[0]?.label === item?.label ? '#E9F3FF' : '#fff',
                    color: '#344054',
                    border: severity?.[0]?.label === item?.label ? '1px solid #c6dbf4' : '1px solid #D0D5DD',
                  }}
                >
                  {item?.label}
                </Button>
              ))}
            </Grid>
          </Grid.Col>
        </Grid>
        <Grid align="center" style={{ margin: '10px 0 10px' }}>
          <Grid.Col span={{ base: 12, sm: 6, lg: 3.5 }}></Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6, lg: 8.5 }}>
            <Checkbox
              size="xs"
              label="Make this as primary condition"
              checked={isPrimaryCondition}
              onChange={(event) => setIsPrimaryCondition(event.currentTarget.checked)}
            />
          </Grid.Col>
        </Grid>
        <Divider />
        <Grid gutter="xs" justify="flex-end" style={{ margin: '20px 0 0' }}>
          <Grid.Col span={{ base: 12, sm: 6, lg: 2.5 }}>
            <Button
              radius="md"
              size="md"
              style={{
                background: '#DCDCDC',
                color: '#000',
                fontWeight: 600,
                marginRight: '10px',
              }}
              onClick={props.close}
            >
              Cancel
            </Button>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6, lg: 3 }}>
            <Button
              radius="md"
              size="md"
              style={{
                fontWeight: 600,
              }}
              type="submit"
              disabled={loading}
            >
              {loading && <Loader size="sm" color="white" mr={10} />} Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default AddCondition;
