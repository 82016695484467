/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box, Card, Title, Center, Menu, UnstyledButton, Group, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import Breadcrumb from '../components/Breadcrumb';
import { ehrList, getOrganizationsByEhr } from '../fhirApi';
import { useMedplum } from '@medplum/react';
import Styles from './TitleComponent.module.css'

interface TitleProps {
  title?: string;
  EhrFilter?: boolean;
  organizationFilter?: boolean;
  selectedLabel?: string;
  selectedEhrType?: any;
  onEhrTypeSelect?: (selectedItem: any) => void;
  onOrganizationSelect?: (selectedItem: any) => void;
  getOrganizationSelect?: (selectedItem: any) => void;
}

const TitleComponent: React.FC<TitleProps> = ({
  EhrFilter,
  organizationFilter,
  selectedLabel,
  selectedEhrType,
  onEhrTypeSelect,
  onOrganizationSelect,
  getOrganizationSelect
}) => {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = React.useState({ id: 'all', label: 'All' });
  const [ehrTypes, setEhrTypes] = useState([]);
  const [organizationsByEHR, setOrganizationsByEHR] = useState([{ id: 'all', label: 'All' }]);
  const [organization, setOrganization] = React.useState<any | null>();
  const medplum = useMedplum();
  useEffect(() => {
    if (EhrFilter || opened) {
      ehrList(medplum)
        .then((response) => {
          const updatedResponse = response?.map((item: any) => ({
            ...item,
          }));
          setEhrTypes(updatedResponse);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [EhrFilter])
  const items = [
    <Menu.Item onClick={() => handleEhrTypeSelect({ id: 'all', label: 'All' })} key="all">
      All
    </Menu.Item>,
    ...ehrTypes?.map((item: any) => (
      <Menu.Item onClick={() => handleEhrTypeSelect(item)} key={item.id}>
        {item.label}
      </Menu.Item>
    )),
  ];

  const organizations = [
    <Menu.Item onClick={() => handleOrganizationSelect({ id: 'all', name: 'All' })} key="all">
      All
    </Menu.Item>,
    ...organizationsByEHR?.map((item: any) => (
      <Menu.Item key={item.id} onClick={() => handleOrganizationSelect(item)}>
        {item.name}
      </Menu.Item>
    )),
  ];

  const handleEhrTypeSelect = (item: any) => {
    if (onEhrTypeSelect) {
      setSelected(item);
      onEhrTypeSelect(item);
    }
  };

  const handleOrganizationSelect = (item: any) => {
    if (onOrganizationSelect) {
      setOrganization(item);
      onOrganizationSelect(item);
    }
  };

  useEffect(() => {
    organizationsByEHR.filter((item: any) => {
      if (selected.label === 'All') {
        setOrganization({ id: 'all', name: 'All' });
      }
      else if (item.id === organization?.id) {
        setOrganization(item);
      }
    });
  }, [selected, organizationsByEHR]);

  useEffect(() => {
    if (organizationFilter) {
      getOrganizationsByEhr(medplum, selected.label)
        .then((response) => {
          setOrganizationsByEHR(response);
          setOrganization({ id: 'all', name: 'All' });
          if (getOrganizationSelect) {
            getOrganizationSelect({ id: 'all', name: 'All' });
          }
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [organizationFilter, selected.label])

  return (
    <>
      <Card
        my="sm"
        display="flex"

      >
        <Center style={{ justifyContent: 'flex-start'}}>{<Breadcrumb />}</Center>
      </Card>

      {(EhrFilter || organizationFilter) && (
        <Box mx="lg" mb="md" mt="lg" px="sm" style={{ display: 'flex', alignItems: 'baseline', flexDirection: 'row' }}>
          <Center>
            <Text variant="sm" size="16px" fw="bold">
              {'Filters : '}
            </Text>
            {EhrFilter && (
              <Menu
                onOpen={() => setOpened(true)}
                onClose={() => setOpened(false)}
                radius="md"
                width="200px"
                withinPortal
              >
                <Center style={{ justifyContent: 'flex-start' }}>
                  <Title variant="h6" size={14} ml="lg" mr="xs">
                    EHR Type :
                  </Title>
                  <Menu.Target>
                    <UnstyledButton className={Styles.control}>
                      <Group gap="xs">
                        <span className={Styles.label}>{selectedLabel}</span>
                      </Group>
                      <IconChevronDown size="1rem" />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>{items}</Menu.Dropdown>
                </Center>

                {EhrFilter && organizationFilter && (
                  <Text variant="sm" size="16px" fw="bold">
                    {'|'}
                  </Text>
                )}
              </Menu>
            )}

            {organizationFilter && (
              <Menu
                onOpen={() => setOpened(true)}
                onClose={() => setOpened(false)}
                radius="md"
                withinPortal
              >
                <Center style={{ justifyContent: 'flex-start' }}>
                  <Title variant="h6" size={14} ml="lg" mr="xs">
                    Organizations :
                  </Title>
                  <Menu.Target>
                    <UnstyledButton className={Styles.control}>
                      <Group gap="xs">
                        <span className={Styles.label}>{organization?.name}</span>
                      </Group>
                      <IconChevronDown size="1rem" />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown style={{ width: '11rem!important' }}>{organizations}</Menu.Dropdown>
                </Center>
              </Menu>
            )}
          </Center>
        </Box>
      )}
    </>
  );
};

export default TitleComponent;
