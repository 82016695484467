import React, { useEffect, useState } from 'react';
import { Box, Group, Tabs } from '@mantine/core';
import { Appointment, Patient } from '@medplum/fhirtypes';
import './PatientDetails.css';
import TelehealthTranscription from './TelehealthComponents/TelehealthTranscription';
import ClinicalNotes from './TelehealthComponents/ClinicalNotes';
import Attendees from './TelehealthComponents/Attendees';
import { Participant } from '@zoom/videosdk';
import CustomTabs from '../../components/CustomTabs';

interface PatientDetailsProps {
  patient: Patient;
  appointment: Appointment;
  transcription: string;
  clinicalNotes?: string;
  documentId?: string;
  participants?: Participant[];
  isSessionEnded: boolean;
  isEndingSession: boolean;
  setDocumentId: (documentId: string) => void;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({
  patient,
  appointment,
  transcription,
  clinicalNotes,
  documentId,
  participants,
  isSessionEnded,
  isEndingSession,
  setDocumentId,
}: PatientDetailsProps) => {
  const [activeTab, setActiveTab] = useState<string>('Transcription');
  useEffect(() => {
    if (isEndingSession) {
      setActiveTab('Clinical Notes');
    }
  }, [isEndingSession]);

  return (
    <Group w={'100%'}>
      <Tabs color="#7F56D9" value={activeTab} defaultValue="Transcription" w={'100%'}>
        <Box display="flex" className="tabs-header">
          <Tabs.List className="tabs-list">
            <CustomTabs
              value="Transcription"
              onClick={() => setActiveTab('Transcription')}
              title="Transcriptions"
              disabled={false}
            />
            <CustomTabs
              value="Clinical Notes"
              onClick={() => setActiveTab('Clinical Notes')}
              title={!clinicalNotes ? 'Waiting to Generate Notes...' : ''}
              disabled={!clinicalNotes && !isSessionEnded}
            />
            <CustomTabs
              value="Attendees"
              onClick={() => setActiveTab('Attendees')}
              title={isSessionEnded ? 'Session Ended' : ''}
              disabled={isSessionEnded}
            />
            <CustomTabs value="Chat" onClick={() => setActiveTab('Chat')} title="Coming Soon.." disabled={true} />
          </Tabs.List>
        </Box>
        <Tabs.Panel value="Transcription" pt="xs" w={'100%'}>
          <TelehealthTranscription transcription={transcription} />
        </Tabs.Panel>
        <Tabs.Panel value="Clinical Notes" pt="xs" w={'100%'}>
          <ClinicalNotes
            clinicalNotes={clinicalNotes}
            documentId={documentId}
            isEndingSession={isEndingSession}
            patient={patient}
            setDocumentId={setDocumentId}
            transcription={transcription}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Attendees" pt="xs" w={'100%'}>
          <Attendees participants={participants || []} appointment={appointment} patient={patient} />
        </Tabs.Panel>
      </Tabs>
    </Group>
  );
};

export default PatientDetails;
