import { Button, Group, JsonInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString, stringify } from '@medplum/core';
import { OperationOutcome, ResourceType } from '@medplum/fhirtypes';
import { Form, OperationOutcomeAlert, useMedplum, useResource } from '@medplum/react';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cleanResource } from '../../resource/utils';

export function Json(): JSX.Element | null {
  const medplum = useMedplum();
  const { id } = useParams() as { id: string };
  const resourceType: ResourceType = 'Patient';
  const resource = useResource({ reference: resourceType + '/' + id });
  const navigate = useNavigate();
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();

  const handleSubmit = useCallback(
    (formData: Record<string, string>): void => {
      medplum
        .updateResource(cleanResource(JSON.parse(formData.resource)))
        .then(() => {
          setOutcome(undefined);
          navigate(`/${resourceType}/${id}/details`);
          showNotification({ color: 'green', message: 'Success' });
        })
        .catch((err) => {
          showNotification({ color: 'red', message: normalizeErrorString(err) });
        });
    },
    [medplum, resourceType, id, navigate]
  );

  if (!resource) {
    return null;
  }

  return (
    <div className="resource-tab-panel">
      <div className="resource-details edit-tab">
        {outcome && <OperationOutcomeAlert outcome={outcome} />}
        <Form onSubmit={handleSubmit}>
          <JsonInput
            name="resource"
            data-testid="resource-json"
            minRows={40}
            defaultValue={stringify(resource, true)}
            formatOnBlur
            deserialize={JSON.parse}
            className="custom-scrollbar"
            styles={{ input: { height: '500px' } }}
          />
          <Group align="right" mt="xl" style={{ justifyContent: 'flex-end' }}>
            <Button type="submit">OK</Button>
          </Group>
        </Form>
      </div>
    </div>
  );
}
