import { Avatar, Button, Divider, Group, Text } from '@mantine/core';
import { Appointment, Patient } from '@medplum/fhirtypes';
import { IconArrowNarrowRight} from '@tabler/icons-react';
import { Participant } from '@zoom/videosdk';
import { useState, useEffect } from 'react';

interface PatientDetailsProps {
  participants: Participant[];
  appointment: Appointment;
  patient: Patient;
}

const Attendees = ({ participants, patient }: PatientDetailsProps): JSX.Element => {
  const [patientId, setPatientId] = useState<string | null>(null);
  const [isPatientParticipant, setIsPatientParticipant] = useState<boolean>(false);
  const patientFullName = `${patient?.name?.[0]?.given?.join(' ') || ''} ${patient?.name?.[0]?.family || ''}`.trim();
  useEffect(() => {
    const matchingParticipant = participants.find((participant) => participant.displayName === patientFullName);
    if (matchingParticipant) {
      setPatientId(patient.id || null);
      setIsPatientParticipant(true);
    } else {
      setIsPatientParticipant(false);
    }
  }, [participants, patient]);

  const filteredParticipants = participants.filter((participant) => participant.displayName !== patientFullName);

  return (
    <div className="attendees-container">
      {filteredParticipants.map((participant, index) => (
        <div key={index} className="attendee-item">
          <Group>
            <div className="avatar-wrapper">
              <Avatar src={participant.avatar || '/placeholder.svg?height=40&width=40'} size={45} radius="xl" />
              <span className={`status-indicator ${participant.isHost ? 'online' : 'offline'}`}></span>
            </div>
            <div className="attendee-info">
              <Text className="attendee-name">{participant.displayName}</Text>
              <Text className="attendee-role">{participant.isHost ? 'Meeting host' : 'Attendee'}</Text>
            </div>
          </Group>
        </div>
      ))}
      <Divider />
      {isPatientParticipant && (
        <>
          <div className="attendee-item">
            <Group>
              <div className="avatar-wrapper">
                <Avatar src="/placeholder.svg?height=40&width=40" size={45} radius="xl" />
                <span className="status-indicator online"></span>
              </div>
              <div className="attendee-info">
                <Text className="attendee-name">{patientFullName}</Text>
                <Text className="attendee-role">Attendee</Text>
              </div>
            </Group>
            <Button
              leftSection={<img src="../../img/icons/profile-user .svg" alt="profile" />}
              className="profile-button"
              onClick={() => window.open(`/Patient/patient-dashboard/${patientId}`, '_blank')}
              rightSection={<IconArrowNarrowRight />}
            >
              Go to Profile
            </Button>
          </div>
          <Divider />
        </>
      )}
    </div>
  );
};

export default Attendees;
