import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Group, TextInput, Select, Tabs, Loader } from '@mantine/core';
import { useForm } from '@mantine/form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchPatients } from '../../../fhirApi';
import { useMedplum } from '@medplum/react';
import styles from './ConsultationModal.module.css';

const defaultQuery = (searchTerm: string): string => `{
  PatientList(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    birthDate
    name {
      family
      given
    }
  }
}`;

const filterOptions: any = {
  conditions: null,
  medications: null,
};

interface ConsultationModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSaving: boolean;
  onSave: (patientData: { id?: string; name: string; birthDate: string }) => void;
}

interface FormValues {
  name: string;
  dob: Date | null;
}

const ConsultationModal: React.FC<ConsultationModalProps> = ({ isOpen, onClose, onSave, isSaving }) => {
  const [activeTab, setActiveTab] = useState<string>('new');
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [patients, setPatients] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const medplum = useMedplum();

  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      dob: null,
    },
    validate: {
      name: (value) => (value ? null : 'Name is required'),
      dob: (value) => (value ? null : 'Date of Birth is required'),
    },
  });

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const query = defaultQuery(searchTerm);

      fetchPatients(medplum, query, filterOptions)
        .then((response: any) => {
          const patientList = response?.data?.PatientList?.map((patient: any) => {
            const givenName = patient?.name?.[0]?.given ? patient?.name?.[0]?.given.join(' ') : '';
            const familyName = patient?.name?.[0]?.family || '';
            const label = familyName ? `${givenName} ${familyName}` : givenName;

            return {
              value: patient.id,
              label: label,
              dob: patient.birthDate,
            };
          });
          setPatients(patientList || []);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    } else {
      setPatients([]);
    }
  }, [searchTerm, medplum]);

  const previousPatientRef = useRef<string | null>(null);

  useEffect(() => {
    if (selectedPatient && selectedPatient !== previousPatientRef.current) {
      previousPatientRef.current = selectedPatient;
      const patient = patients.find((p) => p.value === selectedPatient);
      if (patient) {
        form.setValues({
          name: patient.label,
          dob: patient.dob ? new Date(patient.dob) : null, // Assuming patient.dob is already a string
        });
      }
    }
  }, [selectedPatient, patients, form]);



  const handleSave = (): void => {
    if (!form.isValid()) {
      return;
    }
  
    const formatDate = (date: Date | null): string => {
      if (!date) {
        return '';
      }
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    if (activeTab === 'existing' && selectedPatient) {
      // Existing patient logic
      const patient = patients.find((p) => p.value === selectedPatient);
      if (patient) {
        const formattedDob = formatDate(form.values.dob); // Format DOB from form values
        console.log('Saving existing patient:', patient, 'Formatted DOB:', formattedDob);
  
        onSave({
          id: patient.value,
          name: patient.label,
          birthDate: formattedDob,
        });
      } else {
        console.log('Selected patient not found in patients list');
      }
    } else {
      const newPatient = form.values;
      const formattedDob = formatDate(newPatient.dob);
      console.log('Saving new patient:', { ...newPatient, dob: formattedDob });
  
      onSave({
        name: newPatient.name,
        birthDate: formattedDob,
      });
    }
  };    

  const isExistingPatient = activeTab === 'existing' && selectedPatient;
  const disableInputs: boolean | undefined = isExistingPatient && !!form.values.dob ? true : undefined;

  return (
    <Modal opened={isOpen} onClose={onClose} title="Patient Details">
      <Tabs value={activeTab} onChange={(value) => setActiveTab(value ?? 'new')}>
        <Tabs.List>
          <Tabs.Tab value="new">New Patient</Tabs.Tab>
          <Tabs.Tab value="existing">Existing Patient</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="new" pt="xs">
          <form onSubmit={form.onSubmit(handleSave)} style={{ minHeight: '50vh' }}>
            <TextInput
              label="Name"
              required
              {...form.getInputProps('name')}
              mb={12}
              disabled={disableInputs}
            />
            <label style={{ marginBottom: '8px', display: 'block' }}>Date of Birth</label>
            <DatePicker
              selected={form.values.dob}
              onChange={(date) => form.setFieldValue('dob', date)}
              dateFormat="MM/dd/yyyy"
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabled={disableInputs}
              wrapperClassName={styles['react-datepicker-wrapper']}
              className={styles['datepicker-input']}
            />
          </form>
          <Group justify="right" mt="md">
            {isSaving ? (
              <Button disabled>
                <div>Saving patient...</div>
                <Loader size={16} ml="7px" />
              </Button>
            ) : (
              <div>
                <Button onClick={handleSave} mr={4}>
                  Confirm and Next
                </Button>
                <Button variant="default" onClick={onClose}>
                  Discard
                </Button>
              </div>
            )}
          </Group>
        </Tabs.Panel>

        <Tabs.Panel value="existing" pt="xs">
          <Select
            label="Search Patient"
            placeholder="Search Patient"
            data={patients}
            searchable
            clearable
            nothingFoundMessage={searchTerm.length >= 3 && patients.length === 0 ? "No Patient" : null}
            value={selectedPatient}
            onSearchChange={setSearchTerm}
            searchValue={searchTerm}
            onChange={setSelectedPatient}
            mb="xl"
          />
          {selectedPatient && (
            <form onSubmit={form.onSubmit(handleSave)} style={{ minHeight: '50vh' }}>
              <TextInput
                label="Name"
                required
                {...form.getInputProps('name')}
                disabled={disableInputs}
                mb="md"
              />
              <label style={{ marginBottom: '8px', display: 'block' }}>Date of Birth</label>
              <DatePicker
                selected={form.values.dob}
                onChange={(date) => form.setFieldValue('dob', date)}
                dateFormat="MM/dd/yyyy"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={disableInputs}
                wrapperClassName={styles['react-datepicker-wrapper']}
                className={styles['datepicker-input']}
              />
            </form>
          )}
          <Group justify="right" mt="md">
            {isSaving ? (
              <Button disabled>
                <div>Saving patient and generating clinical notes</div>
                <Loader size={16} ml="7px" />
              </Button>
            ) : (
              <div>
                <Button onClick={handleSave} mr={4}>
                  Confirm & Next
                </Button>
                <Button variant="default" onClick={onClose}>
                  Discard
                </Button>
              </div>
            )}
          </Group>
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
};

export default ConsultationModal;
