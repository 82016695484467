import { ActionIcon, Select, Text, Tooltip, Combobox } from '@mantine/core';
import { forwardRef, useEffect, useState } from 'react';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { fetchPatients } from '../fhirApi';
import { useForm } from '@mantine/form';
import { IconSearch, IconUser } from '@tabler/icons-react';

interface AutoCompleteSearchProps {
  setPatients: (patients: { label: string; value: string; dob?: string }[]) => void;
  setSelectedPatient: (item: string) => void;
  selectedPatient: string;
  patients: { label: string; value: string; dob?: string }[];
  searchInputRef: React.RefObject<HTMLInputElement>;
}

interface FormValues {
  name: string;
  dob: Date | null;
}

interface ItemProps {
  value: string;
  label: string;
  dob?: string;
  style?: React.CSSProperties;
}

const defaultQuery = (searchTerm: string): string => `{
  PatientList(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    birthDate
    name {
      family
      given
    }
  }
}`;

const getPatientQuery = (patientId: string): string => `{
  Patient(id: "${patientId}") {
    name {
      family
      given
    }
  }
}`;

const AutoCompleteSearch = ({
  setPatients,
  setSelectedPatient,
  selectedPatient,
  patients,
  searchInputRef,
}: AutoCompleteSearchProps): JSX.Element => {
  const medplum = useMedplum();
  const [data, setData] = useState<ItemProps[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      dob: null,
    },
  });

  useEffect(() => {
    const mappedData = patients?.map((patient) => ({
      value: patient.value,
      label: patient.label,
      dob: patient?.dob,
    }));
    setData(mappedData);
  }, [patients]);

  useEffect(() => {
    if (searchTerm) {
      const query = defaultQuery(searchTerm);

      fetchPatients(medplum, query)
        .then((response: any) => {
          const patientList = response?.data?.PatientList?.map((patient: any) => {
            const givenName = patient?.name?.[0]?.given ? patient?.name?.[0]?.given.join(' ') : '';
            const familyName = patient?.name?.[0]?.family || '';
            const label = familyName ? `${givenName} ${familyName}` : givenName;

            return {
              value: patient.id,
              label: label,
              dob: patient.birthDate,
            };
          });
          setPatients(patientList);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    } else {
      setPatients([]);
    }
  }, [searchTerm, medplum, setPatients]);

  useEffect(() => {
    if (selectedPatient) {
      const patient = patients.find((p) => p.value === selectedPatient);
      if (patient) {
        form.setValues({
          name: patient.label,
          dob: patient.dob ? new Date(patient.dob) : null,
        });
        setSearchTerm(patient.label);
      } else {
        const query = getPatientQuery(selectedPatient);
        fetchPatients(medplum, query)
          .then((response: any) => {
            const patient = response?.data?.Patient;
            const givenName = patient?.name?.[0]?.given ? patient?.name?.[0]?.given.join(' ') : '';
            const familyName = patient?.name?.[0]?.family || '';
            const label = familyName ? `${givenName} ${familyName}` : givenName;
            form.setValues({
              name: label,
            });
            setSearchTerm(label);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [selectedPatient, patients]);

  const ItemComponent = forwardRef<HTMLDivElement, ItemProps>(({ label, dob, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} style={{ backgroundColor: '#F8FAFC', color: 'black' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <ResourceAvatar />
        <div>
          <Text>{label}</Text>
          <Text size="xs" color="black">
            {dob}
          </Text>
        </div>
      </div>
    </div>
  ));

  const handleChange = (value: string | null) => {
    if (value !== null) {
      setSelectedPatient(value);
      const selectedItem = data?.find((item) => item?.value === value);
      if (selectedItem) {
        setSearchTerm(selectedItem.label);
      }
    } else {
      setSelectedPatient('');
      setSearchTerm('');
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    if (value === '') {
      setPatients([]);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
      <Combobox>
        <Combobox.Target>
          <Select
            ref={searchInputRef}
            placeholder="Search Patient"
            data={data}
            value={selectedPatient}
            searchValue={searchTerm}
            searchable
            clearable
            leftSection={<IconSearch size={17} style={{ marginTop: '2px' }} />}
            onChange={handleChange}
            onSearchChange={handleSearchChange}
          />
        </Combobox.Target>
        <Combobox.Dropdown>
          {data.map((item) => (
            <Combobox.Option key={item.value} value={item.value}>
              <ItemComponent {...item} />
            </Combobox.Option>
          ))}
        </Combobox.Dropdown>
      </Combobox>
      {selectedPatient && (
        <Tooltip label="View profile" position="top" withArrow arrowSize={5}>
          <ActionIcon
            variant="default"
            size={38}
            onClick={() => window.open(`/Patient/patient-dashboard/${selectedPatient}`, '_blank')}
            style={{
              border: '1px solid #D0D5DD',
              borderRadius: '8px',
              marginTop: '2px',
            }}
          >
            <IconUser size={20} />
          </ActionIcon>
        </Tooltip>
      )}
    </div>
  );
};

export default AutoCompleteSearch;
