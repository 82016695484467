/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Text, Group, Skeleton } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import TitleComponent from '../TitleComponent';
import { useMedplum } from '@medplum/react';
import { practitionersByOrganization } from '../../fhirApi';
import { UpcomingAppointments } from '../UpcomingAppointments';
import { ServiceRequest } from './ServiceRequest';
import Each from '../Each';
import Styles from './Practitioners.module.css'

export function Practitioners(): JSX.Element {
  const [selected, setSelected] = React.useState({ id: 'all', label: 'All' });
  const medplum = useMedplum();
  const [organizationResponse, setOrganizationResponse] = useState<any | null>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataFound, setIsDataFound] = useState<boolean>(false);

  const handleOrganizationSelect = (selectedItem: any) => {
    setSelected(selectedItem);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setOrganizationResponse([]);
      setIsDataFound(false);
      const response = await practitionersByOrganization(medplum, selected?.id);
      if (response?.data.data.length !== 0) {
        setOrganizationResponse(response?.data.data || []);
      } else {
        setIsDataFound(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selected]);

  return (
    <>
      <TitleComponent title="Practitioners" organizationFilter={true} onOrganizationSelect={handleOrganizationSelect} />

      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={{ base: 12, md: 12, lg: 12}}>
            <Card withBorder radius="md" className={Styles.card}>
              <Card.Section className={Styles.section} p="lg">
                <Text className="darkText" fw={700}>
                  Practitioners Connected with Organization
                </Text>
              </Card.Section>

              <Card.Section px="lg" display="flex" className={Styles.section}>
                <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                  <Text className="darkText" fw={700}>
                    Name
                  </Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                  <Text className="darkText" fw={700}>
                    Specialization
                  </Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 5 }}>
                  <Text className="darkText" fw={700}>
                    Email ID
                  </Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                  <Text className="darkText" fw={700}>
                    Phone No.
                  </Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                  <Text className="darkText" fw={700}>
                    Organizations
                  </Text>
                </Group>
              </Card.Section>

              <Group
                px="xs"
                className={Styles.scrollbar}
                display="block"
                style={{ height: '281px', overflowX: 'hidden' }}
              >
                <Each
                  of={organizationResponse}
                  render={(item) => (
                    <Card.Section display="flex" className={Styles.section}>
                      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                        <Text className="lightText" fw={500}>
                          {item?.name || '-'}
                        </Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                        <Text className="lightText" fw={500}>
                          {item?.specialization || '-'}
                        </Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 5 }}>
                        <Text className="lightText" fw={500}>
                          {item?.email || '-'}
                        </Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                        <Text className="lightText" fw={500}>
                          {item?.phone || '-'}
                        </Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                        <Text className="lightText" fw={500}>
                          {item?.organization || '-'}
                        </Text>
                      </Group>
                    </Card.Section>
                  )}
                />
                {isDataFound && (
                  <Group style={{ justifyContent: 'center', flex: 1, position: 'relative', top: '50%' }}>
                    <Text className="darkText" fw={700}>
                      No Data Found
                    </Text>
                  </Group>
                )}
                {isLoading && (
                  <>
                    <Skeleton height={8} radius="xl" />
                    <Skeleton height={8} mt={6} radius="xl" />
                    <Skeleton height={8} mt={6} width="70%" radius="xl" />
                  </>
                )}
              </Group>

              <Card.Section className={Styles.footer}>
                <Group style={{ alignItems: 'flex-end' }} gap={30}>
                  <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
                  <Link
                    style={{
                      textDecoration: 'none',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      flex: 1,
                      color: '#5D6874',
                      display: 'flex',
                    }}
                    to={'/all-practitioners-connected-with-organizations'}
                  >
                    <Text fw={700} mr="5px">
                      View All
                    </Text>
                    <IconArrowRight size="16px" />
                  </Link>
                </Group>
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>

      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={{ base: 12, md: 12, lg: 6}} style={{}}>
            <UpcomingAppointments orgId={selected?.id} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 6}}>
            <ServiceRequest orgId={selected?.id}></ServiceRequest>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
