/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
import { Box, Card, Text, Center, Tabs, Table, Anchor, Badge, Pagination, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { MedicalHistory } from './MedicalHistory';
import { Link, useParams } from 'react-router-dom';
import { fetchPatientDetails } from '../../fhirApi';
import { formatDate } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { Insurance } from './Insurance';
import { IconEye } from '@tabler/icons-react';
import Styles from './PatientDetails.module.css'

export function PatientDetails(): JSX.Element {
  const medplum = useMedplum();
  const patientID = useParams().id;
  const defaultQuery = `${
    '{ Patient(id: "' +
    patientID +
    '") { resourceType id  patients: ObservationList(_reference: patient, _sort: "-_lastUpdated") { resourceType id category {coding{code display}} meta{lastUpdated} referenceRange{text} effectiveDateTime status code { text } encounter { display } } } }'
  }`;
  const [query, setQuery] = useState(defaultQuery);
  const [data, setData] = useState([]);
  const [noDataRow, setNoDataRow] = useState(false);
  const detailsTab = [
    { value: 'medicalHistory', name: 'Medical History' },
    { value: 'observations', name: 'Observations' },
    { value: 'immunization', name: 'Immunizations' },
    { value: 'reports', name: 'Reports' },
    { value: 'careTeam', name: 'Care Teams' },
    { value: 'insurance', name: 'Insurance' },
    { value: 'encounter', name: 'Encounter' },
  ];
  const [activeTab, setActiveTab] = useState(detailsTab[0].value);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const observationsFields = [
    { fieldName: 'observationName', displayName: 'Observation Name' },
    { fieldName: 'category', displayName: 'Category' },
    { fieldName: 'visit', displayName: 'Visit' },
    { fieldName: 'lastUpdated', displayName: 'Date Issued' },
    { fieldName: 'effectiveDate', displayName: 'Effective Date' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const immunizationFields = [
    { fieldName: 'immunizationName', displayName: 'Immunization Name' },
    { fieldName: 'manufacturer', displayName: 'Manufacturer' },
    { fieldName: 'date', displayName: 'Date Administered' },
    { fieldName: 'vaccinationRoute', displayName: 'Vaccination Route' },
    { fieldName: 'site', displayName: 'Body Site' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const reportFields = [
    { fieldName: 'reportName', displayName: 'Report Name' },
    { fieldName: 'category', displayName: 'Category' },
    { fieldName: 'performer', displayName: 'Performer' },
    { fieldName: 'issueDate', displayName: 'Date Issued On' },
    { fieldName: 'effectiveDate', displayName: 'Effective Date' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const careTeamFields = [
    { fieldName: 'name', displayName: 'Name' },
    { fieldName: 'role', displayName: 'Role' },
    { fieldName: 'category', displayName: 'Category' },
    { fieldName: 'email', displayName: 'Email' },
    { fieldName: 'phone', displayName: 'Contact' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const encounterFields = [
    { fieldName: 'type', displayName: 'Encounter Type' },
    { fieldName: 'startDate', displayName: 'Date' },
    { fieldName: 'practitioner', displayName: 'Practitioner' },
    { fieldName: 'location', displayName: 'Location' },
    { fieldName: 'status', displayName: 'Status' },
    { fieldName: 'actions', displayName: 'Actions' },
  ];

  const successStatus = ['active', 'completed', 'fulfilled', 'final','finished'];
  const failedStatus = ['inactive', 'incomplete', 'cancelled', 'unknown'];
  const warningStatus = ['booked'];
  const infoStatus = ['amended','in-progress'];
  const blueColorStatus = ['arrived'];

  const statusButton = (status: any) => {
    const formattedStatus = status?.toLowerCase();
    return successStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#DEF5EA' }}>
        {status}
      </Badge>
    ) : failedStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#FAEBEB' }}>
        {status}
      </Badge>
    ) : warningStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#FCF2DF' }}>
        {status}
      </Badge>
    ) : infoStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#F2F2F2' }}>
        {status}
      </Badge>
    ) : blueColorStatus.includes(formattedStatus) ? (
      <Badge className={Styles.badge} variant="filled" style={{ background: '#DBF0FD' }}>
        {status}
      </Badge>
    ) : (
      '-'
    );
  };

  function getCategoryName(name: any): string {
    let list: any = [];
    name.forEach((item: any) => {
      list.push(item.text);
    });
    let formmatedFirstName = list.join(', ');
    return formmatedFirstName;
  }

  useEffect(() => {
    fetchPatientDetails(medplum, query)
      .then((response) => {
        setIsLoading(false);
        if (response?.data.Patient.patients?.length) {
          const fetchedData = response?.data.Patient.patients;
          setNoDataRow(false);
          let tempList: any = [];
          if (activeTab === 'observations') {
            tempList = fetchedData?.map((item: any) => ({
              id: item.id,
              observationName: item?.code?.text || '-',
              category: item?.category[0]?.coding[0]?.code || '-',
              lastUpdated: formatDate(item?.meta?.lastUpdated) || '-',
              range: item?.referenceRange ? item?.referenceRange[0]?.text : '-',
              visit: item?.encounter?.display || '-',
              status: item?.status,
              effectiveDate: formatDate(item?.effectiveDateTime) || '-',
            }));
          } else if (activeTab === 'immunization') {
            tempList = fetchedData?.map((item: any) => ({
              id: item.id,
              immunizationName: item?.vaccineCode?.text || '-',
              manufacturer: item?.manufacturer?.display || '-',
              date: item?.occurrenceDateTime || '-',
              vaccinationRoute: item?.route?.text || '-',
              site: item?.site?.text || '-',
              status: item?.status,
              effectiveDate: formatDate(item?.effectiveDateTime) || '-',
            }));
          } else if (activeTab === 'reports') {
            tempList = fetchedData?.map((item: any) => ({
              id: item.id,
              reportName: item?.code?.text || '-',
              category: getCategoryName(item?.category) || '-',
              performer: item?.performer ? item?.performer[0]?.display : '-',
              status: item?.status,
              issueDate: formatDate(item?.issued) || '-',
              effectiveDate: formatDate(item?.effectiveDateTime) || '-',
            }));
          } else if (activeTab === 'careTeam') {
            tempList = fetchedData?.map((item: any) => ({
              id: item.id,
              name: item?.participant && item?.participant[0]?.member ? item?.participant[0]?.member?.display : '-',
              category: item?.category && item?.category[0]?.text ? item?.category[0]?.text : '-',
              role: item?.participant && item?.participant[0]?.role ? item?.participant[0]?.role[0]?.text : '-',
              email: item?.telecom ? item?.telecom?.find((t: any) => t.system === 'email')?.value : '-',
              phone: item?.telecom ? item?.telecom?.find((t: any) => t.system === 'phone')?.value : '-',
            }));
          }
          else if (activeTab === 'encounter') {
            tempList = fetchedData?.map((item: any) => ({
              id: item.id,
              type: item?.type?.[0]?.coding?.[0]?.display || '-',
              startDate: formatDate(item?.period?.start) || '-',
              practitioner: item?.participant?.[0]?.individual?.display || '-',
              location: item?.location?.[0]?.location?.display || '-',
              status: item?.status,
            }));
          }
          setData(tempList);
        } else {
          setNoDataRow(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [query, activeTab]);

  const displayData = data?.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
    setIsLoading(true);
    setCurrentPage(1);
    if (tab === 'observations') {
      const newQuery = defaultQuery;
      setQuery(newQuery);
    } else if (tab === 'immunization') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: ImmunizationList(_reference: patient) { resourceType id vaccineCode{text} manufacturer{display} occurrenceDateTime route{text} site{text} status} } }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'reports') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: DiagnosticReportList(_reference: patient) { resourceType id code{text} category{text}  performer{display} issued effectiveDateTime status}} }'
      }`;
      setQuery(newQuery);
    } else if (tab === 'careTeam') {
      const newQuery = `${
        '{ Patient(id: "' +
        patientID +
        '") { resourceType id  patients: CareTeamList(_reference: patient) { resourceType id participant{role{text} member{display}} telecom{system value} category{text}  }} }'
      }`;
      setQuery(newQuery);
    }else if (tab === 'encounter') {
      const newQuery = `
    {
  Patient(id: "${patientID}") { resourceType id patients: EncounterList(_reference: patient, _sort: "-_lastUpdated") { resourceType id status type { coding { code display } text } period { start end } location { location {  display reference  }} participant { individual { reference display } type { text }} meta {lastUpdated }}}}`;
      setQuery(newQuery);
    }
  };

  const setDetailsLink = (id: string) => {
    let link = '#';
    if (activeTab === 'observations') {
      link = `${'/Observation/' + id + '/details'}`;
    } else if (activeTab === 'immunization') {
      link = `${'/Immunization/' + id + '/details'}`;
    } else if (activeTab === 'reports') {
      link = `${'/DiagnosticReport/' + id + '/details'}`;
    } else if (activeTab === 'careTeam') {
      link = `${'/CareTeam/' + id + '/details'}`;
    }else if (activeTab === 'encounter') {
      link = `${'/Encounter/' + id + '/details'}`;
    }
    return link;
  };

  const renderTable = (data: any, fields: any) => (
    <Table highlightOnHover style={{ overflowX: 'hidden' }} className={Styles.table} verticalSpacing="sm">
      <thead style={{ textAlign: 'left' }}>
        <tr className={Styles.tableRow}>
          {fields.map((field: any) => (
            <th className={Styles.tableRow} key={field.fieldName}>
              {field.displayName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
              <tr className={Styles.tableRow}>
              <td className={Styles.tableRow} colSpan={observationsFields.length}>
                <Center>{'Loading...'}</Center>
              </td>
            </tr>
          ) : noDataRow ? (
            <tr className={Styles.tableRow}>
              <td className={Styles.tableRow} colSpan={observationsFields.length}>
                <Center>{'No Data Found'}</Center>
              </td>
            </tr>
          ) : (
            data.map((item: any) => (
              <tr className={Styles.tableRow} key={item.id}>
                {fields.map((field: any) => (
                  <>
                    {field.fieldName === 'observationName' || field.fieldName === 'immunizationName' ? (
                      <td className={field.fieldName === 'status' ? Styles.tableRow : ''} key={field.fieldName}>
                        <Anchor
                          href={setDetailsLink(item.id)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={Styles.highlitedRow}
                          style={{ fontWeight: '400'}}
                        >
                          {item[field.fieldName]}
                        </Anchor>
                      </td>
                    ) : field.fieldName === 'actions' ? (
                      <td className={Styles.tableRow} key={field.fieldName}>
                        <Tooltip label="View" withArrow>
                        <Link
                          style={{
                            textDecoration: 'none',
                            color: '#3673b6',
                            padding: '5px',
                            fontSize: '11px',
                            fontWeight: 700,
                            borderRadius: '2px',
                          }}
                          target="_blank"
                          to={setDetailsLink(item.id)}
                        >
                          <IconEye size={20} />
                        </Link>
                       </Tooltip> 
                      </td>
                    ) : field.fieldName === 'status' ? (
                      <td className={Styles.tableRow} key={field.fieldName}>
                        {statusButton(item[field.fieldName])}
                      </td>
                    ) : (
                      <td className={Styles.tableRow} key={field.fieldName}>
                        {item[field.fieldName] || '-'}
                      </td>
                    )}
                  </>
                ))}
              </tr>
            ))
          )}
        </tbody>
    </Table>
  );

  return (
    <Box display="block">
      <Card withBorder radius="md" className={Styles.card}>
        <Card.Section className={Styles.header} p="lg">
          <Center className={Styles.dBlock} style={{ justifyContent: 'space-between' }}>
            <Text fw={700}>Details</Text>
            <Text fw={700}></Text>
          </Center>
        </Card.Section>
        <Card.Section className={Styles.section} p={0} style={{ borderBottom: '0px !important' }}>
          <Tabs
            variant="pills"
            defaultValue={activeTab}
            className={Styles.customTabs}
            onChange={(newTab) => handleTabChange(newTab)}
          >
            <Tabs.List
              className={Styles.customTabList}
              style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap', background: '#F5FAFF' }}
            >
              {detailsTab.map((tab) => (
                <Tabs.Tab key={tab.value} value={tab.value}>
                  {tab.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            <Tabs.Panel value="medicalHistory" pt="md" className={Styles.customTabPanel}>
              <MedicalHistory />
            </Tabs.Panel>

            <Tabs.Panel value="observations" pt="md" className={Styles.customTabPanel}>
              <Card withBorder radius="md" className={Styles.card}>
                <Card.Section className={Styles.header} p="lg">
                  <Text className="darkText" fw={700}>
                    Observations
                  </Text>
                </Card.Section>
                <Card.Section className={Styles.section} p="lg">
                  {renderTable(displayData, observationsFields)}
                </Card.Section>
              </Card>
            </Tabs.Panel>
            <Tabs.Panel value="immunization" pt="md" className={Styles.customTabPanel}>
              <Card withBorder radius="md" className={Styles.card}>
                <Card.Section className={Styles.header} p="lg">
                  <Text className="darkText" fw={700}>
                    Immunization
                  </Text>
                </Card.Section>
                <Card.Section className={Styles.section} p="lg">
                  {renderTable(displayData, immunizationFields)}
                </Card.Section>
              </Card>
            </Tabs.Panel>
            <Tabs.Panel value="reports" pt="md" className={Styles.customTabPanel}>
              <Card withBorder radius="md" className={Styles.card}>
                <Card.Section className={Styles.header} p="lg">
                  <Text className="darkText" fw={700}>
                    Reports
                  </Text>
                </Card.Section>
                <Card.Section className={Styles.section} p="lg">
                  {renderTable(displayData, reportFields)}
                </Card.Section>
              </Card>
            </Tabs.Panel>
            <Tabs.Panel value="encounter" pt="md" className={Styles.customTabPanel}>
              <Card withBorder radius="md" className={Styles.card}>
                <Card.Section className={Styles.header} p="lg">
                  <Text className="darkText" fw={700}>
                    Encounters
                  </Text>
                </Card.Section>
                <Card.Section className={Styles.section} p="lg">
                  {renderTable(displayData, encounterFields)}
                </Card.Section>
              </Card>
            </Tabs.Panel>
            <Tabs.Panel value="careTeam" pt="md" className={Styles.customTabPanel}>
              <Card withBorder radius="md" className={Styles.card}>
                <Card.Section className={Styles.header} p="lg">
                  <Text className="darkText" fw={700}>
                    Care Teams
                  </Text>
                </Card.Section>
                <Card.Section className={Styles.section} p="lg">
                  {renderTable(displayData, careTeamFields)}
                </Card.Section>
              </Card>
            </Tabs.Panel>
            <Tabs.Panel value="insurance" pt="md" className={Styles.customTabPanel}>
              <Insurance />
            </Tabs.Panel>
          </Tabs>
          <Center my="md" style={{ justifyContent: 'flex-end' }}>
            <Pagination
              value={currentPage}
              total={Math.ceil(data.length / itemsPerPage)}
              onChange={handlePageChange}
              defaultValue={itemsPerPage}
              className={Styles.pagination}
            />
          </Center>
        </Card.Section>
      </Card>
    </Box>
  );
}