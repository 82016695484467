import { ActionIcon, Box, Flex, Group, Loader, LoadingOverlay, Paper, Text, Textarea, Tooltip } from '@mantine/core';
import './Components.css';
import React, { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { createClinicalNote, exportClinicalNote, updateClinicalNote } from '../../../fhirApi';
import { IconCheck, IconDownload, IconPencil, IconReload, IconTrash } from '@tabler/icons-react';
import { Patient } from '@medplum/fhirtypes';
import { useParams } from 'react-router-dom';
import { CreateClinicalNotePayload } from '../../../utils/constant';
interface ClinicalNotesProps {
  clinicalNotes?: string;
  documentId?: string;
  patient: Patient;
  isEndingSession: boolean;
  setDocumentId: (documentId: string) => void;
  transcription: string;
}

const ClinicalNotes = (props: ClinicalNotesProps): JSX.Element => {
  const { documentId, setDocumentId, transcription } = props;
  const [clinicalNote, setClinicalNote] = useState<string | undefined>('No notes available, please generate notes');
  const [isEditTextarea, setIsEditTextarea] = useState<boolean>(false);
  const medplum = useMedplum();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const patientName = `${props.patient?.name?.[0]?.given?.join(' ') || ''} ${props.patient?.name?.[0]?.family || ''
    }`.trim();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { id: appointmentId } = useParams<{ id: string }>();

  // Handle textarea change event
  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setClinicalNote(event.currentTarget.value);
  };

  // Update clinical note when props change
  useEffect(() => {
    if (props.clinicalNotes && props.clinicalNotes.length > 0) {
      setClinicalNote(props.clinicalNotes);
    }
  }, [props.clinicalNotes]);

  // Update clinical notes in the backend
  const updateNotes = async (): Promise<void> => {
    if (documentId && clinicalNote && !isUpdating) {
      try {
        const payload = {
          documentId: documentId,
          clinicalnote: clinicalNote,
        };
        setIsUpdating(true);
        await updateClinicalNote(medplum, payload).then((response) => {
          setClinicalNote(response.transcript);
          // Here transcript means clinical note as wrongly named in backend
          setIsEditTextarea(false);
        });
        setIsUpdating(false);
        showNotification({ color: 'green', message: 'Clinical Note updated successfully' });
      } catch (error) {
        console.log(error);
        setIsUpdating(false);
        showNotification({ color: 'red', message: 'Failed to update clinical note' });
      }
    }
  };

  const downloadPdf = async (documentId: string, patientName: string): Promise<void> => {
    setIsDownloading(true);
    await exportClinicalNote(medplum, documentId)
      .then((response: any) => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          showNotification({ color: 'green', message: 'Exported successfully' });
        }
        setIsDownloading(false);
      })
      .catch((error: Error) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
        setIsDownloading(false);
      });
  };

  // Handle delete clinical note
  const handleDelete = async (): Promise<void> => {
    console.log('documentId', documentId, 'isUpdating', isUpdating);
    if (documentId && !isUpdating && setDocumentId) {
      try {
        setIsDeleting(true);
        await medplum.deleteResource('DocumentReference', documentId);
        setIsDeleting(false);
        showNotification({ color: 'green', message: 'Clinical Note deleted successfully' });
        setClinicalNote('No notes available, please generate notes');
        setDocumentId('');
      } catch (error) {
        console.log(error);
        setIsDeleting(false);
        showNotification({ color: 'red', message: 'Failed to delete clinical note' });
      }
    }
  };

  //Handle Regenerate clinical note
  const handleRegenerate = async (): Promise<void> => {
    try {
      setIsUpdating(true);
      if (transcription) {

        const payload: CreateClinicalNotePayload = {
          appointmentId: appointmentId ?? '',
          transcript: transcription,
        };

        await createClinicalNote(medplum, payload).then((response) => {
          setClinicalNote(response?.data?.clinicalNote);
          setDocumentId(response?.data?.documentId);
          showNotification({ color: 'green', message: 'Regenerated notes successfully' });
        });
        showNotification({ color: 'green', message: 'Clinical Note generated successfully' });
      } else {
        setClinicalNote('No transcription available to generate notes');
        showNotification({ color: 'red', message: 'No transcription available to generate notes' });
      }
      setIsUpdating(false);
    } catch (error) {
      console.error(error);
      showNotification({ color: 'red', message: 'Failed to generate clinical note' });
      setIsUpdating(false);
    }
  };

  return (
    <Paper w={'100%'} h={'100%'}>
      {props.isEndingSession || isUpdating ? (
        <Flex justify="center" align="center" h={'50vh'}>
          <Loader size="md" variant="dots" color={'#7F56D9'} />
        </Flex>
      ) : (
        <Group justify="right">
          <Box className="notes-actions-container">
            {documentId && (
              <>
                {isEditTextarea ? (
                  <Tooltip label="Save">
                    <ActionIcon onClick={updateNotes} loading={isUpdating} size={33} className="button-icon" style={{ background: 'none' }}>
                      <IconCheck size={20} color="#00B14A" stroke={2.2} />
                    </ActionIcon>
                  </Tooltip>
                ) : (
                  <Tooltip label="Edit">
                    <ActionIcon size={33} className="button-icon" onClick={() => setIsEditTextarea(true)} style={{ background: 'none' }}>
                      <IconPencil size={20} stroke={2.2} color="#667085" />
                    </ActionIcon>
                  </Tooltip>
                )}
              </>
            )}
            {documentId ? (
              <Tooltip label="Delete">
                <ActionIcon loading={isDeleting} size={33} className="button-icon" onClick={handleDelete} ml={12} style={{ background: 'none' }}>
                  <IconTrash size={20} stroke={2.2} color="red" />
                </ActionIcon>
              </Tooltip>
            ) : (
              <Tooltip label="Regenerate">
                <ActionIcon size={33} className="button-icon" onClick={handleRegenerate} ml={12} style={{ background: 'none' }}>
                  <IconReload size={20} stroke={2.2} />
                </ActionIcon>
              </Tooltip>
            )}
            {documentId && (
              <Tooltip label="Download">
                <ActionIcon
                  loading={isDownloading}
                  size={33}
                  className="button-icon"
                  onClick={() => downloadPdf(documentId || '', patientName)}
                  ml={12}
                  style={{ background: 'none' }}
                >
                  <IconDownload size={20} stroke={2.2} color="#667085" />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>

          <Box className="clinical-notes-box" w={'100%'}>
            {!isEditTextarea ? (
              clinicalNote?.split('\n').map((paragraph: string, index: number, array: string[]) => (
                <React.Fragment key={`${paragraph}-${index}`}>
                  <Text
                    className="note-text"
                    span
                    dangerouslySetInnerHTML={{
                      __html: paragraph.replace(/(\w+):/g, '<span class="note-label">$1</span>:'),
                    }}
                  />
                  {index !== array.length - 1 && paragraph.trim() && <br />}
                </React.Fragment>
              ))
            ) : (
              <Box pos="relative">
                {isUpdating && (
                  <LoadingOverlay
                    visible={true}
                    style={{ backdropFilter: 'blur(4px)' }}
                  />
                )}
                <Textarea
                  minRows={19}
                  disabled={isUpdating}
                  styles={{ input: { border: 'none' } }}
                  onChange={handleTextareaChange}
                >
                  {clinicalNote}
                </Textarea>
              </Box>
            )}
          </Box>
        </Group>
      )}
    </Paper>
  );
};

export default ClinicalNotes;
