import { Card, Text, Group, Button, Skeleton } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { appointmentsByOrganization } from '../fhirApi';
import { useMedplum } from '@medplum/react';
import { format } from 'date-fns';
import Each from './Each';
import Styles from './UpcomingAppointments.module.css'

export interface upcommingAppointmentList {
  id: string;
  patientId: string;
  patientName: string;
  birthdate: string;
  appointmentDate: string;
}

export function UpcomingAppointments(props: any) {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [upcommingAppointment, setUpcomingAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataFound, setIsDataFound] = useState<boolean>(false);
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return format(date, 'MMM DD YYYY');
  };

  // Unused code leaving for future reference
  // const formatDateRange = (dateString: string): string => {
  //   const date = new Date(dateString);

  //   const startTime = format(date, 'hh:mm');
  //   const endTime = format(addMinutes(date, 30), 'hh:mm A');

  //   return `${startTime}-${endTime}`;
  // };
  const fetchData = async () => {
    try {
      setIsLoading(true);
      setUpcomingAppointments([]);
      setIsDataFound(false);
      const response = await appointmentsByOrganization(medplum, props.orgId);
      if (response?.data.data.length !== 0) {
        setUpcomingAppointments(response?.data.data || []);
      } else {
        setIsDataFound(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.orgId]);

  return (
    <Card withBorder radius="md" className={Styles.card}>
      <Card.Section className={Styles.section} p="lg">
        <Text className="darkText" fw={700}>
          Upcoming Appointments
        </Text>
      </Card.Section>

      <Card.Section px="lg" display="flex" className={Styles.section}>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Patient Name
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
          <Text className="darkText" fw={700}>
            Birth Date
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
          <Text className="darkText" fw={700}>
            Appointment Date
          </Text>
        </Group>
        <Group style={{ justifyContent: 'flex-start', flex: 1 }}>
          <Text className="darkText" fw={700}>
            Actions
          </Text>
        </Group>
      </Card.Section>

      <Group px="xs" className={Styles.scrollbar} display="block" style={{ height: '281px', overflowX: 'hidden', padding: '16px' }}>
        <Each
          of={upcommingAppointment}
          render={(item: upcommingAppointmentList) => (
            <Card.Section display="flex" className={Styles.section}>
              <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
                <Text className="f-main" fw={500} onClick={() => navigate(`/Patient/patient-dashboard/${item.patientId}` )} style={{ cursor: 'pointer' }}>
                  {item.patientName}
                </Text>
              </Group>
              <Group style={{ justifyContent: 'flex-start', flex: 2 }}>
                <Text className="lightText" fw={500}>
                  {formatDate(item.birthdate)}
                </Text>
              </Group>
              <Group style={{ justifyContent: 'flex-start', flex: 3 }}>
                <Text className="lightText" fw={500}>
                  {formatDate(item.appointmentDate)}
                </Text>
              </Group>
              <Group style={{ justifyContent: 'flex-start', flex: 1 }}>
                <Button
                  size="xs"
                  radius="xs"
                  style={{
                    textDecoration: 'none',
                    color: '#fff',
                    backgroundColor: '#3673B6',
                  }}
                >
                  View
                </Button>
              </Group>
            </Card.Section>
          )}
        />
        {isDataFound && (
          <Group style={{ justifyContent: 'center', flex: 1, position: 'relative', top: '50%' }}>
            <Text className="darkText" fw={700}>
              No Data Found
            </Text>
          </Group>
        )}
        {isLoading && (
          <div style={{ marginTop: '10px'}}>
            <Skeleton height={8} radius="xl" />
            <Skeleton height={8} mt={6} radius="xl" />
            <Skeleton height={8} mt={6} width="70%" radius="xl" />
          </div>
        )}
      </Group>

      <Card.Section className={Styles.footer}>
        <Group style={{ alignItems: 'flex-end' }} gap={30}>
          <div style={{ textDecoration: 'none', alignItems: 'flex-end', flex: 2 }}></div>
          <Link
            style={{
              textDecoration: 'none',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              color: '#5D6874',
              display: 'flex',
            }}
            to={''}
          >
            <Text fw={700} mr="5px">
              View All
            </Text>
            <IconArrowRight size="16px" />
          </Link>
        </Group>
      </Card.Section>
    </Card>
  );
}
