import React from 'react';
import { Table, Text, Group, Image, Center, Tooltip } from '@mantine/core';
import { Appointment } from '@medplum/fhirtypes';
import { format } from 'date-fns';
import { StatusBadge } from '../../components/StatusBadge';
import {
  STATUS_COLORS,
  STATUS_BACKGROUND_COLORS,
  STATUS_TEXT_COLORS,
  STATUS_BORDER_COLORS,
} from '../../utils/constants';
import { IconCircleCheck, IconDotsVertical, IconVideo } from '@tabler/icons-react';

import './TelehealthTable.css';

interface TelehealthTableProps {
  data: any;
  isLoading: boolean;
  statuses?: { system: string; code: string; display: string }[];
  handleStartCall: (appointmentId: string) => void;
}

const TelehealthTable: React.FC<TelehealthTableProps> = ({ data, isLoading, statuses, handleStartCall }) => {
  const icons = {
    IcUser: '/img/icons/ic-user.svg',
    IcShieldPlus: '/img/icons/ic-shield-plus.svg',
    IcSquareCheck: '/img/icons/ic-square-check.svg',
    IcCard: '/img/icons/ic-id-card.svg',
    IcMobile: '/img/icons/ic-mobile.svg',
    IcChat: '/img/icons/ic-chat.svg',
  };

  return (
    <Table className='telehealth-table'>
      <thead>
        <tr>
          <th>Time & Status</th>
          <th>Patient Name</th>
          <th>Appointment Date</th>
          <th>Reason</th>
          <th>Duration</th>
          <th>Visit Components</th>
          <th>Provider</th>
          <th className='action'>Action</th>
        </tr>
      </thead>

      <tbody >
        {isLoading && (
          <tr>
            <td colSpan={8}>
              <Center>Loading...</Center>
            </td>
          </tr>
        )}
        {!isLoading && data?.length === 0 && (
          <tr>
            <td colSpan={8}>
              <Center>No data found</Center>
            </td>
          </tr>
        )}
        {!isLoading && data?.length > 0 && data?.map((item: any) => {
          const appointment = item.resource as Appointment;
          const patient = appointment.participant?.find((p) => p.actor?.reference?.startsWith('Patient'));
          const practitioner = appointment.participant?.find((p) => p.actor?.reference?.startsWith('Practitioner'));
          const isAppointmentFulfilled: boolean = appointment.status === 'fulfilled';
      
          return (
            <tr key={appointment.id}>
              <td>
                  <div className="flex-center-nowrap">
                    <Text className="appointment-time-text-style">
                      {appointment?.start ? format(appointment?.start, 'hh:mm A') : '-'}
                    </Text>
      
                    {appointment?.status && (
                      <StatusBadge
                        statusColor={STATUS_COLORS[appointment?.status] || 'gray'}
                        backgroundColor={STATUS_BACKGROUND_COLORS[appointment?.status] || 'gray'}
                        textColor={STATUS_TEXT_COLORS[appointment?.status] || 'gray'}
                        borderColor={STATUS_BORDER_COLORS[appointment?.status] || 'gray'}
                        statusLabel={
                          statuses?.find((status) => status.code === appointment?.status)?.display ||
                          appointment?.status
                        }
                      />
                    )}
                  </div>
              </td>
      
              <td>
                <Text className="patientNameTextStyle">{patient?.actor?.display || 'Unknown'}</Text>
              </td>
              <td>
                <Text className="appointmentDatetextStyle">
                  {appointment?.start ? format(new Date(appointment?.start), 'DD MMM YYYY') : '-'}
                </Text>
              </td>
              <td style={{ maxWidth: 250 }}>
                <Text className="appointmentDatetextStyle">{appointment?.description || '-'}</Text>
              </td>
              <td>
                {' '}
                <Text className="appointmentDatetextStyle">
                  {appointment?.minutesDuration ? `${appointment?.minutesDuration} min` : '-'}
                </Text>
              </td>
      
              <td>
                <Group className="">
                  <Image className="" src={icons.IcUser} width={18} />
                  <Image src={icons.IcShieldPlus} width={18} />
                  <Image src={icons.IcSquareCheck} width={18} />
                  <Image src={icons.IcCard} width={18} />
                  <Image src={icons.IcMobile} width={18} />
                </Group>
              </td>
              <td>{practitioner?.actor?.display || 'Unknown'}</td>
              <td>
                <Group style={{ display: 'flex', alignItems: 'center'}}>
                  <IconCircleCheck stroke={1.5} size={20} />
                  <Image src={icons.IcChat} width={18} />

                  <Tooltip label={isAppointmentFulfilled ? 'Call Ended' : 'Join Call'}>
                  <IconVideo
                    color={isAppointmentFulfilled ? '#00B14A' : 'gray'}
                    stroke={1.5}
                    size={20}
                    cursor={isAppointmentFulfilled ? 'default' : 'pointer'}
                    onClick={() => appointment?.id && !isAppointmentFulfilled && handleStartCall(appointment.id)}
                  />
                  </Tooltip>
                  <IconDotsVertical stroke={1.5} size={18} />
                </Group>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TelehealthTable;
