/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
import { Box, Card, Text, Tabs, Table, Badge, Center, Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCoverageInfo, getClaimInfo } from '../../fhirApi';
import { useMedplum } from '@medplum/react';
import { showNotification } from '@mantine/notifications';
import styles from './Insurance.module.css'

export function Insurance() {
  const patientID = useParams().id;
  const medplum = useMedplum();
  const [data, setData] = useState([]);
  const [noDataRow, setNoDataRow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const detailsTab = [
    { value: 'coverage', name: 'Coverage' },
    { value: 'claim', name: 'Claim' },
  ];

  const [activeTab, setActiveTab] = useState(detailsTab[0].value);
  const [isLoading, setIsLoading] = useState(true);

  const coverageFields = [
    { fieldName: 'planName', displayName: 'Plan Name' },
    { fieldName: 'groupName', displayName: 'Group Name' },
    { fieldName: 'subscriber', displayName: 'Subscriber' },
    { fieldName: 'beneficiary', displayName: 'Beneficiary' },
    { fieldName: 'payor', displayName: 'Payor' },
    { fieldName: 'relationship', displayName: 'Relationship' },
    { fieldName: 'period', displayName: 'Period' },
    { fieldName: 'status', displayName: 'Status' },
  ];

  const claimFields = [
    { fieldName: 'claimNumber', displayName: 'Claim Number' },
    { fieldName: 'billablePeriod', displayName: 'Billable Period' },
    { fieldName: 'insurer', displayName: 'Insurer' },
    { fieldName: 'provider', displayName: 'Provider' },
    { fieldName: 'diagnosis', displayName: 'Diagnosis' },
    { fieldName: 'paymentDate', displayName: 'Payment Date' },
    { fieldName: 'paymentAmount', displayName: 'Payment Amount' },
    { fieldName: 'benefitAmount', displayName: 'Benefit Amount' },
    { fieldName: 'copayAmount', displayName: 'Copay Amount' },
    { fieldName: 'submittedAmount', displayName: 'Submitted Amount' },
    { fieldName: 'status', displayName: 'Status' },
  ];

  const successStatus = ['active', 'completed', 'fulfilled', 'final'];
  const failedStatus = ['inactive', 'incomplete', 'cancelled'];
  const warningStatus = ['booked'];
  const infoStatus = ['amended'];
  const blueColorStatus = ['arrived'];

  const statusButton = (status: any) => {
    const formattedStatus = status?.toLowerCase();
    return successStatus.includes(formattedStatus) ? (
      <Badge className={styles.badge} variant="filled" style={{ background: '#DEF5EA' }}>
        {status}
      </Badge>
    ) : failedStatus.includes(formattedStatus) ? (
      <Badge className={styles.badge} variant="filled" style={{ background: '#FAEBEB' }}>
        {status}
      </Badge>
    ) : warningStatus.includes(formattedStatus) ? (
      <Badge className={styles.badge} variant="filled" style={{ background: '#FCF2DF' }}>
        {status}
      </Badge>
    ) : infoStatus.includes(formattedStatus) ? (
      <Badge className={styles.badge} variant="filled" style={{ background: '#F2F2F2' }}>
        {status}
      </Badge>
    ) : blueColorStatus.includes(formattedStatus) ? (
      <Badge className={styles.badge} variant="filled" style={{ background: '#DBF0FD' }}>
        {status}
      </Badge>
    ) : (
      '-'
    );
  };

  useEffect(() => {
    CoverageInfoData();
  }, [activeTab]);

  const displayData = data?.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const generateTabContent = (tab: any, data: any[], fields: any[]) => {
    return (
      <Tabs.Panel value={tab.value} pt="md" className={styles.tabPanel}>
        <Card withBorder radius="md" className={styles.card} style={{ overflow: 'auto' }}>
          <Card.Section className={styles.header} p="lg">
            <Text className="darkText" fw={700}>
              {tab.name}
            </Text>
          </Card.Section>
          <Card.Section className={styles.section} p="lg">
            <Table highlightOnHover style={{ overflowX: 'hidden' }} className={styles.table} verticalSpacing="sm">
              <thead style={{ textAlign: 'left' }}>
                <tr className={styles.tableRow}>
                  {fields.map(({ fieldName, displayName }) => (
                    <th className={styles.tableRow} key={fieldName}>
                      {displayName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr className={styles.tableRow}>
                    <td className={styles.tableRow} colSpan={fields.length}>
                      <Center>{'Loading...'}</Center>
                    </td>
                  </tr>
                ) : noDataRow ? (
                  <tr className={styles.tableRow}>
                    <td className={styles.tableRow} colSpan={fields.length}>
                      <Center>{'No Data Found'}</Center>
                    </td>
                  </tr>
                ) : (
                  data.map((item: any) => (
                    <tr className={styles.tableRow} key={item.id}>
                      {fields.map(({ fieldName }) => (
                        <td className={styles.tableRow} key={fieldName}>
                          {fieldName === 'status' ? (
                            <>{statusButton(item[fieldName])}</>
                          ) : (
                            <>{item[fieldName] || '-'}</>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Card.Section>
        </Card>
      </Tabs.Panel>
    );
  };

  const CoverageInfoData = () => {
    getCoverageInfo(medplum, patientID)
      .then((response: any) => {
        if (response && response.length > 0) {
          const tempList = response.map(
            ({
              Id,
              Status,
              Subscriber,
              Period,
              Beneficiary,
              Relationship,
              Payor,
              Group,
              Plan,
            }: {
              Id: string;
              Status: string;
              Subscriber: string;
              Period: string;
              Beneficiary: string;
              Relationship: string;
              Payor: string;
              Group: string;
              Plan: string;
            }) => ({
              id: Id,
              status: Status,
              subscriber: Subscriber,
              period: Period,
              beneficiary: Beneficiary,
              relationship: Relationship,
              payor: Payor,
              groupName: Group,
              planName: Plan,
            })
          );
          setData(tempList);
        } else {
          setNoDataRow(true);
          setData([]);
        }
      })
      .catch((error: any) => {
        console.error('Error fetching coverage data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const ClaimInfoData = () => {
    getClaimInfo(medplum, patientID)
      .then((response: any) => {
        if (response) {
          let tempList: any = [];
          tempList = response?.map((item: any) => ({
            id: item.id,
            claimNumber: item.ClaimNumber,
            billablePeriod: item?.BillablePeriod,
            paymentDate: item?.PaymentDate,
            insurer: item?.Insurer,
            provider: item.Provider,
            diagnosis: item?.Diagnosis,
            paymentAmount: item?.PaymentAmount,
            benefitAmount: item?.insuranceAmounts?.benefit,
            copayAmount: item?.insuranceAmounts?.copay,
            submittedAmount: item?.insuranceAmounts?.submitted,
            status: item?.Status,
          }));
          if (tempList.length) {
            setData(tempList);
          } else {
            setNoDataRow(true);
            setData([]);
          }
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTabChange = (tab: any) => {
    setData([]);
    setActiveTab(tab);
    setIsLoading(true);
    setCurrentPage(1);
    if (tab === 'coverage') {
      CoverageInfoData();
    } else if (tab === 'claim') {
      ClaimInfoData();
    }
  };

  return (
    <Box py="sm" display="block">
      <Tabs
        variant="pills"
        defaultValue={activeTab}
        className={styles.tabs}
        onChange={(newTab) => handleTabChange(newTab)}
      >
        <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
          {detailsTab.map((tab) => (
            <Tabs.Tab key={tab.value} value={tab.value}>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {generateTabContent(detailsTab[0], displayData, coverageFields)}
        {generateTabContent(detailsTab[1], displayData, claimFields)}
      </Tabs>
      <Center mt="lg" style={{ justifyContent: 'flex-end' }}>
        <Pagination
          value={currentPage}
          total={Math.ceil(data.length / itemsPerPage)}
          onChange={handlePageChange}
          defaultValue={itemsPerPage}
          className={styles.pagination}
        />
      </Center>
    </Box>
  );
}

export default Insurance;
