import { Card, Text, Group, Skeleton, Box, Grid, Center, Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { format } from 'date-fns';
import Each from './Each';
import TitleComponent from './TitleComponent';
import axios from '../utils/axios';

// Removed createStyles, using inline styles instead
/* const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  section: {
    padding: theme.spacing.sm,
    borderBottom: `1px solid #EEF1F6 !important`,
  },
  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },
  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },
  badge: {
    minWidth: '80px',
    minHeight: '30px',
    color: '#000000',
    textTransform: 'capitalize',
    fontWeight: 600,
  },
})); */

const styles = {
  card: {
    backgroundColor: 'white',
  },
  section: {
    padding: '16px',
    borderBottom: '1px solid #EEF1F6 !important',
  },
  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
    }
  }
};

export function AllNotifications(props: any): JSX.Element {
  const medplum = useMedplum();
  const [notificationResponse, setNotificationResponse] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataFound, setIsDataFound] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState<number>();
  const perPage = 20;

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return format(date, 'MM/DD/YYYY hh:mm A');
  };

  const fetchData = async (): Promise<void> => {
    const token = await medplum.getAccessToken();
    const offset = (currentPage - 1) * perPage;
    try {
      setIsLoading(true);
      setNotificationResponse([]);
      setIsDataFound(false);
      const response = await axios.get(
        `fhir/R4/AuditEvent?_count=20&_offset=${offset}&_sort=-_lastUpdated&_total=accurate&outcome:not=4`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response?.data.entry.length !== 0) {
          setTotalPageCount(response?.data.total);
          setNotificationResponse(response?.data.entry || []);
        } else {
          setIsDataFound(true);
        }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.orgId, currentPage]);

  return (
    <>
      <TitleComponent title="All Notifications" />
      <Box px="lg" py="sm">
        <Grid mb="md">
          <Grid.Col span={12}>
            <Card withBorder radius="md" style={styles.card}>
              <Card.Section px="lg" display="flex" style={styles.section}>
                <Group style={{ justifyContent: 'flex-start', flex: 8, paddingRight: '40px' }}>
                  <Text className="darkText" fw={700}>
                    Notifications
                  </Text>
                </Group>
                <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                  <Text className="darkText" fw={700}>
                    Date
                  </Text>
                </Group>
              </Card.Section>

              <Group
                px="xs"
                style={{ 
                  height: '450px', 
                  overflowX: 'hidden',
                  display: 'block',
                  ...styles.scrollbar
                }}
              >
                <Each
                  of={notificationResponse.filter((item: any) => item?.resource?.outcomeDesc)}
                  render={(item: any) => (
                    <Card.Section display="flex" style={styles.section}>
                      <Group style={{ justifyContent: 'flex-start', flex: 8, paddingRight: '40px' }}>
                        <Text className="lightText" fw={500} style={{ cursor: 'pointer' }}>
                          {item.resource.outcomeDesc}
                        </Text>
                      </Group>
                      <Group style={{ justifyContent: 'flex-start', flex: 4 }}>
                        <Text className="lightText" fw={500}>
                          {formatDate(item.resource.recorded)}
                        </Text>
                      </Group>
                    </Card.Section>
                  )}
                />
                {isDataFound && (
                  <Group style={{ justifyContent: 'center', flex: 1, position: 'relative', top: '50%' }}>
                    <Text className="darkText" fw={700}>
                      No Data Found
                    </Text>
                  </Group>
                )}
                {isLoading && (
                  <div style={{ marginTop: '10px' }}>
                    <Skeleton height={8} radius="xl" />
                    <Skeleton height={8} mt={6} radius="xl" />
                    <Skeleton height={8} mt={6} width="70%" radius="xl" />
                  </div>
                )}
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
          {totalPageCount && (
            <Pagination
              className="pagination"
              value={currentPage}
              total={Math.ceil(totalPageCount / perPage)}
              onChange={setCurrentPage}
            />
          )}
        </Center>
      </Box>
    </>
  );
}
